import React, { useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiModal,
	FingotiButton,
	FingotiLoading,
	FingotiDelete,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Avatar from '@material-ui/core/Avatar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined';

import { OrganisationUserInvite } from './OrganisationUserInvite';
import { OrganisationUserEdit } from './OrganisationUserEdit';

import { apiService } from '../../services/api.service';
import {
	useProfileState,
	useProfileDispatch,
} from '../../context/ProfileContext';
import { useRoleCheck } from '../Utils/RoleCheck';
import { ProfileData } from '../../context/ProfileData';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(6),
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	currentUser: {
		background: theme.palette.primary.lighter,
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	userName: {
		padding: theme.spacing(1),
	},
	userWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	deleteIcon: {
		fill: '#FF0000',
	},
	editIcon: {
		fill: '#00FF00',
	},
	iconDisabled: {
		fill: theme.palette.action.disabled,
	},
	button: {
		marginLeft: theme.spacing(2),
	},
	noItemsRow: {
		textAlign: 'center',
		height: theme.spacing(32),
		borderBottom: 'none',
	},
}));

export const OrganisationUsers = () => {
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useProfileDispatch();
	const profileData = ProfileData();
	const { enqueueSnackbar } = useSnackbar();
	const { roleCheck } = useRoleCheck();
	const [currentUser, setCurrentUser] = useState('');
	const [thisUsers, setThisUsers] = useState([]);
	const [thisLoading, setThisLoading] = useState(false);
	const [inviteOpen, setInviteOpen] = useState(false);
	const [editUser, setEditUser] = useState();
	const [editOpen, setEditOpen] = useState(false);
	const { organisation, user, users, roles, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		setThisLoading(true);
		if (organisation && user && users && roles && !loading) {
			loadCurrentUser();
		}
	}, [organisation, user, users, roles, loading]);

	const loadCurrentUser = () => {
		let currentUser = users.find((u) => u.id === user.id);

		if (currentUser) {
			setCurrentUser(currentUser.id);
		}

		setThisLoading(false);
	};

	const getUsers = async (query) => {
		return apiService
			.getData(URLGenerator(query, '/organisation/users'))
			.then((data) => {
				data.users.sort((a, b) => a.surname.localeCompare(b.surname));
				return {
					data: data.users,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const refreshUsers = () => {
		dispatch({ type: 'LOADING' });
		Promise.all([
			profileData.getUsers(),
			profileData.getUserOrganisations(),
		]).then(() => {
			dispatch({ type: 'LOADED' });
		});
		// return new Promise((resolve, reject) => {
		// 	apiService
		// 		.getData('/organisation/users')
		// 		.then((usersData) => {
		// 			delete usersData['Success'];
		// 			dispatch({ type: 'ADD_USERS', payload: usersData.users });
		// 			resolve(true);
		// 		})
		// 		.then(() =>
		// 			ProfileData.getUserOrganisations().then(() =>
		// 				dispatch({ type: 'LOADED' })
		// 			)
		// 		)
		// 		.catch((error) => {
		// 			reject(error);
		// 		});
		// });
	};

	const removeUser = (userId) => {
		apiService
			.deleteData(`/organisation/users/${userId}`)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				refreshUsers();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const nameRenderer = (rd) => {
		return (
			<div className={classes.userWrapper}>
				<Avatar
					className={classes.avatar}
					size='small'
					src={`https://cdn.fingoti.com/images/avatars/${rd.id}`}
				/>
				{rd.forename} {rd.surname}
			</div>
		);
	};

	const roleRenderer = (rd) => {
		return roles.find((r) => r.id === rd.roleId).roleName;
	};

	const permission = roleCheck('user', 2);

	const handleEditUser = () => {
		setEditOpen(false);
		refreshUsers();
	};

	const handleEditOpen = (user) => {
		setEditUser(user);
		setEditOpen(true);
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					onClick={() => setInviteOpen(true)}
					className={classes.button}
					disabled={roleCheck('user', 2)}
					color='primary'>
					invite user
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'Organisation', link: '/organisation' },
		{ text: 'Users', link: '' },
	];

	return (
		<React.Fragment>
			{thisLoading ? (
				<FingotiLoading />
			) : (
				<React.Fragment>
					<FingotiModal
						title='Invite New User'
						open={inviteOpen}
						setOpen={setInviteOpen}>
						<OrganisationUserInvite setOpen={setInviteOpen} />
					</FingotiModal>
					<FingotiModal
						title='Edit Existing User'
						open={editOpen}
						setOpen={setEditOpen}>
						<OrganisationUserEdit
							setOpen={setEditOpen}
							handleEditUser={handleEditUser}
							editUser={editUser}
						/>
					</FingotiModal>
					<FingotiHeader
						breadcrumbs={breadcrumbs}
						sectionIcon={AccountBalanceOutlined}
						actionButtons={actionButtons()}
					/>
					<FingotiMarkdown path='/documentation/account/organisation/users.md' />
					<FingotiTable
						data={getUsers}
						tableRef={tableRef}
						columns={[
							{ title: 'id', field: 'id', hidden: true },
							{
								title: 'name',
								field: 'name',
								cellStyle: { padding: theme.spacing(1) },
								render: nameRenderer,
							},
							{ title: 'email', field: 'email' },
							{ title: 'role', render: roleRenderer },
						]}
						actions={[
							(rowData) =>
								organisation.mainContact !== rowData.id && {
									icon: EditOutlinedIcon,
									onClick: () => handleEditOpen(rowData),
									tooltip: 'edit user',
								},
							(rowData) =>
								organisation.mainContact !== rowData.id &&
								currentUser !== rowData.id && {
									icon: () => (
										<FingotiDelete
											remove
											disabled={permission}
											className={classes.actionIcon}
											tooltipText='remove user'
											onClick={() => removeUser(rowData.id)}
										/>
									),
								},
						]}
						options={{
							rowStyle: (rowData) => {
								return {
									backgroundColor:
										rowData.id === currentUser && theme.palette.primary.lighter,
								};
							},
						}}
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
