import { FingotiButton } from '@fingoti/components';
import { useSnackbar } from 'notistack';
import { apiService } from '../services/api.service';
import { ssoService } from '../services/sso.service';
import { useProfileDispatch } from './ProfileContext';

export const ProfileData = () => {
	const dispatch = useProfileDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	let resendSnackKey = undefined;

	const itemsPerPage = 50;

	const printProgress = (data, count) => {
		console.log(`Got ${data.length} of ${count}`);
	};

	const ResendButton = () => {
		const resend = () => {
			ssoService
				.ssoResendVerification()
				.then((res) => {
					enqueueSnackbar(res.message, {
						variant: res.success ? 'success' : 'error',
					});
				})
				.catch(() => {
					enqueueSnackbar('Something went wrong', { variant: 'error' });
				})
				.finally(() => {
					closeSnackbar(resendSnackKey);
				});
		};

		return (
			<FingotiButton light color='primary' onClick={resend}>
				Re-send
			</FingotiButton>
		);
	};

	const getData = () => {
		return new Promise((resolve, reject) => {
			dispatch({ type: 'LOADING' });
			console.log('Starting profile context fill...');
			Promise.all([
				getUser(),
				getOrganisation(),
				getRoles(),
				getUserOrganisations(),
				getOrganisationAddresses(),
				getUsers(),
			])
				.then(() => {
                    dispatch({ type: 'LOGGED_IN' });
					dispatch({ type: 'LOADED' });
					resolve();
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: 'ERROR' });
					enqueueSnackbar(error, { variant: 'error' });
					reject();
				});
		});
	};

	const getUser = () => {
		return new Promise((resolve, reject) => {
			apiService
				.getData('/user')
				.then((userData) => {
					delete userData.success;
					const isVeify = window.location.pathname.includes('register/verify');
					if (!userData.emailVerified & !isVeify) {
						resendSnackKey = enqueueSnackbar(
							"Your email address isn't verified, please check your email",
							{
								variant: 'warning',
								persist: true,
								action: ResendButton,
								preventDuplicate: true,
							}
						);
					}
					dispatch({ type: 'ADD_USER', payload: userData });
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getUsers = (progressCb = printProgress, page = 1, users = []) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData(
					`/organisation/users?itemsPerPage=${itemsPerPage}&pageNumber=${page}`
				)
				.then((usersData) => {
					users = [...users, ...usersData.users];

					if (usersData.count > users.length) {
						progressCb && progressCb(users, usersData.count);
						getUsers(progressCb, page + 1, users)
							.then(resolve)
							.catch(reject);
					} else {
						dispatch({ type: 'ADD_USERS', payload: users });
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getOrganisation = () => {
		return new Promise((resolve, reject) => {
			apiService
				.getData('/organisation')
				.then((organisationData) => {
					delete organisationData.success;
					dispatch({ type: 'ADD_ORGANISATION', payload: organisationData });
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getRoles = (progressCb = printProgress, page = 1, roles = []) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData(
					`/organisation/roles?itemsPerPage=${itemsPerPage}&pageNumber=${page}`
				)
				.then((rolesData) => {
					roles = [...roles, ...rolesData.roles];

					if (rolesData.count > roles.length) {
						progressCb && progressCb(roles, rolesData.count);
						getRoles(progressCb, page + 1, roles)
							.then(resolve)
							.catch(reject);
					} else {
						dispatch({ type: 'ADD_ROLES', payload: roles });
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getUserOrganisations = (
		progressCb = printProgress,
		page = 1,
		userOrganisations = []
	) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData(
					`/user/organisations?itemsPerPage=${itemsPerPage}&pageNumber=${page}`
				)
				.then((organisationsData) => {
					userOrganisations = [
						...userOrganisations,
						...organisationsData.organisations,
					];

					if (organisationsData.count > userOrganisations.length) {
						progressCb &&
							progressCb(userOrganisations, organisationsData.count);
						getUserOrganisations(progressCb, page + 1, userOrganisations)
							.then(resolve)
							.catch(reject);
					} else {
						dispatch({
							type: 'ADD_ORGANISATIONS',
							payload: userOrganisations,
						});
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getOrganisationAddresses = (
		progressCb = printProgress,
		page = 1,
		addresses = []
	) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData(
					`/organisation/addresses?itemsPerPage=${itemsPerPage}&pageNumber=${page}`
				)
				.then((addressesData) => {
					addresses = [...addresses, ...addressesData.addresses];

					if (addressesData.count > addresses.length) {
						progressCb && progressCb(addresses, addressesData.count);
						getOrganisationAddresses(progressCb, page + 1, addresses)
							.then(resolve)
							.catch(reject);
					} else {
						dispatch({ type: 'ADD_ADDRESSES', payload: addresses });
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	return {
		getData: getData,
		getUser: getUser,
		getUsers: getUsers,
		getRoles: getRoles,
		getUserOrganisations: getUserOrganisations,
	};
};
