import React, { useState } from 'react';
import { FingotiButton } from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Validation from '../../services/validation.service';
import { useForceUpdate } from '../Utils/ForceUpdate';
import { Typography } from '@material-ui/core';
import { apiService } from '../../services/api.service';
import { authUrl } from '../../services/config';
import { handleResponse } from '../../services/response.service';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '25%',
		},
	},
	formControl: {
		marginTop: theme.spacing(2),
	},
	buttonGroup: {
		marginTop: theme.spacing(2),
	},
}));

export const UserSecurityChangePassword = () => {
	const classes = useStyles();
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [thisLoading, setThisLoading] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [errors, setErrors] = useState({});

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	const submitPasswordChange = (event) => {
		event.preventDefault();
		setThisLoading(true);

		let requestOptions = {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				currentPassword: oldPassword,
				newPassword: newPassword,
				confirmPassword: confirmNewPassword,
			}),
		};

		fetch(`${authUrl}/sso/password/change`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				setOldPassword('');
				setNewPassword('');
				setConfirmNewPassword('');
				enqueueSnackbar(result.message, { variant: 'success' });
				setThisLoading(false);
			})
			.catch((error) => {
				console.log('ERROR', error);
				enqueueSnackbar(error.error, { variant: 'error' });
				setThisLoading(false);
			});
	};

	return (
		<div className={classes.root}>
			<Typography variant='h5'>Change Password</Typography>
			<form onSubmit={submitPasswordChange}>
				<FormControl
					className={classes.formControl}
					fullWidth
					variant='outlined'>
					<TextField
						fullWidth
						required
						type={showOldPassword ? 'text' : 'password'}
						id='oldPassword'
						name='oldPassword'
						variant='outlined'
						label='old password'
						value={oldPassword}
						onChange={(e) => setOldPassword(e.target.value)}
						onBlur={(e) => validate.checkNotEmpty(e)}
						error={Boolean(errors['oldPassword'])}
						helperText={
							Boolean(errors['oldPassword']) ? errors['oldPassword'].msg : ''
						}
						InputProps={{
							endAdornment: (
								<IconButton
									tabIndex='-1'
									aria-label='toggle password visibility'
									onClick={() => setShowOldPassword(!showOldPassword)}
									edge='end'>
									{showOldPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							),
						}}
					/>
				</FormControl>
				<FormControl
					className={classes.formControl}
					fullWidth
					variant='outlined'>
					<TextField
						fullWidth
						required
						type={showNewPassword ? 'text' : 'password'}
						id='password'
						name='password'
						variant='outlined'
						label='new password'
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						onBlur={(e) => validate.checkAgainstRegex(e)}
						error={Boolean(errors['password'])}
						helperText={
							Boolean(errors['password']) ? errors['password'].msg : ''
						}
						InputProps={{
							endAdornment: (
								<IconButton
									tabIndex='-1'
									aria-label='toggle password visibility'
									onClick={() => setShowNewPassword(!showNewPassword)}
									edge='end'>
									{showNewPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							),
						}}
					/>
				</FormControl>
				<FormControl
					className={classes.formControl}
					fullWidth
					variant='outlined'>
					<TextField
						fullWidth
						required
						type='password'
						id='confirmPassword'
						name='confirmPassword'
						variant='outlined'
						label='confirm new password'
						value={confirmNewPassword}
						onChange={(e) => setConfirmNewPassword(e.target.value)}
						onBlur={(e) => validate.checkPasswordsMatch(e, newPassword)}
						error={Boolean(errors['confirmPassword'])}
						helperText={
							Boolean(errors['confirmPassword'])
								? errors['confirmPassword'].msg
								: ''
						}
					/>
				</FormControl>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						fullWidth
						color='primary'
						type='submit'
						disabled={
							oldPassword !== '' &&
							newPassword !== '' &&
							confirmNewPassword !== ''
								? false
								: true
						}>
						Change password
					</FingotiButton>
				</div>
			</form>
		</div>
	);
};
