import React, { useState, useEffect } from 'react';
import {
	FingotiButton,
	FingotiHeader,
	FingotiLoading,
	FingotiModal,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { datetimeService } from '../../services/datetime.service';
import {
	useProfileDispatch,
	useProfileState,
} from '../../context/ProfileContext';
import { ImageCrop } from '../Utils/ImageCrop';
import { UserDetailsEdit } from './UserDetailsEdit';
import { ProfileData } from '../../context/ProfileData';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '75vh',
	},
	avatar: {
		width: '4rem',
		height: '4rem',
		border: '4px solid #E0E0E0',
		transition: 'all 150ms linear',
		[theme.breakpoints.up('sm')]: {
			width: '6rem',
			height: '6rem',
		},
		[theme.breakpoints.up('md')]: {
			width: '8rem',
			height: '8rem',
		},
		[theme.breakpoints.up('lg')]: {
			width: '10rem',
			height: '10rem',
		},
	},
	avatarWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		transition: 'all 150ms linear',
	},
	avatarButton: {
		'&:hover': {
			'& $avatarEdit': {
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
				transition: 'all 150ms linear',
			},
			'& $avatar': {
				cursor: 'pointer',
				transition: 'all 150ms linear',
				opacity: '0.4',
			},
		},
	},
	avatarEdit: {
		position: 'absolute',
		top: '42%',
		left: '38%',
		fontSize: '1rem',
		fontFamily: 'Work Sans',
		zIndex: 100,
		display: 'none',
		color: '#FFF',
		transition: 'all 150ms linear',
		padding: '6px 8px',
		borderRadius: '5%',
		background: 'rgba(64, 64, 64, 0.8)',
	},
	defaultAvatar: {
		fontSize: '5rem',
	},
	profileHead: {
		display: 'inline-flex',
		flexDirection: 'column',
		alignSelf: 'center',
		justifyContent: 'flex-end',
	},
	userHandle: {
		color: theme.palette.primary.main,
	},
	userInfoText: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	iconSpacing: {
		marginRight: theme.spacing(2),
	},
	noBottomBorder: {
		border: 'none',
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '50%',
		color: theme.palette.greyTwo.main,
	},
	divider: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
}));

export const UserDetails = () => {
	const classes = useStyles();
    const { getUser } = ProfileData();
	const { enqueueSnackbar } = useSnackbar();
	const [thisLoading, setThisLoading] = useState(true);
	const { user, loading, update, error } = useProfileState();
	const [imageOpen, setImageOpen] = useState(false);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const dispatch = useProfileDispatch();

	useEffect(() => {
		setThisLoading(true);
		if (user && !loading) {
			setThisLoading(false);
		}
	}, [user, loading, update]);

	const imageUploadSuccess = () => {
		enqueueSnackbar('Successfully updated account image', {
			variant: 'success',
		});
		setImageOpen(false);
		dispatch({ type: 'UPDATE' });
	};

	const imageUploadFail = () => {
		enqueueSnackbar('Failed to update account image', { variant: 'error' });
		setImageOpen(false);
	};

    const detailsUpdateSuccess = () => {
        dispatch({ type: 'LOADING' });
        getUser().then(() => {
            dispatch({ type: 'LOADED' })
        })
    }

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					color='primary'
					onClick={() => setDetailsOpen(true)}>
					Edit details
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'User', link: '/user' },
		{ text: 'Overview', link: '' },
	];

	return thisLoading ? (
		<FingotiLoading />
	) : error ? (
		''
	) : (
		<React.Fragment>
			<FingotiModal
				title='Upload Profile Image'
				open={imageOpen}
				setOpen={setImageOpen}>
				<ImageCrop
					account={false}
					setOpen={setImageOpen}
					onSuccess={imageUploadSuccess}
					onError={imageUploadFail}
				/>
			</FingotiModal>
			<FingotiModal
				title='Edit Your Details'
				open={detailsOpen}
				setOpen={setDetailsOpen}>
				<UserDetailsEdit onSuccess={detailsUpdateSuccess} setOpen={setDetailsOpen} />
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				actionButtons={actionButtons()}
				sectionIcon={PersonIcon}
			/>
			<Grid container spacing={4}>
				<Grid item xs={3} sm={2} className={classes.avatarWrapper}>
					<div className={classes.avatarButton}>
						<div className={classes.avatarEdit} onClick={setImageOpen}>
							<EditOutlinedIcon
								style={{ fontSize: '1rem', paddingRight: '6px' }}
							/>
							edit
						</div>
						<Avatar
							src={`https://cdn.fingoti.com/images/profiles/${
								user.id
							}?rid=${Date.now()}`}
							className={classes.avatar}
							onClick={setImageOpen}>
							<PersonIcon className={classes.defaultAvatar} />
						</Avatar>
					</div>
				</Grid>
				<Grid item xs={9} sm={10} className={classes.profileHead}>
					<Typography variant='h4'>
						{user.forename} {user.surname}
					</Typography>
					<Typography className={classes.userHandle}>
						@{user.userHandle}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<TableContainer>
						<Table>
							<TableRow>
								<TableCell className={classes.headerCell}>email</TableCell>
								<TableCell className={classes.noBottomBorder}>
									{user.email}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className={classes.headerCell}>phone</TableCell>
								<TableCell className={classes.noBottomBorder}>
									{user.telephone}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className={classes.headerCell}>mobile</TableCell>
								<TableCell className={classes.noBottomBorder}>
									{user.mobile}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className={classes.headerCell}>joined on</TableCell>
								<TableCell className={classes.noBottomBorder}>
									{datetimeService.formatDateTimeNoSeconds(
										new Date(user.dateRegistered)
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className={classes.headerCell}>
									email verified
								</TableCell>
								<TableCell className={classes.noBottomBorder}>
									{user.emailVerified ? 'Yes' : 'No'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className={classes.headerCell}>marketing</TableCell>
								<TableCell className={classes.noBottomBorder}>
									{user.marketing ? 'Yes' : 'No'}
								</TableCell>
							</TableRow>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
