import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiLoading,
	FingotiButton,
	FingotiModalContent,
	FingotiModalActions,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { apiService } from '../../services/api.service';
import Validation from '../../services/validation.service';
import { useProfileState } from '../../context/ProfileContext';
import { useForceUpdate } from '../Utils/ForceUpdate';
import { cdnUrl } from '../../services/config';

const CustomPopper = (props) => {
	return <Popper {...props} style={{ ...props.style, zIndex: 1400 }} />;
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	formControl: {
		marginTop: theme.spacing(3),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const OrganisationAddressesNewEdit = ({
	setOpen,
	refreshAddresses,
	update,
	updateData,
}) => {
	const classes = useStyles();
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [addressId, setAddressId] = useState('');
	const [addrLn1, setAddrLn1] = useState('');
	const [addrLn2, setAddrLn2] = useState('');
	const [city, setCity] = useState('');
	const [county, setCounty] = useState('');
	const [country, setCountry] = useState('');
	const [selectedCountry, setSelectedCountry] = useState({});
	const [countries, setCountries] = useState([]);
	const [postcode, setPostcode] = useState('');
	const [thisLoading, setThisLoading] = useState(true);
	const [errors, setErrors] = useState([]);
	const [error, setError] = useState(false);
	const { loading } = useProfileState();

	useEffect(() => {
		setThisLoading(true);

		console.log('update data', updateData);

		if (!loading) {
			if (update === true) {
				setAddressId(updateData.id);
				setAddrLn1(updateData.line1);
				setAddrLn2(updateData.line2);
				setCity(updateData.city);
				setCounty(updateData.county);
				setCountry(updateData.country);
				setPostcode(updateData.postcode);
			}

			setThisLoading(false);
		}
	}, [loading]);

	useEffect(() => {
		getCountryList();
	}, []);

	const getCountryList = () => {
		fetch(`${cdnUrl}/static/metadata/countries_v1.0.json`)
			.then(async (response) => {
				if (!response.ok) {
					throw Error('Error getting country codes');
				} else {
					const data = await response.json();
					return data;
				}
			})
			.then((data) => {
				if (!update) {
					setSelectedCountry(data.countries[0]);
					setCountry(data.countries[0].code);
				} else {
					setSelectedCountry(
						data.countries.find((c) => updateData.country === c.code)
					);
				}
				setCountries(data.countries);
				setThisLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setError(true);
				setThisLoading(false);
			});
	};

	const handleErrorSet = (newErrors) => {
		setErrors(newErrors);
		forceUpdate();
	};

	const validatePostcode = (e, r) => {
		let regexString = r || selectedCountry.regex;
		console.log(regexString);
		let regex = new RegExp(regexString);

		console.log(regex);

		if (!regex.test(e.target.value)) {
			errors['postcode'] = { msg: 'postcode not valid' };
			handleErrorSet(errors);
		} else {
			delete errors['postcode'];
			handleErrorSet(errors);
		}
	};

	const validate = new Validation(errors, handleErrorSet);

	const handleSubmit = () => {
		setThisLoading(true);

		let body = {
			line1: addrLn1,
			line2: addrLn2,
			city: city,
			county: county,
			country: country,
			postcode: postcode,
		};

		if (update === true) {
			editAddress(body);
		} else {
			addAddress(body);
		}
	};

	const addAddress = (body) => {
		apiService
			.postData('/organisation/addresses', body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setThisLoading(false);
				handleClose();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
				setThisLoading(false);
			});
	};

	const editAddress = (body) => {
		apiService
			.patchData(`/organisation/addresses/${addressId}`, body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setThisLoading(false);
				handleClose();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
				setThisLoading(false);
			});
	};

	const handleClose = () => {
		setOpen(false);
		refreshAddresses();
	};

	return (
		<>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading />
				) : (
					<div className={classes.root}>
						<form className={classes.form}>
							<FormControl className={classes.formControl} variant='outlined'>
								<TextField
									required
									id='addrLn1'
									name='addrLn1'
									variant='outlined'
									label='Address Line 1'
									value={addrLn1}
									onChange={(e) => setAddrLn1(e.target.value)}
									onBlur={(e) => validate.checkNotEmpty(e)}
									error={Boolean(errors['addrLn1'])}
									helperText={
										Boolean(errors['addrLn1']) ? errors['addrLn1'].msg : ''
									}
								/>
							</FormControl>
							<FormControl className={classes.formControl} variant='outlined'>
								<TextField
									id='addrLn2'
									name='addrLn2'
									variant='outlined'
									label='Address Line 2'
									value={addrLn2}
									onChange={(e) => setAddrLn2(e.target.value)}
									error={Boolean(errors['addrLn2'])}
									helperText={
										Boolean(errors['addrLn2']) ? errors['addrLn2'].msg : ''
									}
								/>
							</FormControl>
							<FormControl className={classes.formControl} variant='outlined'>
								<TextField
									required
									id='city'
									name='city'
									variant='outlined'
									label='City'
									value={city}
									onChange={(e) => setCity(e.target.value)}
									onBlur={(e) => validate.checkNotEmpty(e)}
									error={Boolean(errors['city'])}
									helperText={Boolean(errors['city']) ? errors['city'].msg : ''}
								/>
							</FormControl>
							<FormControl className={classes.formControl} variant='outlined'>
								<TextField
									required
									id='county'
									name='county'
									variant='outlined'
									label='County'
									value={county}
									onChange={(e) => setCounty(e.target.value)}
									onBlur={(e) => validate.checkNotEmpty(e)}
									error={Boolean(errors['county'])}
									helperText={
										Boolean(errors['county']) ? errors['county'].msg : ''
									}
								/>
							</FormControl>
							<FormControl className={classes.formControl} variant='outlined'>
								<Autocomplete
									disableClearable
									id='country'
									name='country'
									options={countries}
									value={selectedCountry}
									onChange={(e, v) => {
										setSelectedCountry(v);
										setCountry(v.code);
										if (postcode !== '') {
											validatePostcode(
												{ target: { value: postcode } },
												v.regex
											);
										}
									}}
									getOptionLabel={(opt) => `${opt.country}`}
									getOptionSelected={(o, v) =>
										o.code === v.code ? true : false
									}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											variant='outlined'
											name='mcountry'
											label='country *'
										/>
									)}
									PopperComponent={CustomPopper}
								/>

								{/* <TextField
									required
									id='country'
									name='country'
									variant='outlined'
									label='Country'
									value={country}
									onChange={(e) => setCountry(e.target.value)}
									onBlur={(e) => validate.checkNotEmpty(e)}
									error={Boolean(errors['country'])}
									helperText={
										Boolean(errors['country']) ? errors['country'].msg : ''
									}
								/> */}
							</FormControl>
							<FormControl className={classes.formControl} variant='outlined'>
								<TextField
									required
									id='postcode'
									name='postcode'
									variant='outlined'
									label='Postcode'
									value={postcode}
									onChange={(e) => setPostcode(e.target.value.toUpperCase())}
									onBlur={(e) => validatePostcode(e)}
									error={Boolean(errors['postcode'])}
									helperText={
										Boolean(errors['postcode']) ? errors['postcode'].msg : ''
									}
								/>
							</FormControl>
						</form>
					</div>
				)}
			</FingotiModalContent>
			{!thisLoading && (
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							color='primary'
							className={classes.button}
							onClick={handleSubmit}
							disabled={Boolean(Object.keys(errors).length > 0)}>
							Save Address
						</FingotiButton>
						<FingotiButton
							light
							onClick={() => setOpen(false)}
							className={classes.button}>
							Cancel
						</FingotiButton>
					</div>
				</FingotiModalActions>
			)}
		</>
	);
};
