import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FingotiLoading, FingotiButton, FingotiFullscreenStatus, FingotiHeader } from '@fingoti/components';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import { apiService } from '../../services/api.service';
import { useProfileDispatch } from '../../context/ProfileContext';


export const Review = ({ classes, submittedInfo, inviteToken, currentUser, partner }) => {

    const userInfo = submittedInfo[0];
    const organisationInfo = submittedInfo[1];
    const [showOrganisation, setShowOrganisation] = useState(true);
    const [showUser, setShowUser] = useState(true);
    const [showTenantUser, setShowTenantUser] = useState(typeof organisationInfo !== 'undefined' ? organisationInfo.inviteCustomer : false);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useProfileDispatch();
    const history = useHistory();

    useEffect(() => {

        if (inviteToken !== '') {
            setShowOrganisation(false)
        } else if (currentUser !== undefined) {
            setShowUser(false);
        }

        if (partner !== undefined) {
            setShowOrganisation(true);
        }

        setLoading(false);
    }, [])

    const createInvitedUser = () => {

        let body = {
            accept: true,
            forename: userInfo.forename,
            surname: userInfo.surname,
            userHandle: userInfo.userHandle,
            mobile: userInfo.mobile,
            telephone: userInfo.telephone,
            password: userInfo.password,
            marketing: userInfo.marketing,
            email: userInfo.email
        }

        apiService.postData('/user/invite?token=' + inviteToken, body, false)
            .then(() => {
                setSuccess(true);
                setLoading(false);
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
            })

    }

    const createUserOrganisation = () => {

        let organisationData = {
            isBusiness: organisationInfo.isBusiness,
            vatNumber: organisationInfo.vatNumber,
            organisationName: organisationInfo.organisationName,
            organisationHandle: organisationInfo.organisationHandle,
            addressLine1: organisationInfo.maddrLn1,
            addressLine2: organisationInfo.maddrLn2,
            city: organisationInfo.mtown,
            county: organisationInfo.mcounty,
            country: organisationInfo.mcountry,
            postcode: organisationInfo.mpostcode,
        }

        apiService.postData("/user", userInfo)
            .then(userData => {
                apiService.postData('/organisation?token=' + userData.token, organisationData)
                    .then(() => {
                        setSuccess(true);
                        setLoading(false);
                    })
                    .catch(() => {
                        setSuccess(false);
                        setLoading(false);
                    })
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
            })

    }

    const createOrganisation = () => {

        let organisationData = {
            isBusiness: organisationInfo.isBusiness,
            vatNumber: organisationInfo.vatNumber,
            organisationName: organisationInfo.organisationName,
            organisationHandle: organisationInfo.organisationHandle,
            addressLine1: organisationInfo.maddrLn1,
            addressLine2: organisationInfo.maddrLn2,
            city: organisationInfo.mtown,
            county: organisationInfo.mcounty,
            country: organisationInfo.mcountry,
            postcode: organisationInfo.mpostcode,
        }

        apiService.postData('/organisation', organisationData)
            .then(() => {
                setSuccess(true);
                apiService.getData('/user/organisations')
                    .then(organisationsData => {
                        delete organisationsData.success;
                        dispatch({ type: 'ADD_ORGANISATIONS', payload: organisationsData.organisations });
                    })
                setLoading(false);
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
            })

    }

    const createTenantOrganisation = () => {

        let organisationData = {
            partnerId: partner,
            isBusiness: organisationInfo.isBusiness,
            vatNumber: organisationInfo.vatNumber,
            organisationName: organisationInfo.organisationName,
            organisationHandle: organisationInfo.organisationHandle,
            addressLine1: organisationInfo.maddrLn1,
            addressLine2: organisationInfo.maddrLn2,
            city: organisationInfo.mtown,
            county: organisationInfo.mcounty,
            country: organisationInfo.mcountry,
            postcode: organisationInfo.mpostcode,
            customerForename: organisationInfo.customerForename,
            customerSurname: organisationInfo.customerSurname,
            customerEmail: organisationInfo.customerEmail
        }

        apiService.postData('/organisation/tenants', organisationData)
            .then(() => {
                setSuccess(true);
                setLoading(false);
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
            })

    }

    const handleSubmit = () => {
        setLoading(true);
        setSubmitted(true);

        if (inviteToken !== '') {
            createInvitedUser();
        } else if (partner !== undefined) {
            createTenantOrganisation();
        } else if (currentUser !== undefined) {
            createOrganisation();
        } else {
            createUserOrganisation();
        }

    }

    const getSuccessMessage = () => {
        if (showTenantUser) {
            return {
                tagline: "Organisation Created!",
                message: "Tenant organisation created successfully, you can now manage the organisation"
            };
        } else if (showOrganisation && showUser) {
            return {
                tagline: "User & Organisation created!",
                message: "User and Organisation created successfully, We've sent you an email you'll need to accept before you can login"
            };
        } else if (showOrganisation && !showUser) {
            return {
                tagline: "Organisation Created",
                message: "Organisation created successfully, you can now switch to your new organisation"
            };
        } else if (showUser) {
            return {
                tagline: "User Created & Invite Accepted",
                message: "User created and invite accepted successfully, you now have access to the organisation"
            };
        }
    }

    let message = getSuccessMessage();

    const breadcrumbs = [
        { text: 'Review Your Details' }
    ]

    return (
        loading ?
            <FingotiLoading />
            :
            submitted ?
                <div className={classes.messageRoot}>
                    {
                        success ?
                            <FingotiFullscreenStatus
                                success
                                tagline={message.tagline}
                                message={message.message}
                            />
                            :
                            <FingotiFullscreenStatus
                                tagline="Registration Failed"
                                message="There was a problem with your registration, please contact support if the issue persists"
                            />
                    }
                </div>
                :
                <React.Fragment>
                    <FingotiHeader
                        breadcrumbs={breadcrumbs}
                        sectionIcon={PersonAddOutlinedIcon}
                    />
                    <Grid container spacing={6}>
                        {
                            showUser ?
                                <Grid item xs={6}>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>forename</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{userInfo.forename}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>surname</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{userInfo.surname}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>email</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{userInfo.email}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>handle</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{userInfo.userHandle}</TableCell>
                                                </TableRow>
                                                {
                                                    userInfo.mobile !== null && userInfo.mobile !== "" ?
                                                        <TableRow>
                                                            <TableCell className={classes.headerCell}>mobile</TableCell>
                                                            <TableCell className={classes.noBottomBorder}>{userInfo.mobile}</TableCell>
                                                        </TableRow>
                                                        :
                                                        ""
                                                }
                                                {
                                                    userInfo.telephone !== null && userInfo.mobile !== "" ?
                                                        <TableRow>
                                                            <TableCell className={classes.headerCell}>telephone</TableCell>
                                                            <TableCell className={classes.noBottomBorder}>{userInfo.telephone}</TableCell>
                                                        </TableRow>
                                                        :
                                                        ""
                                                }
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>marketing</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{userInfo.marketing ? "Yes" : "No"}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                :
                                ""
                        }
                        {
                            showTenantUser ?
                                <Grid item xs={6}>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>customer forename</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.customerForename}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>customer surname</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.customerSurname}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>customer email</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.customerEmail}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                :
                                ""
                        }
                        {
                            showOrganisation ?
                                <Grid item xs={6}>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>organisation name</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.organisationName}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>organisation handle</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.organisationHandle}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>buisness organisation</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.isBusiness ? "Yes" : "No"}</TableCell>
                                                </TableRow>
                                                {
                                                    organisationInfo.isBusiness ?
                                                        <TableRow>
                                                            <TableCell className={classes.headerCell}>vat number</TableCell>
                                                            <TableCell className={classes.noBottomBorder}>{organisationInfo.vatNumber}</TableCell>
                                                        </TableRow>
                                                        :
                                                        ""
                                                }

                                                <TableRow>
                                                    <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>address</TableCell>
                                                    <TableCell className={classes.address}>
                                                        {organisationInfo.maddrLn1}<br />
                                                        {organisationInfo.maddrLn2 ? <>{organisationInfo.maddrLn2}<br /></> : ""}
                                                        {organisationInfo.mtown}<br />
                                                        {organisationInfo.mcounty}<br />
                                                        {organisationInfo.mcountry}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.headerCell}>postcode</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisationInfo.mpostcode}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                :
                                ""
                        }
                        {!showOrganisation || !showUser && !showTenantUser ? <Grid item xs={6} /> : ""}
                        <Grid item xs={4} className={classes.buttonGroup}>
                            <FingotiButton
                                light
                                type="submit"
                                className={classes.button}
                                color="primary"
                                onClick={handleSubmit}
                            >
                                REGISTER
                            </FingotiButton>
                            <FingotiButton
                                light
                                className={classes.button}
                                onClick={history.goBack}
                            >
                                CANCEL
                            </FingotiButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
    )
}