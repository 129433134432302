import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiLoading, FingotiModal } from '@fingoti/components';
import clsx from 'clsx';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { cdnUrl } from '../../../services/config';
import { useProfileState } from '../../../context/ProfileContext';
import { EditSubscription } from './EditSubscirption';
import { DetailSubscription } from './DetailsSubscription';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	accentRow: {
		backgroundColor: theme.palette.primary.lighter,
	},
	status: {
		verticalAlign: 'middle',
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		background: '#FFF',
		color: theme.palette.secondary.main,
	},
	organisationName: {
		padding: theme.spacing(1),
	},
	organisationWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	labelCell: {
		minWidth: 52,
		textAlign: 'center',
		display: 'inline-block',
		padding: '2px',
		borderRadius: '3px',
		marginLeft: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
	},
	usageRoot: {
		height: 10,
		borderRadius: 5,
	},
	usageBar: {
		borderRadius: 5,
		backgroundColor: theme.palette.greyFour.main,
	},
	success: {
		borderRadius: 5,
		backgroundColor: theme.palette.success.main,
	},
	warning: {
		borderRadius: 5,
		backgroundColor: theme.palette.warning.main,
	},
	error: {
		borderRadius: 5,
		backgroundColor: theme.palette.error.main,
	},
}));

export const CurrentSubscription = ({
	tenants,
	subscriptions: subsIn,
	billingInfo,
	onSuccess,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const { organisation, loading } = useProfileState();
	const [thisLoading, setThisLoading] = useState(true);
	const [subscriptions, setSubscriptions] = useState();
	const [editOpen, setEditOpen] = useState(false);
	const [editSubscirption, setEditSubscription] = useState({});
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [detailSubscription, setDetailSubscription] = useState({});
	const [showCanceled, setShowCanceled] = useState(false);

	useEffect(() => {
		if ((!loading, organisation)) {
			let subs = subsIn.filter((s) => s.status !== 3);
			setSubscriptions(subs);
			setThisLoading(false);
		}
	}, [loading, organisation, tenants]);

	const handleSubscriptionEdit = (sub) => {
		setEditSubscription(sub);
		setEditOpen(true);
	};

	const handleSubscriptionDetails = (sub) => {
		setDetailSubscription(sub);
		setDetailsOpen(true);
	};

	const handleShowCanceled = () => {
		setShowCanceled(!showCanceled);
		if (!showCanceled) {
			setSubscriptions(subsIn);
		} else {
			let subs = subsIn.filter((s) => s.status !== 3);
			setSubscriptions(subs);
		}
	};

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<>
			<FingotiModal
				title='Edit Subscription'
				open={editOpen}
				setOpen={setEditOpen}>
				<EditSubscription
					billingInfo={billingInfo}
					subscription={editSubscirption}
					tenants={tenants}
					setOpen={setEditOpen}
					onSuccess={onSuccess}
				/>
			</FingotiModal>
			<FingotiModal
				title='Subscription Details'
				open={detailsOpen}
				setOpen={setDetailsOpen}>
				<DetailSubscription
					billingInfo={billingInfo}
					subscription={detailSubscription}
					tenants={tenants}
					setOpen={setDetailsOpen}
				/>
			</FingotiModal>
			<FormControlLabel
				control={
					<Switch
						color='primary'
						checked={showCanceled}
						onChange={handleShowCanceled}
					/>
				}
				label='Show Canceled'
			/>
			<TableContainer>
				<Table className={classes.root}>
					<TableHead>
						<TableRow>
							<TableCell width='12%' className={classes.tableHead}>
								subscription number
							</TableCell>
							<TableCell
								width='10%'
								align='center'
								className={classes.tableHead}>
								status
							</TableCell>
							<TableCell
								style={{ minWidth: 150 }}
								width='20%'
								className={classes.tableHead}>
								subscription plan
							</TableCell>
							{organisation.isPartner && (
								<TableCell
									style={{ minWidth: 200 }}
									className={classes.tableHead}>
									assigned to
								</TableCell>
							)}
							<TableCell width='25%' className={classes.tableHead}>
								ripple usage
							</TableCell>
							<TableCell width='5%'></TableCell>
							<TableCell width='10%' className={classes.tableHead}>
								actions
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{subscriptions.map((sub, i) => {
							let assignedTo = [];

							if (organisation.isPartner) {
								if (billingInfo.subscriptionId === sub.subscriptionId) {
									assignedTo.push(organisation);
								}

								assignedTo.push(
									...tenants.filter(
										(t) => t.subscriptionId === sub.subscriptionId
									)
								);
							}

							const usedRipples = sub.planSelected.ripples - sub.balance;
							const usedRipplePercent = Math.floor(
								(usedRipples / sub.planSelected.ripples) * 100
							);

							return (
								<TableRow
									key={`${i}`}
									className={
										billingInfo.subscriptionId === sub.subscriptionId &&
										organisation.isPartner &&
										classes.accentRow
									}>
									<TableCell width='12%'>{sub.subscriptionNumber}</TableCell>
									<TableCell width='10%' align='center'>
										{sub.status === 0 ? (
											<Tooltip title='pending'>
												<CancelOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.error.main }}
												/>
											</Tooltip>
										) : sub.status === 3 ? (
											<Tooltip title='cancelled'>
												<CancelOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.error.main }}
												/>
											</Tooltip>
										) : sub.endDate ? (
											<Tooltip title='ending'>
												<ErrorOutlineOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.warning.main }}
												/>
											</Tooltip>
										) : sub.status === 1 ? (
											<Tooltip title='trial'>
												<CheckCircleOutlineOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.secondary.main }}
												/>
											</Tooltip>
										) : (
											<Tooltip title='active'>
												<CheckCircleOutlineOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.success.main }}
												/>
											</Tooltip>
										)}
									</TableCell>
									<TableCell width='20%'>
										{sub.planSelected.name}
										{/* {sub.trial && <span style={{ backgroundColor: theme.palette.secondary.main, color: '#fff' }} className={classes.labelCell}>Trial</span>} */}
									</TableCell>
									{organisation.isPartner && (
										<TableCell className={classes.organisationName}>
											<div className={classes.organisationWrapper}>
												{assignedTo.length > 0 ? (
													<AvatarGroup className={classes.avatarGroup}>
														{assignedTo.map((o) => (
															<Tooltip title={`${o.organisationName}`}>
																<Avatar
																	alt={`${o.organisationName}`}
																	className={classes.avatar}
																	size='small'
																	src={`${cdnUrl}/images/avatars/${
																		o ? o.id : 'unassigend'
																	}`}>
																	{o.organisationName
																		.split(' ')
																		.map((p, i) => i < 2 && p.charAt(0))}
																</Avatar>
															</Tooltip>
														))}
													</AvatarGroup>
												) : (
													'Unassigned'
												)}
												{/* <Avatar className={classes.avatar} size="small" src={`https://cdn.fingoti.com/images/avatars/${assignedTo ? assignedTo.id : "unassigend"}`}>
                                                            <AccountBalanceOutlinedIcon />
                                                        </Avatar>
                                                        {
                                                            assignedTo ?
                                                                assignedTo.organisationName
                                                                :
                                                                "Unassigned"
                                                        } */}
											</div>
										</TableCell>
									)}
									<TableCell width='25%' className={classes.useage}>
										<Box display='flex' alignItems='center'>
											<Box width='100%' mr={1}>
												<LinearProgress
													variant='determinate'
													value={usedRipplePercent}
													classes={{
														root: classes.usageRoot,
														colorPrimary: classes.usageBar,
														bar: clsx({
															[classes.success]: usedRipplePercent <= 74,
															[classes.warning]: usedRipplePercent >= 75,
															[classes.error]: usedRipplePercent >= 90,
														}),
													}}
												/>
											</Box>
											<Box minWidth={35}>{usedRipplePercent}%</Box>
										</Box>
									</TableCell>
									<TableCell width='5%'></TableCell>
									<TableCell width='10%'>
										<div className={classes.actionIcons}>
											<Tooltip title='subscription details'>
												<IconButton
													className={classes.actionIcon}
													onClick={() => handleSubscriptionDetails(sub)}>
													<InfoOutlinedIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title='change subscription'>
												<IconButton
													disabled={
														sub.updating || organisation.partnerId
															? true
															: false
													}
													className={classes.actionIcon}
													onClick={() => handleSubscriptionEdit(sub)}>
													<CreateOutlinedIcon />
												</IconButton>
											</Tooltip>
										</div>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
