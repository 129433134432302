import React, { useState } from 'react';
import { FingotiButton, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import Validation from '../../services/validation.service';
import { apiService } from '../../services/api.service';
import { useForceUpdate } from '../Utils/ForceUpdate';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    table: {
        width: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            width: '75%',
            overflowX: 'unset',
        },
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        color: theme.palette.greyTwo.main,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: theme.spacing(2),
        }
    },
    noBottomBorder: {
        border: 'none',
        [theme.breakpoints.up('sm')]: {
            width: '55%',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: 0,
        }
    },
    roleName: {
        width: '100%',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            width: '50%'
        }
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    roleButtonGroup: {
        '&:not(:last-child)': {
            borderRight: '1px solid #ccc',
        }
    },
    textRoleButton: {
        padding: '6px 16px',
    }

}))

const initalRole = {
    roleName: '',
    device: 0,
    organisation: 0,
    follow: 0,
    rule: 0,
    schedule: 0,
    user: 0,
    webhook: 0,
    partner: 0,
    billing: 0
}

export const OrganisationRoleAdd = ({ handleNewRole, setOpen }) => {

    const classes = useStyles();
    const forceUpdate = useForceUpdate();
    const { enqueueSnackbar } = useSnackbar();
    const [role, setRole] = useState(initalRole)
    const [errors, setErrors] = useState({});

    const handleSetErrors = (errors) => {
        setErrors(errors);
        forceUpdate();
    }

    const validate = new Validation(errors, handleSetErrors);

    const handleChangeRoleName = (newName) => {
        var newRole = { ...role };
        newRole['roleName'] = newName;
        setRole(newRole)
    }

    const handleChangeRole = (realm, permission) => {
        var newRole = { ...role };
        newRole[realm] = permission;
        setRole(newRole);
    }

    const submitNewRole = () => {
        let body = { ...role }
        apiService.postData(`/organisation/roles`, body)
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' })
                handleNewRole();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' })
            })
    }

    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <FormControl variant="outlined" className={classes.roleName}>
                        <TextField
                            fullWidth
                            required
                            id='roleName'
                            name='roleName'
                            variant='outlined'
                            label='Role Name'
                            value={role.roleName}
                            onChange={(e) => handleChangeRoleName(e.target.value)}
                            onBlur={(e) => validate.checkNotEmpty(e)}
                            error={Boolean(errors['roleName'])}
                            helperText={Boolean(errors['roleName']) ? errors['roleName'].msg : ""}
                        />
                    </FormControl>
                    <TableContainer className={classes.table}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>organisation</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.organisation === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("organisation", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.organisation === 1 ? "secondary" : "default"}
                                                variant={role.organisation === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("organisation", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.organisation === 2 ? "primary" : "default"}
                                                variant={role.organisation === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("organisation", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>billing</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.billing === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("billing", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.billing === 1 ? "secondary" : "default"}
                                                variant={role.billing === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("billing", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.billing === 2 ? "primary" : "default"}
                                                variant={role.billing === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("billing", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>user</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation className={classes.roleButtonGroup}>
                                            <Button
                                                color="default"
                                                variant={role.user === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("user", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.user === 1 ? "secondary" : "default"}
                                                variant={role.user === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("user", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.user === 2 ? "primary" : "default"}
                                                variant={role.user === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("user", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>device</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.device === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("device", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.device === 1 ? "secondary" : "default"}
                                                variant={role.device === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("device", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.device === 2 ? "primary" : "default"}
                                                variant={role.device === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("device", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>rule</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.rule === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("rule", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.rule === 1 ? "secondary" : "default"}
                                                variant={role.rule === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("rule", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.rule === 2 ? "primary" : "default"}
                                                variant={role.rule === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("rule", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>schedule</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.schedule === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("schedule", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.schedule === 1 ? "secondary" : "default"}
                                                variant={role.schedule === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("schedule", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.schedule === 2 ? "primary" : "default"}
                                                variant={role.schedule === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("schedule", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>follow</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.follow === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("follow", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.follow === 1 ? "secondary" : "default"}
                                                variant={role.follow === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("follow", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.follow === 2 ? "primary" : "default"}
                                                variant={role.follow === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("follow", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>webhook</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.webhook === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("webhook", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.webhook === 1 ? "secondary" : "default"}
                                                variant={role.webhook === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("webhook", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.webhook === 2 ? "primary" : "default"}
                                                variant={role.webhook === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("webhook", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>partner</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        <ButtonGroup disableElevation>
                                            <Button
                                                color="default"
                                                variant={role.partner === 0 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("partner", 0)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                None
                                            </Button>
                                            <Button
                                                color={role.partner === 1 ? "secondary" : "default"}
                                                variant={role.partner === 1 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("partner", 1)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Read
                                            </Button>
                                            <Button
                                                color={role.partner === 2 ? "primary" : "default"}
                                                variant={role.partner === 2 ? "contained" : "text"}
                                                onClick={() => handleChangeRole("partner", 2)}
                                                className={clsx(classes.textRoleButton, classes.roleButtonGroup)}
                                            >
                                                Write
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </FingotiModalContent>
            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        color="primary"
                        className={classes.button}
                        onClick={() => submitNewRole()}
                    >
                        ADD ROLE
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        CANCEL
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    )
}