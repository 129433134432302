import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { FingotiNotFound, FingotiLoading } from '@fingoti/components';
import { useCookies } from 'react-cookie';

import Layout from './components/Layout';
import { User } from './components/User/User';
import { Organisation } from './components/Organisation/Organisation';
import { Billing } from './components/Billing/Billing';
import { Login } from './components/Login/Login';
import { Logout } from './components/Login/Logout';
import { Register } from './components/Register/Register';
import { VerifyEmail } from './components/Register/VerifyEmail';
import { EnterEmail } from './components/Reset/EnterEmail';
import { EnterPassword } from './components/Reset/EnterPassword';
import { InviteConfirm } from './components/Register/InviteConfirm';
import { CancelReset } from './components/Reset/CancelReset';

import { authService } from './services/auth.service';
import { useProfileDispatch, useProfileState } from './context/ProfileContext';
import { ProfileData } from './context/ProfileData';

import './main.css';

const PrivateRoute = ({ children, ...rest }) => {
	const { loggedIn, loading } = useProfileState();
	//get return url query string
	let params = new URLSearchParams(window.location.search);
	let returnUrl = params.get('ReturnUrl');

	//not logged in so redirect to login page with the return url
	if (!loggedIn & !loading) {
		console.log('no user');
		if (returnUrl) {
			return (
				<Redirect
					to={{
						pathname: '/',
						search: '?ReturnUrl=' + returnUrl,
						state: { from: rest.location },
					}}
				/>
			);
		} else {
			return (
				<Redirect to={{ pathname: '/', state: { from: rest.location } }} />
			);
		}
	}
	
	if (loggedIn & !loading) {
		// authorised so return component
		return <Route {...rest}>{children}</Route>;
	}

	return <FingotiLoading fullHeight />
};

const App = () => {
	const profileData = ProfileData();
	const dispatch = useProfileDispatch();
	const [cookies, setCookie] = useCookies();

	const handleVisibilityChange = (e) => {
		if (document.visibilityState === 'visible') {
			console.log('page is vis, checking cookie and LS match');
			authService
				.findAOCookie()
				.then((aoCookie) => {
					authService
						.findAppToken()
						.then((appToken) => {
							if (aoCookie !== appToken.oid) {
								authService.getAppToken().then(() => {
									profileData.getData();
								});
							}
						})
						.catch(() => {});
				})
				.catch(() => {});
		}
	};

	document.addEventListener('visibilitychange', handleVisibilityChange, false);

	useEffect(() => {
		console.log('Cookies');
		console.log(cookies.ActiveOrganisation);
		if (cookies.ActiveOrganisation) {
			authService
				.findAppToken()
				.then((appToken) => {
					console.log('App token present');
					console.log(appToken);
					let hasExpired =
						Date.now() / 1000 > new Date(appToken.exp) ? true : false;
					if (!hasExpired) {
						if (cookies.ActiveOrganisation !== appToken.oid) {
							authService.getAppToken().then(() => {
								profileData.getData();
							});
						} else {
							dispatch({ type: 'LOGGED_IN' });
							profileData.getData();
						}
					} else {
						console.log('App token has exp');
						authService
							.getAppToken()
							.then(() => {
								profileData.getData();
							})
							.catch(() => {
								dispatch({ type: 'LOADED' });
							});
					}
				})
				.catch(() => {
					console.log('No app token');
					authService
						.getAppToken()
						.then(() => {
							profileData.getData();
						})
						.catch(() => {
							dispatch({ type: 'LOADED' });
						});
				});
		} else {
			dispatch({ type: 'LOADED' });
		}
	}, []);

	return (
		<div className='App'>
			<Layout>
				<Switch>
					<Route exact path='/' component={Login} />
					<Route exact path='/reset' component={EnterEmail} />
					<Route path='/reset/verify' component={EnterPassword} />
					<Route path='/reset/cancel' component={CancelReset} />
					<Route exact path='/register' component={Register} />
					<Route path='/register/verify' component={VerifyEmail} />
					<Route path='/invite' component={InviteConfirm} />
					<Route path='/logout' component={Logout} />

					<PrivateRoute exact path='/organisation'>
						<Organisation activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/manage'>
						<Organisation activeMenu={1} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/users'>
						<Organisation activeMenu={2} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/roles'>
						<Organisation activeMenu={3} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/addresses'>
						<Organisation activeMenu={4} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/tokens'>
						<Organisation activeMenu={5} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/tenants'>
						<Organisation activeMenu={6} />
					</PrivateRoute>
					<PrivateRoute exact path='/organisation/applications'>
						<Organisation activeMenu={7} />
					</PrivateRoute>

					<PrivateRoute exact path='/user'>
						<User activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute path='/user/sessions'>
						<User activeMenu={1} />
					</PrivateRoute>
					<PrivateRoute path='/user/tokens'>
						<User activeMenu={2} />
					</PrivateRoute>
					<PrivateRoute path='/user/security'>
						<User activeMenu={3} />
					</PrivateRoute>

					<PrivateRoute exact path='/billing'>
						<Billing activeMenu={0} />
					</PrivateRoute>
					<PrivateRoute exact path='/billing/cards'>
						<Billing activeMenu={1} />
					</PrivateRoute>
					<PrivateRoute exact path='/billing/invoices'>
						<Billing activeMenu={2} />
					</PrivateRoute>

					{/* Keep at bottom */}
					<Route component={FingotiNotFound} />
				</Switch>
			</Layout>
		</div>
	);
};

export default App;
