import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { FingotiButton, FingotiLoading, FingotiHeader, FingotiDelete, FingotiModal, FingotiMarkdown } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import amex from 'payment-icons/min/flat/amex.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import visa from 'payment-icons/min/flat/visa.svg';


import { AddCard } from './AddCard';
import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    root: {
        width: '100%',
        marginTop: theme.spacing(6),
    },
    tableHead: {
        color: theme.palette.primary.main,
    },
    currentUser: {
        background: theme.palette.primary.lighter,
    },
    actionIcons: {
        display: 'flex',
        alignItems: 'center',
    },
    actionIcon: {
        padding: 0,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    deleteIcon: {
        fill: '#FF0000',
    },
    editIcon: {
        fill: '#00FF00',
    },
    iconDisabled: {
        fill: theme.palette.action.disabled
    },
    noItemsRow: {
        textAlign: 'center',
        height: theme.spacing(32),
        borderBottom: 'none',
    },
    brandIcon: {
        verticalAlign: 'middle',
        width: '42px'
    },
    labelCell: {
        minWidth: 62,
        display: 'inline-block',
        padding: '2px',
        borderRadius: '3px',
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
        backgroundColor: theme.palette.primary.main
    },

}));

export const BillingCards = () => {

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useProfileState();
    const [thisLoading, setThisLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [addCardOpen, setAddCardOpen] = useState(false);
    const [defaultCard, setDefaultCard] = useState('');

    const getCards = () => {
        setThisLoading(true);
        apiService.getData('/billing/cards')
            .then(result => {
                
                if (result.cards.length > 0) {
                    let localCards = [...result.cards]
                    localCards.sort(function(x,y){ return x.id == result.defaultCard ? -1 : y.id == result.defaultCard ? 1 : 0; })
                    setCards(localCards);
                } else {
                    setCards([]);
                }
                
                setDefaultCard(result.defaultCard);
                setThisLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setThisLoading(false);
            })
    }

    const handleDelete = (cardId) => {
        apiService.deleteData(`/billing/cards/${cardId}`)
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' })
                getCards();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' })
                getCards();
            })
    }

    const handleAdd = () => {
        getCards();
    }

    const handleMakeDefault = (cardId) => {
        apiService.patchData('/billing/cards', { paymentMethodId: cardId, default: true })
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' });
                getCards();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
            })
    }

    const actionButtons = () => {
        return (
            <FingotiButton
                light
                color="primary"
                onClick={() => setAddCardOpen(true)}
            >
                add card
            </FingotiButton>
        )
    }

    const breadcrumbs = [
        { text: 'Billing', link: '/billing' },
        { text: 'Cards', link: '' }
    ]

    useEffect(() => {
        getCards();
    }, [loading])

    return (
        <>
            {
                thisLoading ?
                    <FingotiLoading />
                    :
                    <>
                        <FingotiModal open={addCardOpen} title="Add New Card" setOpen={setAddCardOpen}>
                            <AddCard setOpen={setAddCardOpen} onSuccess={handleAdd} />
                        </FingotiModal>
                        <FingotiHeader
                            sectionIcon={CreditCardIcon}
                            breadcrumbs={breadcrumbs}
                            actionButtons={actionButtons()}
                        />
                        <FingotiMarkdown
                            path="/documentation/account/billing/cards.md"
                        />
                        <TableContainer>
                            <Table className={classes.root}>
                                <TableHead>
                                    <TableCell className={classes.tableHead}>brand</TableCell>
                                    <TableCell className={classes.tableHead}>card no</TableCell>
                                    <TableCell className={classes.tableHead}>expires</TableCell>
                                    <TableCell className={classes.tableHead}>postcode</TableCell>
                                    <TableCell align="center" className={classes.tableHead}>assignment</TableCell>
                                    <TableCell className={classes.tableHead}>action</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        cards.length > 0 ?
                                            cards.map((card, i) => {

                                                let cardIcon;

                                                switch (card.card.brand) {
                                                    case "amex":
                                                        cardIcon = amex;
                                                        break;
                                                    case "diners":
                                                        cardIcon = diners;
                                                        break;
                                                    case "discover":
                                                        cardIcon = discover;
                                                        break;
                                                    case "jcb":
                                                        cardIcon = jcb;
                                                        break;
                                                    case "mastercard":
                                                        cardIcon = mastercard;
                                                        break;
                                                    case "unionpay":
                                                        cardIcon = unionpay;
                                                        break;
                                                    case "visa":
                                                        cardIcon = visa;
                                                        break;
                                                    default:
                                                        cardIcon = "unk";
                                                        break;
                                                }

                                                return (
                                                    <TableRow key={`${i}`}>
                                                        <TableCell><img src={cardIcon} className={classes.brandIcon} /></TableCell>
                                                        <TableCell> {!isMobile && '**** **** ****' } {card.card.last4}</TableCell>
                                                        <TableCell>{card.card.expMonth.toString().padStart(2, "0")}/{card.card.expYear}</TableCell>
                                                        <TableCell>{card.postcode}</TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                card.id === defaultCard ?
                                                                    <span className={classes.labelCell}>Default</span>
                                                                    :
                                                                    'None'
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={classes.actionIcons}>
                                                                <FingotiDelete className={classes.actionIcon} tooltipText="remove card" onClick={() => handleDelete(card.id)} />
                                                                {
                                                                    card.id !== defaultCard ?
                                                                        <Tooltip title="make default">
                                                                            <IconButton onClick={() => handleMakeDefault(card.id)} className={classes.actionIcon}>
                                                                                <CreditCardIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        :
                                                                        ''
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={6} className={classes.noItemsRow}>
                                                    You currently have no cards on file
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
            }
        </>
    );
}