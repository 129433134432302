import React, { useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import countryCodeLookup from 'iso-countries-lookup';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FingotiButton, FingotiModalActions, FingotiModalContent } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';

const useStyles = makeStyles(theme => ({

    root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    input: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        border: '1px solid ' + theme.palette.greyThree.main,
        borderRadius: theme.spacing(1) / 2,
        fontFamily: 'Work Sans',
        transition: 'border-color 0.125s ease-in-out',
    },
    halfWidthInput: {
        width: '50%'
    },
    inputFocus: {
        borderColor: theme.palette.primary.main + ' !important',
        borderWidth: '2px',
    },
    inputInvalid: {
        borderColor: theme.palette.error.main,
    },
    expcvcWrapper: {
        display: 'flex',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    formControl: {
        margin: theme.spacing(1),
    }

}))


export const AddCard = ({ setOpen, onSuccess }) => {

    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const { addresses, organisation, loading } = useProfileState();
    const [cardNoFocus, setCardNoFocus] = useState(false);
    const [cardExpFocus, setCardExpFocus] = useState(false);
    const [cardCVCFocus, setCardCVCFocus] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(addresses.find(addr => addr.id === organisation.billingAddress).id)
    const [cardNumberError, setCardNumberError] = useState(true);
    const [cvcError, setCvcError] = useState(true);
    const [expiryError, setExpiryError] = useState(true);

    const createPaymentMethod = async () => {
        setSubmitted(true);
        const noEl = elements.getElement(CardNumberElement);

        const addr = addresses.find(addr => addr.id === selectedAddress);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: noEl,
            billing_details: {
                address: {
                    line1: addr.line1,
                    line2: addr.line2,
                    city: addr.city,
                    state: addr.county,
                    country: countryCodeLookup(addr.country),
                    postal_code: addr.postcode
                }
            }
        });

        if (error) {
            console.log('[error]', error);
            setSubmitted(false);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            apiService.postData('/billing/cards', { paymentMethodId: paymentMethod.id, default: isDefault })
                .then(result => {
                    enqueueSnackbar(result.message, { variant: 'success' });
                    setSubmitted(false);
                    setOpen(false);
                    onSuccess();
                })
                .catch(error => {
                    enqueueSnackbar(error, { variant: 'error' });
                    setSubmitted(false);
                })
        }
    }

    const handleCardNumberChange = (event) => {
        if(event.error) {
            setCardNumberError(true);
        } else {
            setCardNumberError(false);
        }
    }

    const handleCvcChange = (event) => {
        if(event.error) {
            setCvcError(true);
        } else {
            setCvcError(false);
        }
    }

    const handleExpiryChange = (event) => {
        if(event.error) {
            setExpiryError(true);
        } else {
            setExpiryError(false);
        }
    }

    const cardNoOpts = {
        classes: {
            base: clsx(classes.input, { [classes.inputFocus]: cardNoFocus }),
            invalid: classes.inputInvalid,
        },
        showIcon: true
    }

    const cardCvcOpts = {
        classes: {
            base: clsx(classes.input, classes.halfWidthInput, { [classes.inputFocus]: cardCVCFocus }),
            invalid: classes.inputInvalid,
        },
    }

    const cardExpOpts = {
        classes: {
            base: clsx(classes.input, classes.halfWidthInput, { [classes.inputFocus]: cardExpFocus }),
            invalid: classes.inputInvalid,
        },
    }

    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <FormControl className={classes.formControl} style={{ width: '98%' }} required variant='outlined'>
                        <Select
                            required
                            value={selectedAddress}
                            onChange={(e) => setSelectedAddress(e.target.value)}
                            variant='outlined'
                        >
                            {
                                addresses.map((addr, i) => {
                                    return (
                                        <MenuItem key={`${i}`} value={addr.id}>
                                            {addr.line1}, {addr.line2}, {addr.city}, {addr.county}, {addr.postcode}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <CardNumberElement
                        options={cardNoOpts}
                        onChange={handleCardNumberChange}
                        onFocus={() => setCardNoFocus(true)}
                        onBlur={() => setCardNoFocus(false)}
                    />
                    <div className={classes.expcvcWrapper}>
                        <CardExpiryElement
                            options={cardExpOpts}
                            onChange={handleExpiryChange}
                            onFocus={() => setCardExpFocus(true)}
                            onBlur={() => setCardExpFocus(false)}
                        />
                        <CardCvcElement
                            options={cardCvcOpts}
                            onChange={handleCvcChange}
                            onFocus={() => setCardCVCFocus(true)}
                            onBlur={() => setCardCVCFocus(false)}
                        />
                    </div>
                    <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isDefault}
                                    onChange={() => setIsDefault(!isDefault)}
                                    name='isDefault'
                                    color='primary'
                                />
                            }
                            label='set as default payment method'
                        />
                    </FormControl>
                </div>
            </FingotiModalContent>
            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        color="primary"
                        className={classes.button}
                        disabled={Boolean(cardNumberError || cvcError || expiryError)}
                        loading={submitted}
                        onClick={createPaymentMethod}
                    >
                        ADD CARD
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        CANCEL
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    )

}