import React, { useState, useEffect } from 'react'
import { FingotiLoading, FingotiFullscreenStatus } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import { authUrl } from '../../services/config';

const useStyles = makeStyles((theme) => ({

    root: {
        height: '75vh'
    },

}));

export const CancelReset = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('token')) {
            cancelReset(urlParams.get('token'));
        } else {
            setSuccess(false);
            setLoading(false);
        }
    }, [])



    const cancelReset = (token) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        };

        fetch(`${authUrl}/sso/password/cancel`, requestOptions)
            .then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        setSuccess(false);
                        setLoading(false);
                        return Promise.reject(false);
                    } else {
                        if (data.success) {
                            return data;
                        } else {
                            return Promise.reject(false);
                        }
                    }
                })
            })
            .then(() => {
                setSuccess(true);
                setLoading(false);
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
            })

    }

    return (
        <React.Fragment>
            {
                loading ?
                    <FingotiLoading />
                    :
                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        {
                            success ?
                                <FingotiFullscreenStatus
                                    success
                                    tagline="Password reset cancelled"
                                    message="Your password reset request has been cancelled successfully"
                                />
                                :
                                <FingotiFullscreenStatus
                                    tagline="Password cancellation request failed"
                                    message="Looks like we've run into some issues, please contact support if the issue persists"
                                />
                        }
                    </Grid>
            }
        </React.Fragment>
    )
}