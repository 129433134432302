import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { FingotiButton, FingotiLoading, FingotiFullscreenStatus } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { useForceUpdate } from '../Utils/ForceUpdate';
import Validation from '../../services/validation.service';
import { authUrl } from '../../services/config';

const useStyles = makeStyles((theme) => ({

    root: {
        height: '75vh'
    },
    title: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    iconSpacing: {
        marginRight: theme.spacing(2),
        fontSize: '3rem',
    },
    form: {
        width: '100%'
    },
    password: {
        marginBottom: theme.spacing(1)
    },
    confirmPassword: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(6)
    },
    button: {
        width: '90%',
    },
    alignRight: {
        textAlign: 'right',
    }

}));

export const EnterPassword = () => {

    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSetErrors = (errors) => {
        setErrors(errors);
        forceUpdate();
    }

    const validate = new Validation(errors, handleSetErrors);

    const getResetToken = () => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('token')) {
            let token = urlParams.get('token');
            return token;
        } else {
            return false;
        }
    }

    const requestReset = (formData) => {

        setLoading(true);
        setSubmitted(true);
        formData.preventDefault();
        let token = getResetToken();

        if (!token) {
            setSuccess(false);
            setLoading(false);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            body: JSON.stringify({ newPassword: password, confirmPassword: confirmPassword })
        };

        fetch(`${authUrl}/sso/password/reset`, requestOptions)
            .then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        setSuccess(false);
                        setLoading(false);
                        return Promise.reject(false);
                    } else {
                        if (data.success) {
                            return data;
                        } else {
                            return Promise.reject(false);
                        }
                    }
                })
            })
            .then(() => {
                setSuccess(true);
                setLoading(false);
            })
            .catch(() => {
                setSuccess(false);
                setLoading(false);
            })

    }

    const cancelPasswordReset = () => {
        history.push('/');
    }

    return (
        <React.Fragment>
            { loading ?
                <FingotiLoading />
                :
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                    {
                        submitted ?
                            success ?
                                <FingotiFullscreenStatus
                                    success
                                    tagline="Password reset"
                                    message="Password reset successfully, you can now log in"
                                />
                                :
                                <FingotiFullscreenStatus
                                    tagline="Failed to reset password"
                                    message="Looks like we've run into some issues, please contact support"
                                />
                            :
                            <Grid item xs={12} lg={3}>
                                <Typography className={classes.title} variant="h5">
                                    <RotateLeftIcon className={classes.iconSpacing} />
                                    New Password
                                </Typography>
                                <Divider className={classes.divider} />
                                <form className={classes.form} onSubmit={(e) => requestReset(e)}>
                                    <FormControl fullWidth variant="outlined" className={classes.password}>
                                        <TextField
                                            required
                                            type={showPassword ? "text" : "password"}
                                            id='password'
                                            name='password'
                                            variant='outlined'
                                            label='new password'
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={(e) => validate.checkAgainstRegex(e)}
                                            error={Boolean(errors['password'])}
                                            helperText={Boolean(errors['password']) ? errors['password'].msg : ""}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={classes.confirmPassword}>
                                        <TextField
                                            required
                                            type={showConfirmPassword ? "text" : "password"}
                                            id='confirmPassword'
                                            name='confirmPassword'
                                            variant='outlined'
                                            label='confirm new password'
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            onBlur={(e) => validate.checkPasswordsMatch(e, password)}
                                            error={Boolean(errors['confirmPassword'])}
                                            helperText={Boolean(errors['confirmPassword']) ? errors['confirmPassword'].msg : ""}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} className={classes.alignRight}>
                                            <FingotiButton
                                                light
                                                className={classes.button}
                                                type="submit"
                                                color="primary"
                                            >
                                                Reset password
                                            </FingotiButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FingotiButton
                                                light
                                                className={classes.button}
                                                onClick={() => cancelPasswordReset()}
                                            >
                                                Cancel
                                            </FingotiButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                    }
                </Grid>
            }
        </React.Fragment>
    )
}