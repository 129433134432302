import { authService, refreshLock } from './auth.service';
import { handleResponse } from './response.service';
import Queue from './queue.service';
import { apiUrl, accountUrl } from './config';


async function getData(path, retry = true) {

    console.log("getting data from: " + path);

    let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request, retry)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function patchData(path, bodyData, retry = true) {

    console.log("patch data to: " + path);

    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
        body: JSON.stringify(bodyData)
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request, retry)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function postData(path, bodyData, retry = true) {

    console.log("posting data to: " + path);

    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
        body: JSON.stringify(bodyData)
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request, retry)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function postFormData(path, formData, retry = true) {

    console.log("posting form data to: " + path);

    let requestOptions = {
        method: 'POST',
        headers: { ...authService.authHeader() },
        body: formData
    };

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request, retry)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

async function deleteData(path, retry = true) {

    console.log("posting data to: " + path);

    let requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authService.authHeader() },
    };

    if (path.includes('token=')) {
        requestOptions.headers = { 'Content-Type': 'application/json' }
    }

    let request = new Request(apiUrl + path, requestOptions);

    return new Promise((resolve, reject) => {
        callAPI(request, retry)
            .then(data => resolve(data))
            .catch(error => reject(error));
    })
}

const callAPI = (request, shouldRetry) => {

    if (request.headers.has('Authorization')) {
        request.headers.set('Authorization', authService.authHeader().Authorization)
    }

    let requestCopy = request.clone();

    return new Promise((resolve, reject) => {
        fetch(request)
            .then(res => handleResponse(res, shouldRetry))
            .then(data => {
                return resolve(data)
            })
            .catch(error => {
                console.log("whole error", error);
                if (error.retry) {
                    //Q Promise for later
                    Queue.enqueue(() =>
                        callAPI(requestCopy)
                            .then(data => {
                                return resolve(data);
                            })
                            .catch(error => {
                                return reject(error.error);
                            })
                    )
                    //Try and get a new app token
                    if (!refreshLock) {
                        authService.getAppToken()
                            .then(() => {
                                Queue.dequeue();
                            })
                            .catch(error => {
                                if (error.requireAuth) {
                                    window.location.assign(accountUrl);
                                }
                                return reject(error.error);
                            })
                    }
                } else {
                    console.log("non retry error");
                    return reject(error.error);
                }
            })
    })

}


export const apiService = {
    getData,
    patchData,
    postData,
    postFormData,
    deleteData,
    callAPI
}