import React, { useState, useEffect } from 'react';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import Validation from '../../services/validation.service';
import { useForceUpdate } from '../Utils/ForceUpdate';
import { apiService } from '../../services/api.service';
import { useProfileState } from '../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
	},
	divider: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	iconSpacing: {
		marginRight: theme.spacing(2),
		fontSize: '2rem',
	},
	title: {
		marginTop: theme.spacing(3),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
		whiteSpace: 'nowrap',
	},
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		fontSize: '0.2rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	addressControlWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	addressControl: {
		height: '100%',
	},
}));

export const OrganisationEdit = ({ organisation, onSuccess, setOpen }) => {
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const { users, addresses, loading } = useProfileState();
	const classes = useStyles();
	const [thisLoading, setThisLoading] = useState(true);
	const [organisationName, setOrganisationName] = useState(
		organisation.organisationName
	);
	const [vatNumber, setVatNumber] = useState(organisation.vatNumber);
	const [mainContact, setMainContact] = useState(organisation.mainContact);
	const [mainAddress, setMainAddress] = useState(organisation.mainAddress);
	const [billingContact, setBillingContact] = useState(
		organisation.billingContact
	);
	const [billingAddress, setBillingAddress] = useState(
		organisation.billingAddress
	);
	const [deliveryContact, setDeliveryContact] = useState(
		organisation.deliveryContact
	);
	const [deliveryAddress, setDeliveryAddress] = useState(
		organisation.deliveryAddress
	);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setThisLoading(true);
		if (users && addresses && !loading) {
			setThisLoading(false);
		}
	}, [users, addresses, loading]);

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	const submitData = () => {
		let body = {
			organisationName: organisationName,
			vatNumber: vatNumber,
			mainContact: mainContact,
			mainAddress: mainAddress,
			billingContact: billingContact,
			billingAddress: billingAddress,
			deliveryContact: deliveryContact,
			deliveryAddress: deliveryAddress,
		};

		apiService
			.patchData('/organisation', body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setOpen(false);
				onSuccess();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	console.log('errors', errors);

	return (
		<>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading />
				) : (
					<div className={classes.root}>
						<div className={classes.content}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField
										fullWidth
										required
										id='organisationName'
										name='organisationName'
										variant='outlined'
										label='organisation name'
										value={organisationName}
										onChange={(e) => setOrganisationName(e.target.value)}
										onBlur={(e) => validate.checkNotEmpty(e)}
										error={Boolean(errors['organisationName'])}
										helperText={
											Boolean(errors['organisationName'])
												? errors['organisationName'].msg
												: ''
										}
									/>
								</Grid>
								{organisation.isBusiness ? (
									<Grid item xs={12} md={6}>
										<TextField
											fullWidth
											id='vatNumber'
											name='vatNumber'
											variant='outlined'
											label='VAT Number'
											value={vatNumber}
											onChange={(e) => setVatNumber(e.target.value)}
											error={Boolean(errors['vatNumber'])}
											helperText={
												Boolean(errors['vatNumber'])
													? errors['vatNumber'].msg
													: ''
											}
										/>
									</Grid>
								) : (
									<Grid item xs={12} md={6} />
								)}
								<Grid item xs={12} md={6}>
									<FormControl fullWidth required variant='outlined'>
										<InputLabel id='mainContact-label'>main contact</InputLabel>
										<Select
											fullWidth
											required
											variant='outlined'
											id='mainContact'
											disabled={Boolean(
												mainContact === '00000000-0000-0000-0000-000000000000'
											)}
											value={mainContact}
											onChange={(e) => setMainContact(e.target.value)}
											labelId='mainContact-label'
											label='main contact *'
											classes={{
												outlined: classes.select,
											}}>
											{users.length > 0 ? (
												users.map((user, i) => {
													return (
														<MenuItem key={i} value={user.id}>
															<Avatar
																className={classes.avatar}
																size='small'
																src={`https://cdn.fingoti.com/images/avatars/${user.id}`}
															/>
															{user.forename} {user.surname}
														</MenuItem>
													);
												})
											) : (
												<MenuItem
													value='00000000-0000-0000-0000-000000000000'
													disabled>
													<Avatar className={classes.avatar} size='small' />
													No users
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									className={classes.addressControlWrapper}>
									<FormControl
										fullWidth
										required
										variant='outlined'
										style={{ height: '100%' }}>
										<InputLabel id='mainAddress-label'>main address</InputLabel>
										<Select
											fullWidth
											required
											variant='outlined'
											id='mainAddress'
											value={mainAddress}
											onChange={(e) => setMainAddress(e.target.value)}
											labelId='mainAddress-label'
											label='main address *'
											style={{ height: '100%' }}>
											{addresses.map((address, i) => {
												return (
													<MenuItem key={i} value={address.id}>
														{address.line1},{' '}
														{address.line2 ? `${address.line2}, ` : ''}{' '}
														{address.postcode}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth required variant='outlined'>
										<InputLabel id='billingContact-label'>
											billing contact
										</InputLabel>
										<Select
											fullWidth
											required
											variant='outlined'
											id='billingContact'
											disabled={Boolean(
												billingContact ===
													'00000000-0000-0000-0000-000000000000'
											)}
											value={billingContact}
											onChange={(e) => setBillingContact(e.target.value)}
											labelId='billingContact-label'
											label='billing contact *'
											classes={{
												outlined: classes.select,
											}}>
											{users.length > 0 ? (
												users.map((user, i) => {
													return (
														<MenuItem key={i} value={user.id}>
															<Avatar
																className={classes.avatar}
																size='small'
																src={`https://cdn.fingoti.com/images/avatars/${user.id}`}
															/>
															{user.forename} {user.surname}
														</MenuItem>
													);
												})
											) : (
												<MenuItem
													value='00000000-0000-0000-0000-000000000000'
													disabled>
													<Avatar className={classes.avatar} size='small' />
													No users
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									className={classes.addressControlWrapper}>
									<FormControl
										fullWidth
										required
										variant='outlined'
										style={{ height: '100%' }}>
										<InputLabel id='billingAddress-label'>
											billing address
										</InputLabel>
										<Select
											fullWidth
											required
											variant='outlined'
											id='billingAddress'
											value={billingAddress}
											onChange={(e) => setBillingAddress(e.target.value)}
											labelId='billingAddress-label'
											label='billing address *'
											style={{ height: '100%' }}>
											{addresses.map((address, i) => {
												return (
													<MenuItem key={i} value={address.id}>
														{address.line1},{' '}
														{address.line2 ? `${address.line2}, ` : ''}{' '}
														{address.postcode}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} md={6}>
									<FormControl fullWidth required variant='outlined'>
										<InputLabel id='deliveryContact-label'>
											delivery contact
										</InputLabel>
										<Select
											fullWidth
											required
											variant='outlined'
											id='deliveryContact'
											disabled={Boolean(
												deliveryContact ===
													'00000000-0000-0000-0000-000000000000'
											)}
											value={deliveryContact}
											onChange={(e) => setDeliveryContact(e.target.value)}
											labelId='deliveryContact-label'
											label='delivery contact *'
											classes={{
												outlined: classes.select,
											}}>
											{users.length > 0 ? (
												users.map((user, i) => {
													return (
														<MenuItem key={i} value={user.id}>
															<Avatar
																className={classes.avatar}
																size='small'
																src={`https://cdn.fingoti.com/images/avatars/${user.id}`}
															/>
															{user.forename} {user.surname}
														</MenuItem>
													);
												})
											) : (
												<MenuItem
													value='00000000-0000-0000-0000-000000000000'
													disabled>
													<Avatar className={classes.avatar} size='small' />
													No users
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									className={classes.addressControlWrapper}>
									<FormControl
										fullWidth
										required
										variant='outlined'
										style={{ height: '100%' }}>
										<InputLabel id='deliveryAddress-label'>
											delivery address
										</InputLabel>
										<Select
											fullWidth
											required
											variant='outlined'
											id='deliveryAddress'
											value={deliveryAddress}
											onChange={(e) => setDeliveryAddress(e.target.value)}
											labelId='deliveryAddress-label'
											label='delivery address *'
											style={{ height: '100%' }}>
											{addresses.map((address, i) => {
												return (
													<MenuItem key={i} value={address.id}>
														{address.line1},{' '}
														{address.line2 ? `${address.line2}, ` : ''}{' '}
														{address.postcode}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</div>
					</div>
				)}
			</FingotiModalContent>
			{!thisLoading && (
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							type='submit'
							className={classes.button}
							color='primary'
							onClick={submitData}
							disabled={Boolean(Object.keys(errors).length > 0)}>
							SAVE ORGANISATION
						</FingotiButton>
						<FingotiButton
							light
							className={classes.button}
							onClick={() => setOpen(false)}>
							CANCEL
						</FingotiButton>
					</div>
				</FingotiModalActions>
			)}
		</>
	);
};
