import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { FingotiButton, FingotiLoading, FingotiHeader, FingotiDelete, FingotiModal, FingotiMarkdown } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import amex from 'payment-icons/min/flat/amex.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import visa from 'payment-icons/min/flat/visa.svg';

import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';
import { IconButton } from '@material-ui/core';
import { datetimeService } from '../../../services/datetime.service';
import { RetryPayment } from './RetryPayment';
import { TryPayment } from './TryPayment';

const useStyles = makeStyles(theme => ({

    root: {
        width: '100%',
        marginTop: theme.spacing(6),
    },
    tableHead: {
        color: theme.palette.primary.main,
    },
    currentUser: {
        background: theme.palette.primary.lighter,
    },
    actionIcons: {
        display: 'flex',
        alignItems: 'center',
    },
    actionIcon: {
        padding: 0,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    deleteIcon: {
        fill: '#FF0000',
    },
    editIcon: {
        fill: '#00FF00',
    },
    iconDisabled: {
        fill: theme.palette.action.disabled
    },
    noItemsRow: {
        textAlign: 'center',
        height: theme.spacing(32),
        borderBottom: 'none',
    },
    brandIcon: {
        verticalAlign: 'middle',
        width: '42px'
    },
    labelCell: {
        width: '22%',
        display: 'inline-block',
        padding: '2px',
        borderRadius: '3px',
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
        backgroundColor: theme.palette.primary.main
    },
    brandIcon: {
        verticalAlign: 'middle',
        width: '42px',
        marginRight: theme.spacing(1)
    },
    open: {
        color: theme.palette.warning.main,
        verticalAlign: 'middle',
    },
    paid: {
        color: theme.palette.success.main,
        verticalAlign: 'middle',
    },
    void: {
        color: theme.palette.error.main,
        verticalAlign: 'middle',
    },
    unknown: {
        color: theme.palette.greyFour.main,
        verticalAlign: 'middle',
    },
    paymentCard: {
        display: 'flex',
        alignItems: 'center',
    }

}));

export const BillingInvoices = () => {

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useProfileState();
    const [thisLoading, setThisLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [retryOpen, setRetryOpen] = useState(false);
    const [retryInvoice, setRetryInvoice] = useState({});
    const [tryOpen, setTryOpen] = useState(false);
    const [tryInvoice, setTryInvoice] = useState({});

    useEffect(() => {
        getInvoices();
    }, [loading])

    const getInvoices = () => {
        setThisLoading(true);
        apiService.getData('/billing/invoices')
            .then(result => {
                console.log("invocies", result);
                setInvoices(result.invoices);
                setThisLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setThisLoading(false);
            })
    }

    const handlePaymentSuccess = () => {
        getInvoices();
        setRetryOpen(false);
        setTryOpen(false);
    }

    const breadcrumbs = [
        { text: 'Billing', link: '/billing' },
        { text: 'Invoices', link: '' }
    ]

    return (
        <>
            {
                thisLoading ?
                    <FingotiLoading />
                    :
                    <>
                        <FingotiModal title="Retry Payment" open={retryOpen} setOpen={setRetryOpen}>
                            <RetryPayment invIn={retryInvoice} setOpen={setRetryOpen} onSuccess={handlePaymentSuccess} />
                        </FingotiModal>
                        <FingotiModal title="Payment" open={tryOpen} setOpen={setTryOpen}>
                            <TryPayment inv={tryInvoice} setOpen={setTryOpen} onDone={handlePaymentSuccess} />
                        </FingotiModal>
                        <FingotiHeader
                            sectionIcon={CreditCardIcon}
                            breadcrumbs={breadcrumbs}
                        />
                        <FingotiMarkdown
                            path="/documentation/account/billing/invoices.md"
                        />
                        <TableContainer>
                            <Table className={classes.root}>
                                <TableHead>
                                    <TableCell className={classes.tableHead}>invoice number</TableCell>
                                    <TableCell align="center" className={classes.tableHead}>status</TableCell>
                                    <TableCell className={classes.tableHead}>invoice date</TableCell>
                                    <TableCell className={classes.tableHead}>payment method</TableCell>
                                    <TableCell className={classes.tableHead}>total</TableCell>
                                    <TableCell className={classes.tableHead}>actions</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        invoices.length > 0 ?
                                            invoices.map((inv, i) => {

                                                let showCard = true;
                                                let chargeCard

                                                if (inv.status !== "open") {
                                                    if (inv.payment_intent && inv.status !== "void") {
                                                        chargeCard = inv.payment_intent.charges.data[inv.payment_intent.charges.data.length - 1].payment_method_details.card;
                                                    } else {
                                                        chargeCard = "-";
                                                        showCard = false;
                                                    }
                                                } else {
                                                    showCard = false;
                                                    chargeCard = "-";
                                                }

                                                let cardIcon;
                                                let statusIcon;

                                                switch (chargeCard.brand) {
                                                    case "amex":
                                                        cardIcon = amex;
                                                        break;
                                                    case "diners":
                                                        cardIcon = diners;
                                                        break;
                                                    case "discover":
                                                        cardIcon = discover;
                                                        break;
                                                    case "jcb":
                                                        cardIcon = jcb;
                                                        break;
                                                    case "mastercard":
                                                        cardIcon = mastercard;
                                                        break;
                                                    case "unionpay":
                                                        cardIcon = unionpay;
                                                        break;
                                                    case "visa":
                                                        cardIcon = visa;
                                                        break;
                                                    default:
                                                        cardIcon = "unk";
                                                        break;
                                                }

                                                switch (inv.status) {
                                                    case "open":
                                                        statusIcon = {
                                                            icon: <ErrorOutlineOutlinedIcon className={classes.open} />,
                                                            tooltip: 'needs attention'
                                                        }
                                                        break;
                                                    case "paid":
                                                        statusIcon = {
                                                            icon: <CheckCircleOutlineOutlinedIcon className={classes.paid} />,
                                                            tooltip: 'invoice paid'
                                                        }
                                                        break;
                                                    case "void":
                                                        statusIcon = {
                                                            icon: <CancelOutlinedIcon className={classes.void} />,
                                                            tooltip: 'invoice expired'
                                                        }
                                                        break;
                                                    default:
                                                        statusIcon = {
                                                            icon: <HelpOutlineOutlinedIcon className={classes.unknown} />,
                                                            tooltip: 'unkown'
                                                        }
                                                        break;
                                                }

                                                return (
                                                    <TableRow key={`${i}`}>
                                                        <TableCell>{inv.number}</TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title={statusIcon.tooltip}>
                                                                {statusIcon.icon}
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell>{datetimeService.formatDate(new Date(inv.status_transitions.finalized_at * 1000))}</TableCell>
                                                        <TableCell>
                                                            <div className={classes.paymentCard}>
                                                                {
                                                                    showCard ?
                                                                        <>
                                                                            <img src={cardIcon} className={classes.brandIcon} /> {!isMobile && '**** **** ****'} {chargeCard.last4}
                                                                        </>
                                                                        :
                                                                        'N/A'
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>£{(inv.total / 100).toFixed(2)}</TableCell>
                                                        <TableCell>
                                                            <div className={classes.actionIcons}>
                                                                <Tooltip title="view invoice">
                                                                    <IconButton onClick={() => window.open(inv.invoice_pdf, '_blank')} className={classes.actionIcon}>
                                                                        <InsertDriveFileOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {
                                                                    inv.amount_remaining !== 0 && inv.status !== "void" ?
                                                                        <Tooltip title="pay invoice">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    if (inv.attempted) {
                                                                                        setRetryInvoice(inv);
                                                                                        setRetryOpen(true);
                                                                                    } else {
                                                                                        setTryInvoice(inv);
                                                                                        setTryOpen(true);
                                                                                    }
                                                                                }}
                                                                                className={classes.actionIcon}
                                                                            >
                                                                                <CreditCardIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        :
                                                                        ''
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={6} className={classes.noItemsRow}>
                                                    You currently have no invoices
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
            }
        </>
    );
}