import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import { FingotiLoading, FingotiButton, FingotiModalContent, FingotiModal, FingotiModalActions } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { apiService } from '../../services/api.service';
import Validation from '../../services/validation.service';
import { useProfileState } from '../../context/ProfileContext';
import { useForceUpdate } from '../Utils/ForceUpdate';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },

}))

export const OrganisationUserInvite = ({ setOpen }) => {

    const classes = useStyles();
    const forceUpdate = useForceUpdate();
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState('');
    const [forename, setForname] = useState('');
    const [surname, setSurname] = useState('');
    const [thisLoading, setThisLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const { roles, loading } = useProfileState();

    useEffect(() => {
        if (roles && !loading) {
            setThisLoading(false);
        }
    }, [roles, loading])

    const handleErrorSet = (newErrors) => {
        setErrors(newErrors);
        forceUpdate();
    }

    const sendInvite = () => {
        setThisLoading(true);
        let body = { forename: forename, surname: surname, email: email, roleId: selectedRole }
        apiService.postData('/organisation/users', body)
            .then(result => {
                enqueueSnackbar(result.message, { variant: 'success' })
                setThisLoading(false);
                handleClose();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' })
                setThisLoading(false);
            })
    }

    const handleClose = () => {
        setOpen(false);
    }

    const validate = new Validation(errors, handleErrorSet);

    if (thisLoading) {
        return (
            <FingotiModalContent>
                <div className={classes.root}>
                    <FingotiLoading />
                </div>
            </FingotiModalContent>
        )
    } else {
        return (
            <>
                <FingotiModalContent>
                    <div className={classes.root}>
                        <form className={classes.form}>
                            <FormControl variant="outlined">
                                <TextField
                                    required
                                    id='forename'
                                    name='forename'
                                    variant='outlined'
                                    label='forename'
                                    onChange={(e) => setForname(e.target.value)}
                                    onBlur={(e) => validate.checkNotEmpty(e)}
                                    error={Boolean(errors['forename'])}
                                    helperText={Boolean(errors['forename']) ? errors['forename'].msg : ""}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl} variant="outlined">
                                <TextField
                                    id='surname'
                                    name='surname'
                                    variant='outlined'
                                    label='surname'
                                    onChange={(e) => setSurname(e.target.value)}
                                    error={Boolean(errors['surname'])}
                                    helperText={Boolean(errors['surname']) ? errors['surname'].msg : ""}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl} variant="outlined">
                                <TextField
                                    required
                                    id='email'
                                    name='email'
                                    variant='outlined'
                                    label='email address'
                                    onChange={(e) => setEmail(e.target.value.trim())}
                                    onBlur={(e) => validate.checkAgainstRegex(e)}
                                    error={Boolean(errors['email'])}
                                    helperText={Boolean(errors['email']) ? errors['email'].msg : ""}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl} variant="outlined">
                                <TextField
                                    required
                                    select
                                    variant='outlined'
                                    id='role'
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value.trim())}
                                    onBlur={(e) => validate.checkNotEmpty(e)}
                                    label='Role'
                                >
                                    {
                                        roles.map((role, i) => {
                                            return (
                                                <MenuItem key={i} value={role.id}>
                                                    {role.roleName}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </form>
                    </div>
                </FingotiModalContent>
                <FingotiModalActions>
                    <div className={classes.buttonGroup}>
                        <FingotiButton
                            light
                            color="primary"
                            className={classes.button}
                            onClick={sendInvite}
                            disabled={Boolean(Object.keys(errors).length > 0 || forename.length === 0 || email.length === 0 || selectedRole.length === 0)}
                        >
                            Send Invite
                        </FingotiButton>
                        <FingotiButton
                            light
                            onClick={handleClose}
                            className={classes.button}
                        >
                            Cancel
                        </FingotiButton>
                    </div>
                </FingotiModalActions>
            </>
        )
    }
}