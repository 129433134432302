import React, { useState, useEffect } from 'react';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import Validation from '../../services/validation.service';
import { useForceUpdate } from '../Utils/ForceUpdate';
import { apiService } from '../../services/api.service';
import { useProfileState } from '../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	content: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		alignItems: 'center',
		flexDirection: 'column',
	},
	divider: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	iconSpacing: {
		marginRight: theme.spacing(2),
		fontSize: '2rem',
	},
	title: {
		marginTop: theme.spacing(3),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
		whiteSpace: 'nowrap',
	},
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		fontSize: '0.2rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	addressControlWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	addressControl: {
		height: '100%',
	},
}));

export const UserDetailsEdit = ({ onSuccess, setOpen }) => {
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const { user, loading } = useProfileState();
	const classes = useStyles();
	const [thisLoading, setThisLoading] = useState(true);
	const [forename, setForename] = useState(user.forename);
	const [surname, setSurname] = useState(user.surname);
	const [mobile, setMobile] = useState(user.mobile);
	const [telephone, setTelephone] = useState(user.telephone);
	const [marketing, setMarketing] = useState(user.marketing);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setThisLoading(true);
		if (user && !loading) {
			setThisLoading(false);
		}
	}, [user, loading]);

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	const submitData = () => {
		let body = {
			forename: forename,
			surname: surname,
			mobile: mobile,
			telephone: telephone,
			marketing: marketing,
		};

		apiService
			.patchData('/user', body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				setOpen(false);
				onSuccess();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	console.log('errors', errors);

	return (
		<>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading />
				) : (
					<div className={classes.root}>
						<div className={classes.content}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField
										fullWidth
										required
										id='forename'
										name='forename'
										variant='outlined'
										label='forename'
										value={forename}
										onChange={(e) => setForename(e.target.value)}
										onBlur={(e) => validate.checkNotEmpty(e)}
										error={Boolean(errors['forename'])}
										helperText={
											Boolean(errors['forename']) ? errors['forename'].msg : ''
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										fullWidth
										required
										id='surname'
										name='surname'
										variant='outlined'
										label='surname'
										value={surname}
										onChange={(e) => setSurname(e.target.value)}
										onBlur={(e) => validate.checkNotEmpty(e)}
										error={Boolean(errors['surname'])}
										helperText={
											Boolean(errors['surname']) ? errors['surname'].msg : ''
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										fullWidth
										id='mobile'
										name='mobile'
										variant='outlined'
										label='mobile'
										value={mobile}
										onChange={(e) => setMobile(e.target.value)}
										onBlur={(e) => validate.checkAgainstRegex(e)}
										error={Boolean(errors['mobile'])}
										helperText={
											Boolean(errors['mobile']) ? errors['mobile'].msg : ''
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										fullWidth
										id='telephone'
										name='telephone'
										variant='outlined'
										label='telephone'
										value={telephone}
										onChange={(e) => setTelephone(e.target.value)}
										onBlur={(e) => validate.checkAgainstRegex(e)}
										error={Boolean(errors['telephone'])}
										helperText={
											Boolean(errors['telephone'])
												? errors['telephone'].msg
												: ''
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl required variant='outlined'>
										<FormControlLabel
											control={
												<Switch
													checked={marketing}
													onChange={() => setMarketing(!marketing)}
													name='marketing'
													color='primary'
												/>
											}
											label='marketing'
										/>
									</FormControl>
								</Grid>
							</Grid>
						</div>
					</div>
				)}
			</FingotiModalContent>
			{!thisLoading && (
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							type='submit'
							className={classes.button}
							color='primary'
							onClick={submitData}
							disabled={Boolean(
								Object.keys(errors).length > 0 ||
									forename.length === 0 ||
									surname.length === 0
							)}>
							SAVE
						</FingotiButton>
						<FingotiButton
							light
							className={classes.button}
							onClick={() => setOpen(false)}>
							CANCEL
						</FingotiButton>
					</div>
				</FingotiModalActions>
			)}
		</>
	);
};
