export default class Validation {

    errors;

    constructor(errors, setErrors) {
        this.errors = errors;
        this.setErrors = setErrors;
    }

    removeError = (field) => {
        console.log("removing errors", this.errors);
        delete this.errors[field];
        this.setErrors(this.errors);
        return this.errors;
    }

    checkNotEmpty = (e) => {
        if (!e.target.value) {
            this.errors[e.target.name] = { msg: "cannot be empty" }
            this.setErrors(this.errors);
            return false;
        } else {
            this.removeError(e.target.name);
            return true;
        }
    }

    checkRequiredFields = (data) => {
        let valid = [];

        for (var field of Object.keys(data)) {
            if (data[field]) {
                valid[field] = true;
            } else {
                valid[field] = false;
            }
        }

        if (Object.values(valid).every(el => el === true)) {
            return true;
        } else {
            Object.keys(valid).forEach(el => {
                if (valid[el] === false) {
                    this.errors[el] = { msg: "cannot be empty" }
                }
            })

            this.setErrors(this.errors);
            return false;
        }
    }

    checkAgainstRegex = (e) => {

        const fields = {
            email: {
                regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                msg: "email is invalid"
            },
            password: {
                regex: /^((?=.*[a-z])(?=.*[A-Z])(?=.*[!@$£%^&*()_+|~=`:";'<>?,./-])(?=.*\d)).{6,32}$/,
                msg: "password does not meet complexity requirements"
            },
            mobile: {
                regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                msg: "invalid mobile number"
            },
            telephone: {
                regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                msg: "invalid telephone number"
            },
            userHandle: {
                regex: /^[a-zA-Z0-9]+$/,
                msg: "user handle must not contain special characters"
            },
            organisationHandle: {
                regex: /^[a-zA-Z0-9]+$/,
                msg: "organisation handle must not contain special characters"
            }
        }

        if (this.checkNotEmpty(e)) {
            if (fields[e.target.name].regex.test(e.target.value)) {
                this.removeError(e.target.name);
                return true;
            } else {
                this.errors[e.target.name] = { msg: fields[e.target.name].msg }
                this.setErrors(this.errors)
                return false;
            }
        } else if (e.target.name === "mobile" || e.target.name === "telephone") {
            this.removeError(e.target.name);
            return true;
        }
    }

    checkPasswordsMatch = (e, comparator) => {
        if (this.checkNotEmpty(e)) {
            if (comparator !== e.target.value) {
                this.errors['confirmPassword'] = { msg: "passwords do not match" }
                this.setErrors(this.errors);
                return false;
            } else {
                this.removeError('confirmPassword');
                return true;
            }
        } else {
            this.errors['confirmPassword'] = { msg: "cannot be empty" }
            this.setErrors(this.errors);
            return false;
        }
    }

    checkValidPostcode = (e) => {
        if (this.checkNotEmpty(e)) {
            let regEx = /(GIR 0AA)|((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY])))) [0-9][ABDEFGHJLNPQRSTUWXYZ]{2})/i;
            //Remove any whitespace, add a space 3 char from end, convert to uppercase 
            let postcode = e.target.value.replace(/\s/g, "").replace(/(?=.{3}$)/, ' ').toUpperCase();
            if (regEx.test(postcode)) {
                this.removeError(e.target.name);
                return true;
            } else {
                this.errors[e.target.name] = { msg: "Postcode not valid" }
                return false;
            }
        }
    }
}