import React, { createContext, useContext, useReducer } from 'react';

const initialState = { loggedIn: false, loading: true, error: false, update: 0 }

const ProfileStateContext = createContext();
const ProfileDispatchContext = createContext();

const ProfileReducer = (state, action) => {
    switch (action.type) {
        case 'LOGGED_IN':
            console.log("PROFILE CONTEXT - Logged in");
            return { ...state, loggedIn: true }
        case 'LOADED':
            console.log("PROFILE CONTEXT - Data loaded");
            return { ...state, loading: false, error: false }
        case 'LOADING':
            console.log("PROFILE CONTEXT - Data loading");
            return { ...state, loading: true, error: false }
        case 'ERROR':
            console.log("PROFILE CONTEXT - Error");
            return { ...state, loading: false, error: true }
        case 'ADD_USER':
            console.log("PROFILE CONTEXT - Adding user...");
            return { ...state, user: { ...action.payload } }
        case 'ADD_ORGANISATION':
            console.log("PROFILE CONTEXT - Adding organisation...");
            return { ...state, organisation: { ...action.payload } }
        case 'ADD_ROLES':
            console.log("PROFILE CONTEXT - Adding roles...");
            return { ...state, roles: [...action.payload] }
        case 'ADD_ORGANISATIONS':
            console.log("PROFILE CONTEXT - Adding organisations...");
            return { ...state, organisations: [...action.payload] }
        case 'ADD_ADDRESSES':
            console.log("PROFILE CONTEXT - Adding addresses...");
            return { ...state, addresses: [...action.payload] }
        case 'ADD_USERS':
            console.log("PROFILE CONTEXT - Adding users...");
            return { ...state, users: [...action.payload] }
        case 'LOGOUT':
            console.log("PROFILE CONTEXT - Removing context data...");
            return initialState;
        case 'UPDATE':
            console.log("PROFILE CONTEXT - Forcing update...");
            return { ...state, update: state.update++ }
        default:
            console.error(`Unhandled action dispatched to user reducer, action type was: ${action.type}`);
            return state;
    }
}

const ProfileProvider = ({ children }) => {

    const [state, dispatch] = useReducer(ProfileReducer, initialState)

    return (
        <ProfileStateContext.Provider value={state}>
            <ProfileDispatchContext.Provider value={dispatch}>
                {children}
            </ProfileDispatchContext.Provider>
        </ProfileStateContext.Provider>
    );
};

const useProfileState = () => {
    const context = useContext(ProfileStateContext);

    if (context === undefined) {
        throw new Error('useProfileState must be used within a ProfileContextProvider')
    }

    return context;
};

const useProfileDispatch = () => {
    const context = useContext(ProfileDispatchContext);

    if (context === undefined) {
        throw new Error('useProfileDispatch must be used within a ProfileContextProvider')
    }

    return context;
};

export {
    ProfileProvider,
    useProfileDispatch,
    useProfileState
}