import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FingotiButton, FingotiHeader } from '@fingoti/components';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import { apiService } from '../../services/api.service';
import Validation from '../../services/validation.service';
import { useForceUpdate } from '../Utils/ForceUpdate';

export const UserRegister = ({ classes, submitStep, inviteInfo, checkNotUserHandle }) => {

    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const [forename, setForename] = useState('');
    const [surname, setSurname] = useState('');
    const [userHandle, setUserHandle] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [telephone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [marketing, setMarketing] = useState(false);
    const [inviteProvided, setInviteProvided] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        if (Object.keys(inviteInfo).length > 0) {
            setEmail(inviteInfo.email);
            setForename(inviteInfo.forename);
            setSurname(inviteInfo.surname);
            setInviteProvided(true);
            generateHandle(inviteInfo.forename, inviteInfo.surname);
        }
    }, [])

    const handleSetErrors = (errors) => {
        setErrors(errors);
        forceUpdate();
    }

    const validate = new Validation(errors, handleSetErrors);

    const checkSpecialChar = (e) => {
        if (validate.checkAgainstRegex(e)) {
            checkDoesntExist(e);
        }
    }

    const checkDoesntExist = async (e) => {
        if (validate.checkNotEmpty(e)) {

            let fieldName = e.target.name;
            let fieldValue = e.target.value;
            let body = { [fieldName]: fieldValue }

            try {
                let data = await apiService.postData("/user/details", body);
                if (!data[fieldName]) {
                    errors[fieldName] = { msg: fieldName + " already exists" }
                    handleSetErrors(errors);
                    return false;
                } else if (errors[fieldName]) {
                    delete errors[fieldName];
                    handleSetErrors(errors);
                    return true;
                } else {
                    return true;
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    const generateHandle = async (f, s) => {

        let fn = f || forename;
        let sn = s || surname;

        if (userHandle === '') {
            let generatedHandle = '';
            let finalHandle = '';

            generatedHandle = `${fn.substring(0, 4).trim()}${sn.substring(0, 4).trim()}`;

            const addRandomNumbers = () => {
                let rn = Math.floor(Math.random() * (999 - 1)).toString();
                let paddedRn = rn.padStart(3, "0");

                return generatedHandle + paddedRn;
            }

            finalHandle = addRandomNumbers();

            let fakeEvent = {
                target: {
                    name: "userHandle",
                    value: generatedHandle
                }
            }

            let doesntExist = await checkDoesntExist(fakeEvent);

            if (!doesntExist) {
                finalHandle = addRandomNumbers();
            }

            delete errors['userHandle'];
            handleSetErrors(errors);
            setUserHandle(finalHandle.toLowerCase());
        }
    }

    const handleNextStep = async (e) => {
        let reqFields = { forename: forename, surname: surname, userHandle: userHandle, email: email, password: password, confirmPassword: confirmPassword };
        let allFields = { forename: forename, surname: surname, userHandle: userHandle, email: email, mobile: mobile, telephone: telephone, marketing: marketing, password: password, confirmPassword: confirmPassword };

        //To fix auto complete       
        let fakeEvent = {
            target: {
                value: email,
                name: 'email'
            }
        }

        if (!inviteProvided) {

            let exist = await checkDoesntExist(fakeEvent);

            if (exist) {
                if (validate.checkRequiredFields(reqFields)) {
                    submitStep(allFields);
                }
            }
        } else {
            if (validate.checkRequiredFields(reqFields)) {
                submitStep(allFields);
            }
        }
    }

    const endAdornmentContent = () => {
        return (
            <InputAdornment position="end">
                {
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                }
            </InputAdornment>
        )
    };

    const breadcrumbs = [
        { text: 'Create User' }
    ]

    return (
        <React.Fragment>
            <FingotiHeader
                breadcrumbs={breadcrumbs}
                sectionIcon={PersonAddOutlinedIcon}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h6'>
                        Basic Info
                    </Typography>
                    <FormControl className={classes.formControl} fullWidth variant='outlined'>
                        <TextField
                            fullWidth
                            required
                            id='forename'
                            name='forename'
                            variant='outlined'
                            label='forename'
                            value={forename}
                            onChange={(e) => setForename(e.target.value.trim())}
                            onBlur={(e) => validate.checkNotEmpty(e)}
                            error={Boolean(errors['forename'])}
                            helperText={Boolean(errors['forename']) ? errors['forename'].msg : ""}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            required
                            id='surname'
                            name='surname'
                            variant='outlined'
                            label='surname'
                            value={surname}
                            onChange={(e) => setSurname(e.target.value.trim())}
                            onBlur={(e) => { validate.checkNotEmpty(e); generateHandle(); }}
                            error={Boolean(errors['surname'])}
                            helperText={Boolean(errors['surname']) ? errors['surname'].msg : ""}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            required
                            id='userHandle'
                            name='userHandle'
                            variant='outlined'
                            label='user handle'
                            value={userHandle}
                            onChange={(e) => setUserHandle(e.target.value.toLowerCase())}
                            onBlur={(e) => checkSpecialChar(e)}
                            error={Boolean(errors['userHandle'])}
                            helperText={Boolean(errors['userHandle']) ? errors['userHandle'].msg : ""}
                            inputProps={{ maxLength: 32 }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant='h6'>
                        Contact Details
                    </Typography>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            required
                            id='email'
                            name='email'
                            variant='outlined'
                            label='email'
                            value={email}
                            disabled={inviteProvided}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={(e) => checkDoesntExist(e)}
                            error={Boolean(errors['email'])}
                            helperText={Boolean(errors['email']) ? errors['email'].msg : ""}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            id='mobile'
                            name='mobile'
                            variant='outlined'
                            label='mobile'
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            onBlur={(e) => validate.checkAgainstRegex(e)}
                            error={Boolean(errors['mobile'])}
                            helperText={Boolean(errors['mobile']) ? errors['mobile'].msg : ""}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            id='telephone'
                            name='telephone'
                            variant='outlined'
                            label='telephone'
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                            onBlur={(e) => validate.checkAgainstRegex(e)}
                            error={Boolean(errors['telephone'])}
                            helperText={Boolean(errors['telephone']) ? errors['telephone'].msg : ""}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} >
                        <FormControlLabel
                            control={
                                <Switch className="switch"
                                    color="primary"
                                    name="marketing"
                                    label="Recieve marketing?"
                                    checked={marketing}
                                    onClick={() => setMarketing(!marketing)}
                                />
                            }
                            label="Recieve marketing?"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant='h6'>
                        Password
                    </Typography>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            required
                            type={showPassword ? 'text' : 'password'}
                            id='password'
                            name='password'
                            variant='outlined'
                            label='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={(e) => validate.checkAgainstRegex(e)}
                            error={Boolean(errors['password'])}
                            helperText={Boolean(errors['password']) ? errors['password'].msg : ""}
                            InputProps={{
                                endAdornment: endAdornmentContent(),
                            }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            fullWidth
                            required
                            type='password'
                            id='confirmPassword'
                            name='confirmPassword'
                            variant='outlined'
                            label='confirm password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onBlur={(e) => validate.checkPasswordsMatch(e, password)}
                            error={Boolean(errors['confirmPassword'])}
                            helperText={Boolean(errors['confirmPassword']) ? errors['confirmPassword'].msg : ""}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        type="submit"
                        className={classes.button}
                        color="primary"
                        onClick={handleNextStep}
                        disabled={Boolean(Object.keys(errors).length > 0 ||
                            forename.length === 0 ||
                            surname.length === 0 ||
                            userHandle.length === 0 ||
                            email.length === 0 ||
                            password.length === 0 ||
                            confirmPassword.length === 0
                        )}
                    >
                        NEXT STEP
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => history.goBack()}
                    >
                        CANCEL
                    </FingotiButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}