import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { authUrl } from '../../services/config';
import { handleResponse } from '../../services/response.service';
import { useForceUpdate } from '../Utils/ForceUpdate';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		textAlign: 'center',
	},
	OTP: {
		marginTop: theme.spacing(2),
	},
	manualCode: {
		color: theme.palette.primary.main,
	},
	recoveryCodes: {
		width: '50%',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	recoveryCode: {
		fontFamily: 'Courier New',
		color: theme.palette.secondary.main
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const UserSecurityMFASetup = ({ setOpen, onSetup }) => {
	const classes = useStyles();
	const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [QRData, setQRData] = useState();
	const [manualCode, setManualCode] = useState();
	const [thisLoading, setThisLoading] = useState(true);
	const [OTP, setOTP] = useState('');
	const [error, setError] = useState(false);
	const [errors, setErrors] = useState({});
	const [submitting, setSubmitting] = useState(false);
	const [recoveryCodes, setRecoveryCodes] = useState([]);

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	useEffect(() => {
		getQRCode();
	}, []);

	const getQRCode = () => {
		let requestOptions = {
			method: 'GET',
			credentials: 'include',
		};

		fetch(`${authUrl}/mfa/enable`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				console.log('res', result);
				setQRData(result.qrCode);
				setManualCode(result.manual);
				setThisLoading(false);
			})
			.catch((error) => {
				console.error('ERROR', error);
				setError(true);
				setThisLoading(false);
			});
	};

	const validateOTP = (e) => {
		let regex = new RegExp(/^[0-9]*$/);

		console.log(regex);

		if (!regex.test(e.target.value)) {
			errors['verifyOTP'] = { msg: 'OTP must only contain numbers' };
			handleSetErrors(errors);
		} else {
			delete errors['verifyOTP'];
			handleSetErrors(errors);
		}
	};

	const sendOTP = () => {
		setSubmitting(true);
		let requestOptions = {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				code: OTP,
			}),
		};

		fetch(`${authUrl}/mfa/verify`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				console.log('res', result);
				getRecoveryCodes();
			})
			.catch((error) => {
				console.error('ERROR', error);
				errors['verifyOTP'] = { msg: 'OTP invalid' };
				handleSetErrors(errors);
				setSubmitting(false);
			});
	};

	const getRecoveryCodes = () => {
		let requestOptions = {
			method: 'GET',
			credentials: 'include',
		};

		fetch(`${authUrl}/mfa/recover`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				console.log('res', result);
				setRecoveryCodes(result.codes);
			})
			.catch((error) => {
				console.error('ERROR', error);
				setError(true);
			});
	};

	const copyRecoveryCodes = async () => {
		try {
			await navigator.clipboard.writeText(recoveryCodes.join('\n'));
			enqueueSnackbar('Recovery codes copied to clipboard', {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar('Failed to copy recovery codes to clipboard', {
				variant: 'error',
			});
		}
	};

	const downloadRecoveryCodes = () => {
		var element = document.createElement('a');
		element.setAttribute(
			'href',
			'data:text/plain;charset=utf-8,' +
				encodeURIComponent(recoveryCodes.join('\n'))
		);
		element.setAttribute('download', 'fingoti-recovery-codes.txt');

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};

	return (
		<>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading fullHeight={false} />
				) : error ? (
					<Typography>
						Looks live we've run into a problem, please try again, if the error
						persists please contact support
					</Typography>
				) : (
					<div className={classes.root}>
						<div className={classes.content}>
							{recoveryCodes.length > 0 ? (
								<>
									<Typography>
										MFA has been enabled successfully, each of the 16 codes
										shown below are recovery codes for use if you no longer have
										access to your MFA app, please keep a copy of these codes
										secure.
									</Typography>
									<Grid container className={classes.recoveryCodes}>
										{recoveryCodes.map((rc, i) => (
											<Grid item xs={6}>
												<Typography
													key={`code-${i}`}
													className={classes.recoveryCode}>
													<strong>{rc}</strong>
												</Typography>
											</Grid>
										))}
									</Grid>
									<div
										className={classes.buttonGroup}
										style={{ marginLeft: 'auto', marginRight: 'auto' }}>
										<FingotiButton
											light
											color='primary'
											onClick={copyRecoveryCodes}>
											Copy to clipboard
										</FingotiButton>
										<FingotiButton
											light
											color='primary'
											onClick={downloadRecoveryCodes}>
											Download as TXT
										</FingotiButton>
									</div>
								</>
							) : (
								<>
									<Typography>
										Please scan the below QR code with your prefered
										authenticator app, alternatively manually enter the code
										shown below the QR code
									</Typography>

									<img src={QRData} className={classes.qr} />
									<Typography className={classes.manualCode}>
										{manualCode}
									</Typography>

									<Typography className={classes.OTP}>
										Please enter the 6 digit code shown on your app to verfiy
									</Typography>
									<TextField
										required
										id='verifyOTP'
										name='verifyOTP'
										variant='outlined'
										label='otp'
										value={OTP}
										inputProps={{
											maxLength: 6,
										}}
										onChange={(e) => {
											validateOTP(e);
											setOTP(e.target.value);
										}}
										onBlur={(e) => validateOTP(e)}
										className={classes.OTP}
										error={Boolean(errors['verifyOTP'])}
										helperText={
											Boolean(errors['verifyOTP'])
												? errors['verifyOTP'].msg
												: ''
										}
									/>
								</>
							)}
						</div>
					</div>
				)}
			</FingotiModalContent>

			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					{recoveryCodes.length === 0 && (
						<FingotiButton
							light
							color='primary'
							className={classes.button}
							loading={submitting}
							disabled={Boolean(
								Object.keys(errors).length > 0 || OTP.length !== 6
							)}
							onClick={() => sendOTP()}>
							Verify
						</FingotiButton>
					)}
					<FingotiButton
						light
						className={classes.button}
						onClick={
							recoveryCodes.length > 0 ? () => onSetup() : () => setOpen(false)
						}>
						Close
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
