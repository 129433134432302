import React from 'react';
import { useHistory } from 'react-router-dom';
import { FingotiMarkdown, FingotiButton, FingotiHeader } from '@fingoti/components';

import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import { cdnUrl } from '../../services/config';

export const Overview = ({ handleNextStep, classes }) => {

    const history = useHistory();
    const theme = useTheme();

    const breadcrumbs = [
        { text: 'How It Works' }
    ];

    return (
        <>
            <FingotiHeader
                breadcrumbs={breadcrumbs}
                sectionIcon={PersonAddOutlinedIcon}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FingotiMarkdown
                        path="/documentation/account/register/overview.md"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={`${cdnUrl}/images/static/User-Org-Infographic.png`} width="100%" />
                </Grid>
                <Grid item xs={12} md={6} /> {/* force new row */}
                <Grid item xs={12} md={4} className={classes.buttonGroup} style={{ marginTop: theme.spacing(4) }}>
                    <FingotiButton
                        light
                        type="submit"
                        className={classes.button}
                        color="primary"
                        onClick={handleNextStep}
                    >
                        CONTINUE
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => history.goBack()}
                    >
                        CANCEL
                    </FingotiButton>
                </Grid>
            </Grid>
        </>
    );
}