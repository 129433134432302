import React, { useRef, useCallback, useEffect, useState } from 'react';
import {
	FingotiButton,
	FingotiDelete,
	FingotiModal,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';

import SvgIcon from '@material-ui/core/SvgIcon';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { UserSessionInfo } from './UserSessionInfo';

import { apiService } from '../../services/api.service';
import { datetimeService } from '../../services/datetime.service';
import { authService } from '../../services/auth.service';
import { ssoService } from '../../services/sso.service';

import { ReactComponent as FingotiLogo } from '../../icons/FingotiIcon.svg';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	actionIcon: {
		padding: 0,
	},
	client: {
		display: 'flex',
		alignItems: 'center',
	},
	clientIcon: {
		marginRight: theme.spacing(2),
		fill: theme.palette.greyTwo.main,
	},
	currentSession: {
		background: theme.palette.primary.lighter,
	},
}));

const WindowsIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<path d='M3,12V6.75L9,5.43V11.91L3,12M20,3V11.75L10,11.9V5.21L20,3M3,13L9,13.09V19.9L3,18.75V13M20,13.25V22L10,20.09V13.1L20,13.25Z' />
		</SvgIcon>
	);
};

const FingotiIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<FingotiLogo />
		</SvgIcon>
	);
};

export const UserSessions = () => {
	const theme = useTheme();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [infoSession, setInfoSession] = useState();
	const [infoOpen, setInfoOpen] = useState(false);
	const [currentSession, setCurrentSession] = useState('');
	const tableRef = useRef();

	const getSessionId = useCallback(async () => {
		let sessionId = await authService.findAppToken();
		console.log('session', sessionId.sid);
		setCurrentSession(sessionId.sid);
	}, []);

	useEffect(() => {
		getSessionId();
	}, [getSessionId]);

	const getSessions = (query) => {
		return apiService
			.getData(URLGenerator(query, '/user/sessions'))
			.then(async (data) => {
				let sessionId = await authService.findAppToken();
				data.sessions.sort((a, b) => {
					return new Date(b.loginTime) - new Date(a.loginTime);
				});
				let removeIndex = data.sessions.findIndex(
					(session) => session.id === sessionId.sid
				);
				let currentSession = data.sessions[removeIndex];
				delete data.sessions[removeIndex];
				data.sessions.unshift(currentSession);
				let sessions = data.sessions.filter((s) => s !== undefined);
				return {
					data: sessions,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const endSession = (id) => {
		ssoService
			.ssoLogout(id)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const endAllSessions = () => {
		ssoService
			.ssoLogout('all')
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const handleInfoOpen = (session) => {
		setInfoSession(session);
		setInfoOpen(true);
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton light color='primary' onClick={() => endAllSessions()}>
					Close all sessions
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'User', link: '/user' },
		{ text: 'Sessions', link: '' },
	];

	const platformRenderer = (rd) => {
		let icon = <HelpOutlineOutlinedIcon className={classes.clientIcon} />;

		if (rd.platform.includes('Windows')) {
			icon = <WindowsIcon className={classes.clientIcon} />;
		}

		if (rd.platform.includes('Android')) {
			icon = <AndroidOutlinedIcon className={classes.clientIcon} />;
		}

		if (
			rd.platform.includes('iOS') ||
			rd.platform.includes('iPad') ||
			rd.platform.includes('macOS')
		) {
			icon = <AppleIcon className={classes.clientIcon} />;
		}

		if (rd.platform.includes('Fingoti')) {
			icon = <FingotiIcon className={classes.clientIcon} />;
		}

		return (
			<div className={classes.client}>
				{icon} {rd.platform}
			</div>
		);
	};

	const lastActiveRenderer = (rd) => {
		return rd.id === currentSession
			? 'Active Now'
			: datetimeService.formatDateTimeNoSeconds(new Date(rd.loginTime));
	};

	return (
		<React.Fragment>
			<FingotiModal
				title='Session Information'
				open={infoOpen}
				setOpen={setInfoOpen}>
				<UserSessionInfo session={infoSession} setOpen={setInfoOpen} />
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				actionButtons={actionButtons()}
				sectionIcon={PersonIcon}
			/>
			<FingotiMarkdown path='/documentation/account/user/sessions.md' />
			<FingotiTable
				data={getSessions}
				tableRef={tableRef}
				isLoading={currentSession === ''}
				options={{
					filtering: false,
					rowStyle: (rowData) => {
						return {
							backgroundColor:
								rowData.id === currentSession && theme.palette.primary.lighter,
						};
					},
				}}
				columns={[
					{ title: 'id', field: 'id', hidden: true },
					{ title: 'platform', render: platformRenderer },
					{ title: 'client', field: 'client' },
					{ title: 'location', field: 'location' },
					{ title: 'last active', render: lastActiveRenderer },
				]}
				actions={[
					(rowData) => ({
						icon: InfoOutlinedIcon,
						tooltip: 'session information',
						onClick: () => handleInfoOpen(rowData),
					}),
					(rowData) => ({
						icon: () =>
							rowData.id !== currentSession ? (
								<FingotiDelete
									remove
									tooltipText='end session'
									className={classes.actionIcon}
									onClick={() => endSession(rowData.id)}
								/>
							) : (
								<></>
							),
					}),
				]}
			/>
		</React.Fragment>
	);
};
