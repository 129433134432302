import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { authUrl } from '../../services/config';
import { handleResponse } from '../../services/response.service';
import Validation from '../../services/validation.service';
import { useForceUpdate } from '../Utils/ForceUpdate';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		textAlign: 'center',
	},
	OTP: {
        width: '70%',
		marginTop: theme.spacing(2),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
}));

export const UserSecurityMFAPasswordConfirm = ({ setOpen, onSuccess, regenerate = false }) => {
	const classes = useStyles();
    const forceUpdate = useForceUpdate();
	const { enqueueSnackbar } = useSnackbar();
	const [password, setPassword] = useState('');
	const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	const sendPassword = () => {
		setSubmitting(true);
		let requestOptions = {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				password: password,
			}),
		};

		fetch(`${authUrl}/mfa/${regenerate ? 'recover/regenerate' : 'disable'}`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				console.log('res', result);
				setSubmitting(false);
				enqueueSnackbar(result.message, { variant: 'success' });
				onSuccess();
			})
			.catch((error) => {
				console.error('ERROR', error);
				errors['password'] = { msg: 'Password incorrect' };
				handleSetErrors(errors);
				setSubmitting(false);
			});
	};

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<div className={classes.content}>
						<Typography>
							Please enter your password to confirm
						</Typography>
						<TextField

							required
							id='password'
							name='password'
							variant='outlined'
							label='Password'
                            type='password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							onBlur={(e) => validate.checkNotEmpty(e)}
							className={classes.OTP}
							error={Boolean(errors['password'])}
							helperText={
								Boolean(errors['password']) ? errors['password'].msg : ''
							}
						/>
					</div>
				</div>
			</FingotiModalContent>

			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						color='primary'
						className={classes.button}
						loading={submitting}
						disabled={Boolean(
							Object.keys(errors).length > 0 || password.length < 8
						)}
						onClick={() => sendPassword()}>
						{regenerate ? 'Regenerate' : 'Disable'}
					</FingotiButton>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						Close
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
