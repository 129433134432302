import React, { useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useStripe } from '@stripe/react-stripe-js';
import { FingotiButton, FingotiLoading, FingotiModalContent, FingotiModalActions } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { apiService } from '../../../services/api.service';
import { CardSelect } from '../cards/CardSelect';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    root: {
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center'
    },
    tableHead: {
        color: theme.palette.primary.main,
    },
    spacing: {
        margin: theme.spacing(2) + 'px auto',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    select: {
        textAlign: 'left',
        width: '50%'
    }

}))


export const RetryPayment = ({ setOpen, onSuccess, invIn }) => {

    const classes = useStyles();
    const stripe = useStripe();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCard, setSelectedCard] = useState('');
    const [thisLoading, setThisLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [inv, setInv] = useState(invIn);

    const getInvoice = () => {
        setThisLoading(true);
        apiService.getData(`/billing/invoices/${inv.id}`)
            .then(result => {
                console.log("invocies", result);
                setInv(result.invoices[0]);
                setThisLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: 'error' });
                setThisLoading(false);
            })
    }

    const authenticatePayment = async () => {
        setSubmitted(true);
        let { error, paymentIntent } = await stripe.confirmCardPayment(inv.payment_intent.client_secret);

        if (error) {
            handlePaymentError(error);
        } else {
            console.log("[payment intent]", paymentIntent);
            setSubmitted(false);
            enqueueSnackbar("Payment authenticated successfully", { variant: 'success' });
            onSuccess();
        }

    }

    const updatePaymentMethod = async () => {
        setSubmitted(true);
        let { error, paymentIntent } = await stripe.confirmCardPayment(inv.payment_intent.client_secret, { payment_method: selectedCard });

        if (error) {
            handlePaymentError(error);
        } else {
            console.log("[payment intent]", paymentIntent);
            setSubmitted(false);
            enqueueSnackbar("Payment method updated successfully", { variant: 'success' });
            onSuccess();
        }
    }

    const handlePaymentError = (error) => {
        console.error(error);
        getInvoice();
        setSubmitted(false);
        enqueueSnackbar("Payment failed", { variant: 'error' });
    }

    if (thisLoading) {
        return (
            <FingotiModalContent>
                <FingotiLoading fullHeight={false} />
            </FingotiModalContent>
        )
    } else {
        return (
            <>
                <FingotiModalContent>
                    <div className={classes.root}>
                        <Typography>
                            It looks like there was an issue taking payment.
                        </Typography>
                        <Typography className={classes.failMsg}>
                            {
                                inv.payment_intent.charges.length > 0 ?
                                    inv.payment_intent.charges.data[0].failure_message
                                    :
                                    ''
                            }
                        </Typography>
                        {
                            inv.payment_intent.status === "requires_action" ?
                                <>
                                    <br />
                                    <Typography>
                                        You may wish to retry the payment
                                    </Typography>
                                </>
                                :
                                ''
                        }
                        {
                            inv.payment_intent.status === "requires_payment_method" ?
                                <>
                                    <br />
                                    <Typography>
                                        Please select another payment method and try again
                                    </Typography>
                                    <br />
                                    <CardSelect className={clsx(classes.spacing, classes.select)} onChange={(v) => setSelectedCard(v)} />
                                </>
                                :
                                ''
                        }


                    </div>
                </FingotiModalContent>
                <FingotiModalActions>
                    <div className={classes.buttonGroup}>
                        <FingotiButton
                            light
                            color="primary"
                            className={classes.button}
                            loading={submitted}
                            onClick={inv.payment_intent.status === "requires_payment_method" ? updatePaymentMethod : authenticatePayment}
                        >
                            RETRY PAYMENT
                        </FingotiButton>
                        <FingotiButton
                            light
                            className={classes.button}
                            onClick={() => setOpen(false)}
                        >
                            CANCEL
                        </FingotiButton>
                    </div>
                </FingotiModalActions>
            </>
        );
    }
}