import React, { useEffect, useState } from 'react';
import { FingotiLoading, FingotiFullscreenStatus, FingotiButton } from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import { authUrl } from '../../services/config';
import { ssoService } from '../../services/sso.service';
import { useProfileState } from '../../context/ProfileContext';

const useStyles = makeStyles((theme) => ({

    root: {
        height: '75vh'
    },

}))

export const VerifyEmail = () => {

    const classes = useStyles();
    const [thisLoading, setThisLoading] = useState(true);
    const [error, setError] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { loading } = useProfileState();
    let resendSnackKey = undefined;

    useEffect(() => {
        if (!loading) {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('token')) {
                sendToken(urlParams.get('token'));
            } else {
                setError(true);
                setThisLoading(false);
            }
        }
    }, [loading])

    const ResendButton = () => {
    
        const resend = () => {
            ssoService.ssoResendVerification()
                .then(res => {
                    enqueueSnackbar(res.message, { variant: res.success ? 'success' : 'error' });
                })
                .catch(() => {
                    enqueueSnackbar("Something went wrong", { variant: 'error' });
                })
                .finally(() => {
                    closeSnackbar(resendSnackKey);
                })
        }
    
        return (
            <FingotiButton
                light
                color="primary"
                onClick={resend}
            >
                Re-send
            </FingotiButton>
        )
    }

    const sendToken = (token) => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        };

        fetch(`${authUrl}/sso/email/verify`, requestOptions)
            .then(response => {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (!response.ok) {
                        throw new Error(`${response.statusText}`);
                    } else {
                        return data;
                    }
                })
            })
            .then(() => {
                setThisLoading(false);
            })
            .catch(() => {
                setError(true);
                setThisLoading(false);
                resendSnackKey = enqueueSnackbar(
                    "Failed to veify email address, click to resend",
                    {
                        variant: 'warning',
                        persist: true,
                        action: ResendButton,
                        preventDuplicate: true
                    });
            })
    }

    return (
        <React.Fragment>
            {
                thisLoading ?
                    <FingotiLoading />
                    :
                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        {
                            error ?
                                <FingotiFullscreenStatus
                                    tagline="Failed to verify email address"
                                    message="Looks like we've run into some issues, please contact support"
                                />
                                :
                                <FingotiFullscreenStatus
                                    success
                                    tagline="Email verified"
                                    message="Email verified successfully, you can now log in"
                                />
                        }
                    </Grid>
            }
        </React.Fragment>
    )
}