import React, { useState, useEffect } from 'react';
import { FingotiButton, FingotiLoading, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { useProfileState } from '../../context/ProfileContext';
import { datetimeService } from '../../services/datetime.service';
import { apiService } from '../../services/api.service';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    avatar: {
        fontSize: '1.1rem',
        border: '2px solid #E0E0E0',
        marginRight: theme.spacing(2),
    },
    organisationName: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    noBottomBorder: {
        border: 'none',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '24%',
        color: theme.palette.greyTwo.main,
    },
    expired: {
        color: '#FF0000',
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    enabledIcon: {
        verticalAlign: 'middle',
        fill: theme.palette.success.main,
    },
    disabledIcon: {
        verticalAlign: 'middle',
        fill: theme.palette.error.main,
    },


}))

export const UserTokenInfo = ({ setOpen, token }) => {

    const classes = useStyles()

    let hasExpired = new Date(token.expiry) <= new Date() && token.expiry !== null ? true : false;

    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>organisation</TableCell>
                                    <TableCell className={clsx(classes.noBottomBorder, classes.organisationName)}>
                                        <Avatar className={classes.avatar} size="small" src={`https://cdn.fingoti.com/images/avatars/${token.organisationId}`}>

                                        </Avatar>
                                        {token.organisationName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>token name</TableCell>
                                    <TableCell className={classes.noBottomBorder}>{token.tokenName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>enabled</TableCell>
                                    <TableCell className={classes.noBottomBorder}>
                                        {
                                            token.disabled ?
                                                <BlockOutlinedIcon className={classes.disabledIcon} />
                                                :
                                                <CheckCircleOutlineIcon className={classes.enabledIcon} />
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>created</TableCell>
                                    <TableCell className={classes.noBottomBorder}>{datetimeService.formatDateTimeNoSeconds(new Date(token.dateCreated))}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>expires</TableCell>
                                    <TableCell className={clsx(classes.noBottomBorder, { [classes.expired]: hasExpired })}>
                                        {token.expiry === null ? "Never" : datetimeService.formatDateTimeNoSeconds(new Date(token.expiry))}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>token key</TableCell>
                                    <TableCell style={{ wordWrap: 'anywhere' }} className={classes.noBottomBorder}>{token.tokenKey}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </FingotiModalContent>

            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    );

}