import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: '12rem !important',
		marginBottom: theme.spacing(6),
		color: theme.palette.error.main,
	},
	message: {
		marginTop: theme.spacing(2),
	},
}));

export const ManagedByPartner = ({ partnerName }) => {
	const classes = useStyles();

	return (
		<Box
			display='flex'
			height='60vh'
			alignItems='center'
			justifyContent='center'>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<BlockOutlinedIcon className={classes.icon} />
				<Typography variant='h3'>Partner Managed</Typography>
				<Typography className={classes.message}>
					{`Billing for your organisation is managed by your partner${partnerName !== undefined ? ` ${partnerName}` : ''}, please
					contact your partner to make changes`}
				</Typography>
			</Box>
		</Box>
	);
};
