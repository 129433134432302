import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { FingotiButton, FingotiLoading } from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import LockIcon from '@material-ui/icons/Lock';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
	useProfileDispatch,
	useProfileState,
} from '../../context/ProfileContext';
import { ssoService } from '../../services/sso.service';
import { ProfileData } from '../../context/ProfileData';
import { authService } from '../../services/auth.service';
import { handleResponse } from '../../services/response.service';
import { useForceUpdate } from '../Utils/ForceUpdate';
import { authUrl } from '../../services/config';
import Validation from '../../services/validation.service';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '75vh',
		width: '95%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	title: {
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	divider: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(6),
	},
	iconSpacing: {
		marginRight: theme.spacing(2),
		fontSize: '3rem',
	},
	button: {
		width: '90%',
	},
	form: {
		width: '100%',
	},
	email: {
		marginBottom: theme.spacing(1),
	},
	password: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(6),
	},
	alignRight: {
		textAlign: 'right',
	},
}));

export const Login = () => {
	const history = useHistory();
	const classes = useStyles();
	const profileData = ProfileData();
	const { loading, loggedIn } = useProfileState();
	const { enqueueSnackbar } = useSnackbar();
	const forceUpdate = useForceUpdate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [thisLoading, setThisLoading] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [showOTPInput, setShowOTPInput] = useState(false);
	const [OTP, setOTP] = useState('');
	const [errors, setErrors] = useState({});
	const [isRecovery, setIsRecovery] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const handleSetErrors = (errors) => {
		setErrors(errors);
		forceUpdate();
	};

	const validate = new Validation(errors, handleSetErrors);

	//Need 2 vars for fullscreen loading and login submission

	useEffect(() => {
		if (!loading) {
			setThisLoading(false);
			if (loggedIn) {
				history.push('/organisation');
			}
		}
	}, [loggedIn, loading, history]);

	const login = (formData) => {
		setSubmitting(true);
		formData.preventDefault();
		let returnUrl = tryGetReturnUrl();
		console.log('return url', returnUrl);
		ssoService
			.ssoLogin(email, password)
			.then((user) => {
				console.log('user login', user);
				if (user.mfaEnabled) {
					setShowOTPInput(true);
					setSubmitting(false);
				} else {
					if (returnUrl !== null) {
						//Throw user back to where they came from with cookie
						window.location.replace(returnUrl);
					} else {
						//Normal login, push user to account settings
						getUserData();
					}
				}
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
				setSubmitting(false);
			});
	};

	const getUserData = () => {
		authService.getAppToken().then(() => {
			profileData.getData().then(() => {
				history.push('/organisation');
			});
		});
	};

	const validateOTP = (e) => {
		let regex = isRecovery
			? new RegExp(/^[0-9a-f]{5}-[0-9a-f]{5}$/)
			: new RegExp(/^[0-9]*$/);

		console.log(regex);

		if (!regex.test(e.target.value)) {
			errors['otp'] = {
				msg: `${
					isRecovery ? 'invalid recovery code' : 'otp must only contain numbers'
				}`,
			};
			handleSetErrors(errors);
		} else {
			delete errors['otp'];
			handleSetErrors(errors);
		}
	};

	const sendOTP = (e) => {
		setSubmitting(true);
		e.preventDefault();
		
		let returnUrl = tryGetReturnUrl();
		console.log('return url otp', returnUrl);

		let requestOptions = {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				code: OTP,
			}),
		};

		let route = isRecovery ? 'recover' : 'verify';

		fetch(`${authUrl}/mfa/${route}`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				console.log('res', result);
				if (returnUrl !== null) {
					//Throw user back to where they came from with cookie
					window.location.replace(returnUrl);
				} else {
					//Normal login, push user to account settings
					getUserData();
				}
			})
			.catch((error) => {
				console.error('Error', error);
				errors['otp'] = {
					msg: `${isRecovery ? 'recovery code' : 'otp'} invalid`,
				};
				handleSetErrors(errors);
				setSubmitting(false);
			});
	};

	const tryGetReturnUrl = () => {
		let params = new URLSearchParams(window.location.search);
		return params.get('ReturnUrl');
	};

	const endAdornmentContent = () => {
		return (
			<InputAdornment position='end'>
				{password ? (
					<IconButton
						aria-label='toggle password visibility'
						onClick={() => setShowPassword(!showPassword)}
						edge='end'>
						{showPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				) : (
					<Link component={RouterLink} to={`/reset?email=${email}`}>
						forgotten?
					</Link>
				)}
			</InputAdornment>
		);
	};

	return thisLoading ? (
		<FingotiLoading fullHeight />
	) : (
		<Grid
			container
			spacing={2}
			justify='center'
			alignItems='center'
			className={classes.root}>
			<Grid item xs={12} lg={3}>
				{showOTPInput ? (
					<>
						<Typography className={classes.title} variant='h5'>
							<LockIcon className={classes.iconSpacing} />
							MFA {isRecovery ? 'Recovery' : 'Verification'}
						</Typography>
						<Divider className={classes.divider} />
						<form className={classes.form} onSubmit={(e) => sendOTP(e)}>
							<FormControl
								fullWidth
								variant='outlined'
								className={classes.password}>
								<TextField
									required
									fullWidth
									value={OTP}
									name='otp'
									id='otp'
									variant='outlined'
									label={isRecovery ? 'recovery code' : 'otp'}
									inputProps={{
										maxLength: isRecovery ? 11 : 6,
									}}
									onChange={(e) => {
										validateOTP(e);
										setOTP(e.target.value);
									}}
									error={Boolean(errors['otp'])}
									helperText={Boolean(errors['otp']) ? errors['otp'].msg : ''}
								/>
							</FormControl>
							<div>
								<Grid container spacing={3}>
									<Grid item xs={6} className={classes.alignRight}>
										<FingotiButton
											light
											loading={submitting}
											className={classes.button}
											disabled={Boolean(
												Object.keys(errors).length || OTP.length < 6
											)}
											type='submit'
											color='primary'>
											{isRecovery ? 'Recover' : 'Verify'}
										</FingotiButton>
									</Grid>
									<Grid item xs={6}>
										<FingotiButton
											light
											className={classes.button}
											onClick={() => {
												handleSetErrors({});
												setOTP('');
												setIsRecovery(!isRecovery);
											}}>
											{isRecovery ? 'Cancel' : 'Recovery'}
										</FingotiButton>
									</Grid>
								</Grid>
							</div>
						</form>
					</>
				) : (
					<>
						<Typography className={classes.title} variant='h5'>
							<AssignmentIndIcon className={classes.iconSpacing} />
							User Account Login
						</Typography>
						<Divider className={classes.divider} />
						<form className={classes.form} onSubmit={(e) => login(e)}>
							<FormControl
								fullWidth
								variant='outlined'
								className={classes.email}>
								<TextField
									required
									fullWidth
									value={email}
									type='text'
									name='email'
									id='email'
									variant='outlined'
									label='email address'
									onChange={(e) => setEmail(e.target.value)}
									onBlur={(e) => validate.checkAgainstRegex(e)}
									error={Boolean(errors['email'])}
									helperText={
										Boolean(errors['email']) ? errors['email'].msg : ''
									}
								/>
							</FormControl>
							<FormControl
								fullWidth
								variant='outlined'
								className={classes.password}>
								<TextField
									required
									fullWidth
									value={password}
									type={showPassword ? 'text' : 'password'}
									name='password'
									id='password'
									variant='outlined'
									label='password'
									onChange={(e) => setPassword(e.target.value)}
									InputProps={{
										endAdornment: endAdornmentContent(),
									}}
									onBlur={(e) => validate.checkNotEmpty(e)}
									error={Boolean(errors['password'])}
									helperText={
										Boolean(errors['password']) ? errors['password'].msg : ''
									}
								/>
							</FormControl>
							<div>
								<Grid container spacing={3}>
									<Grid item xs={6} className={classes.alignRight}>
										<FingotiButton
											light
											className={classes.button}
											loading={submitting}
											disabled={Boolean(
												Object.keys(errors).length > 0 ||
													email.length < 5 ||
													password.length < 1
											)}
											type='submit'
											color='primary'>
											Login
										</FingotiButton>
									</Grid>
									<Grid item xs={6}>
										<FingotiButton
											light
											className={classes.button}
											onClick={() => history.push('/register')}>
											Register
										</FingotiButton>
									</Grid>
								</Grid>
							</div>
						</form>
					</>
				)}
			</Grid>
		</Grid>
	);
};
