import React, { useEffect, useState, useRef } from 'react';
import {
	FingotiModal,
	FingotiLoading,
	FingotiButton,
	FingotiDelete,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined';

import { OrganisationRoleEdit } from './OrganisationRoleEdit';
import { OrganisationRoleAdd } from './OrganisationRoleAdd';
import { OrganisationRoleDependentUsers } from './OrganisationRoleDependentUsers';

import { useProfileState } from '../../context/ProfileContext';
import { apiService } from '../../services/api.service';
import { useRoleCheck } from '../Utils/RoleCheck';
import { ProfileData } from '../../context/ProfileData';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(6),
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	currentUser: {
		background: theme.palette.primary.lighter,
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
	},
	deleteIcon: {
		fill: '#FF0000',
	},
	editIcon: {
		fill: '#00FF00',
	},
	iconDisabled: {
		fill: theme.palette.action.disabled,
	},
	roleCell: {
		minWidth: 52,
		display: 'inline-block',
		padding: '2px',
		borderRadius: '3px',
		[theme.breakpoints.up('md')]: {
			width: '55%',
		},
	},
	None: {
		backgroundColor: theme.palette.greyFour.main,
	},
	Read: {
		backgroundColor: theme.palette.secondary.light,
	},
	Write: {
		backgroundColor: theme.palette.primary.light,
	},
}));

export const OrganisationRoles = () => {
	const theme = useTheme();
	const classes = useStyles();
	const profileData = ProfileData();
	const { organisation, organisations, users, loading } =
		useProfileState();
	const { enqueueSnackbar } = useSnackbar();
	const { roleCheck } = useRoleCheck();
	const [currentRole, setCurrentRole] = useState('');
	const [thisLoading, setThisLoading] = useState(true);
	const [editOpen, setEditOpen] = useState(false);
	const [editRole, setEditRole] = useState({});
	const [addOpen, setAddOpen] = useState(false);
	const [dependentUsers, setDependentUsers] = useState([]);
	const [dependentOpen, setDependentOpen] = useState(false);
	const tableRef = useRef();

	useEffect(() => {
		setThisLoading(true);
		if (organisation && organisations && !loading) {
			findCurrentRole();
		}
	}, [loading, organisation, organisations]);

	const findCurrentRole = () => {
		let currentOrganisation = organisations.find(
			(org) => org.id === organisation.id
		);

		if (currentOrganisation) {
			setCurrentRole(currentOrganisation.roleId);
		}

		setThisLoading(false);
	};

	const getRoles = async (query) => {
		return apiService
			.getData(URLGenerator(query, '/organisation/roles'))
			.then((data) => {
				data.roles.sort((a, b) => a.roleName.localeCompare(b.roleName));
				return {
					data: data.roles,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const permissionRenderer = (rd, permission) => {
		let role = roleString(rd[permission]);

		return (
			<span className={clsx(classes[role], classes.roleCell)}>{role}</span>
		);
	};

	const refreshRoles = () => {
		profileData.getRoles();
		tableRef.current.onQueryChange();
	};

	const handleEditOpen = (role) => {
		setEditRole(role);
		setEditOpen(true);
	};

	const handleNewRole = () => {
		setAddOpen(false);
		refreshRoles();
	};

	const handleEditRole = () => {
		setEditOpen(false);
		refreshRoles();
	};

	const handleDeleteRole = (roleId) => {
		profileData.getUsers().then(() => {
			let usersWithRole = users.filter((u) => u.roleId === roleId);
			if (usersWithRole.length > 0) {
				setDependentUsers(usersWithRole);
				setDependentOpen(true);
			} else {
				apiService
					.deleteData(`/organisation/roles/${roleId}`)
					.then((result) => {
						enqueueSnackbar(result.message, { variant: 'success' });
						refreshRoles();
					})
					.catch((error) => {
						enqueueSnackbar(error, { variant: 'error' });
						refreshRoles();
					});
			}
		});
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					color='primary'
					disabled={roleCheck('user', 2)}
					onClick={() => setAddOpen(true)}>
					new role
				</FingotiButton>
			</React.Fragment>
		);
	};

	const roleString = (role) => {
		switch (role) {
			case 0:
				return 'None';
			case 1:
				return 'Read';
			case 2:
				return 'Write';
			default:
				return '';
		}
	};

	const breadcrumbs = [
		{ text: 'Organisation', link: '/organisation' },
		{ text: 'Roles', link: '' },
	];

	return (
		<React.Fragment>
			{thisLoading ? (
				<FingotiLoading />
			) : (
				<React.Fragment>
					<FingotiModal
						title='Create New Role'
						open={addOpen}
						setOpen={setAddOpen}>
						<OrganisationRoleAdd
							handleNewRole={handleNewRole}
							setOpen={setAddOpen}
						/>
					</FingotiModal>
					<FingotiModal
						title='Edit Existing Role'
						open={editOpen}
						setOpen={setEditOpen}>
						<OrganisationRoleEdit
							handleEditRole={handleEditRole}
							editRole={editRole}
							setOpen={setEditOpen}
						/>
					</FingotiModal>
					<FingotiModal
						title='Dependent Users'
						open={dependentOpen}
						setOpen={setDependentOpen}>
						<OrganisationRoleDependentUsers
							dependentUsers={dependentUsers}
							setOpen={setDependentOpen}
						/>
					</FingotiModal>
					<FingotiHeader
						breadcrumbs={breadcrumbs}
						actionButtons={actionButtons()}
						sectionIcon={AccountBalanceOutlined}
					/>
					<FingotiMarkdown path='/documentation/account/organisation/roles.md' />
					<FingotiTable
						data={getRoles}
						tableRef={tableRef}
						columns={[
							{ title: 'id', field: 'id', hidden: true },
							{ title: 'role name', field: 'roleName' },
							{
								title: 'organisation',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'organisation'),
							},
							{
								title: 'billing',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'billing'),
							},
							{
								title: 'user',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'user'),
							},
							{
								title: 'device',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'device'),
							},
							{
								title: 'rule',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'rule'),
							},
							{
								title: 'schedule',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'schedule'),
							},
							{
								title: 'follow',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'follow'),
							},
							{
								title: 'webhook',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'webhook'),
							},
							{
								title: 'partner',
								align: 'center',
								render: (rd) => permissionRenderer(rd, 'partner'),
							},
						]}
						actions={[
							(rowData) =>
								!Boolean(
									rowData.id === organisation.adminRole ||
										rowData.id === organisation.readRole
								) && {
									icon: () => (
										<FingotiDelete
											disabled={roleCheck('user', 2)}
											className={classes.actionIcon}
											tooltipText='delete role'
											onClick={() => handleDeleteRole(rowData.id)}
										/>
									),
								},
							(rowData) =>
								!Boolean(
									rowData.id === organisation.adminRole ||
										rowData.id === organisation.readRole
								) && {
									icon: EditOutlinedIcon,
									disabled: roleCheck('user', 2),
									onClick: () => handleEditOpen(rowData),
									tooltip: 'edit role',
								},
						]}
						options={{
							rowStyle: (rowData) => {
								return {
									backgroundColor:
										rowData.id === currentRole && theme.palette.primary.lighter,
								};
							},
						}}
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
