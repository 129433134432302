import React, { useState, useEffect, useRef } from 'react';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import { DateTimePicker } from '@material-ui/pickers';

import { useProfileState } from '../../context/ProfileContext';
import { apiService } from '../../services/api.service';
import { Typography } from '@material-ui/core';
import { OrganisationTokenAssignment } from './OrganisationTokenAssignment';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	formControl: {
		marginTop: theme.spacing(3),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		fontSize: '0.2rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	tenantLabel: {
		fontWeight: 'bolder',
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		borderTop: '1px solid ' + theme.palette.divider,
		borderBottom: '1px solid ' + theme.palette.divider,
	},
}));

export const OrganisationTokenEdit = ({ token, handleEditToken, setOpen }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [thisLoading, setThisLoading] = useState(true);
	const [name, setName] = useState(token.tokenName);
	const [enabled, setEnabled] = useState(!token.disabled);
	const [selectedRole, setSelectedRole] = useState(token.roleId);
	const [enableExpiry, setEnableExpiry] = useState(token.expiry ? true : false);
	const [expiry, setExpiry] = useState(new Date(token.expiry));
	const [assignedUsers, setAssignedUsers] = useState([]);
	const [validUsers, setValidUsers] = useState([]);
	const { roles, users, loading } = useProfileState();
	const assignedRef = useRef();

	useEffect(() => {
		if (roles && users && !loading) {
			if (token.assignedUsers.length > 0) {
				let userObjs = [];
				token.assignedUsers.forEach((au) => {
					userObjs.push(users.find((u) => u.id === au));
				});

				let valid = users.filter((u) => !token.assignedUsers.some(au => au === u.id));

				setAssignedUsers(userObjs);
				setValidUsers(valid);
			} else {
				setValidUsers(users);
			}

			setThisLoading(false);
		}
	}, [roles, users, loading]);

	const updateToken = (event) => {
		event.preventDefault();
		let body = {
			disabled: !enabled,
			tokenName: name,
			roleId: selectedRole,
			expiry: enableExpiry ? expiry : null,
			assignedUsers: assignedRef.current().map((a) => a.id),
		};

		apiService
			.patchData(`/organisation/tokens/${token.id}`, body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				handleEditToken();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	return (
		<>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading />
				) : (
					<div className={classes.root}>
						<form className={classes.form}>
							<Grid container spacing={4} alignItems='center'>
								<Grid item md={8}>
									<FormControl required fullWidth variant='outlined'>
										<TextField
											required
											id='tokenName'
											name='tokenName'
											variant='outlined'
											label='token name'
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</FormControl>
								</Grid>
								<Grid item md={4}>
									<FormControl>
										<FormControlLabel
											control={
												<Switch
													checked={enabled}
													onChange={() => setEnabled(!enabled)}
													name='enable'
													color='primary'
												/>
											}
											label='enabled'
										/>
									</FormControl>
								</Grid>
								<Grid item xs={8}>
									<FormControl required fullWidth variant='outlined'>
										<InputLabel id='roleSelect'>role</InputLabel>
										<Select
											required
											variant='outlined'
											id='role'
											name='role'
											value={selectedRole}
											onChange={(e) => setSelectedRole(e.target.value)}
											labelId='roleSelect'
											label='role *'>
											{roles.map((r, i) => {
												return (
													<MenuItem key={r.id} value={r.id}>
														{r.roleName}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl required variant='outlined'>
										<FormControlLabel
											control={
												<Switch
													checked={enableExpiry}
													onChange={() => setEnableExpiry(!enableExpiry)}
													name='enableExpiry'
													color='primary'
												/>
											}
											label='enable expiry?'
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Collapse in={enableExpiry}>
										<FormControl required fullWidth variant='outlined'>
											<DateTimePicker
												DialogProps={{ style: { zIndex: 1400 } }}
												value={expiry}
												hideTabs
												disablePast
												inputVariant='outlined'
												onChange={setExpiry}
												label='expiry date/time'
											/>
										</FormControl>
									</Collapse>
								</Grid>
								<Grid item xs={12}>
									<OrganisationTokenAssignment
										validUsers={validUsers}
										assignedUsers={assignedUsers}
										assignedRef={assignedRef}
									/>
								</Grid>
							</Grid>
						</form>
					</div>
				)}
			</FingotiModalContent>

			{!thisLoading && (
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							type='submit'
							color='primary'
							className={classes.button}
							onClick={updateToken}>
							update token
						</FingotiButton>
						<FingotiButton
							light
							className={classes.button}
							onClick={() => setOpen(false)}>
							Cancel
						</FingotiButton>
					</div>
				</FingotiModalActions>
			)}
		</>
	);
};
