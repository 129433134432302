import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiLoading,
	FingotiHeader,
	FingotiModal,
	FingotiButton,
	FingotiMarkdown,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';

import CreditCardIcon from '@material-ui/icons/CreditCard';

import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';
import { StartSubscription } from './StartSubscription';
import { CurrentSubscription } from './CurrentSubscription';
import { StartSubscriptionDetails } from './StartSubscriptionDetails';
import { ManagedByPartner } from './ManagedByPartner';

const useStyles = makeStyles((theme) => ({
	textSpacing: {
		marginBottom: theme.spacing(6),
	},
}));

export const BillingSubscription = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { organisation, loading } = useProfileState();
	const [thisLoading, setThisLoading] = useState(true);
	const [billingInfo, setBillingInfo] = useState({});
	const [tenants, setTenants] = useState();
	const [open, setOpen] = useState(false);
	let waitForWebhook;

	useEffect(() => {
		setThisLoading(true);
		if (!loading && organisation) {
			getBillingInfo().then(() => {
				if (organisation.isPartner) {
					getTenants();
				} else {
					setThisLoading(false);
				}
			});
		}

		return () => {
			clearTimeout(waitForWebhook);
		};
	}, [loading, organisation]);

	const getBillingInfo = () => {
		return new Promise((resolve) => {
			apiService
				.getData('/billing')
				.then((result) => {
					console.log('result', result);
					if (result.subscriptions.length > 0 && organisation.isPartner) {
						let removeIndex = result.subscriptions.findIndex(
							(sub) => sub.subscriptionId === result.subscriptionId
						);
						if (removeIndex !== -1) {
							let thisSubscription = result.subscriptions[removeIndex];
							result.subscriptions.splice(removeIndex, 1);
							result.subscriptions.unshift(thisSubscription);
						}
						console.log('billing', result);
						setBillingInfo(result);
						resolve(result);
					} else {
						setBillingInfo(result);
						resolve(result);
					}
				})
				.catch((error) => {
					enqueueSnackbar(error, { variant: 'error' });
					setBillingInfo({ subscriptions: [] });
					setThisLoading(false);
				});
		});
	};

	const getTenants = (page = 1, tenants = []) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData(
					`/organisation/tenants?itemsPerPage=50&pageNumber=${page}`
				)
				.then((tenantsData) => {

					tenants = [...tenants, ...tenantsData.tenants];

					if (tenantsData.count > tenants.length) {
						getTenants(page + 1, tenants)
							.then(resolve)
							.catch(reject);
					} else {
						setTenants(tenants);
						setThisLoading(false);
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const actionButtons = () => {
		if (organisation.isPartner) {
			return (
				<>
					<FingotiButton
						light
						disabled={
							!tenants.some((t) => !t.subscriptionId) &&
							billingInfo.subscriptionId
						}
						color='primary'
						onClick={() => setOpen(true)}>
						add subscription
					</FingotiButton>
				</>
			);
		} else {
			return;
		}
	};

	const onCreateSuccess = () => {
		waitForWebhook = setTimeout(() => {
			getBillingInfo().then(() => {
				if (organisation.isPartner) {
					getTenants();
				}
			});
		}, 3000);
	};

	const onEditSuccess = () => {
		getBillingInfo().then(() => {
			if (organisation.isPartner) {
				getTenants();
			}
		});
	};

	const breadcrumbs = [
		{ text: 'Billing', link: '/billing' },
		{ text: 'Subscriptions', link: '' },
	];

	return (
		<>
			<FingotiModal title='Setup Subscription' open={open} setOpen={setOpen}>
				<StartSubscriptionDetails
					tenants={tenants}
					billingInfo={billingInfo}
					setOpen={setOpen}
					onSuccess={onCreateSuccess}
				/>
			</FingotiModal>
			<FingotiHeader
				sectionIcon={CreditCardIcon}
				breadcrumbs={breadcrumbs}
				actionButtons={!thisLoading && actionButtons()}
			/>
			{thisLoading ? (
				<FingotiLoading />
			) : organisation.partnerId !== null ? (
				<ManagedByPartner />
			) : (
				<>
					<FingotiMarkdown
						className={classes.textSpacing}
						path='/documentation/account/billing/subscriptions.md'
					/>
					{billingInfo.subscriptions.length > 0 ? (
						<CurrentSubscription
							tenants={tenants}
							subscriptions={billingInfo.subscriptions}
							billingInfo={billingInfo}
							onSuccess={onEditSuccess}
						/>
					) : (
						<StartSubscription
							tenants={tenants}
							billingInfo={billingInfo}
							onSuccess={onCreateSuccess}
						/>
					)}
				</>
			)}
		</>
	);
};
