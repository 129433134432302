import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';
import { FingotiLoading, FingotiTabs } from '@fingoti/components';

import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { UserSessions } from './UserSessions';
import { UserDetails } from './UserDetails';
import { UserTokens } from './UserTokens';
import { UserSecurity } from './UserSecurity';

const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%'
  },
  tab: {
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  iconSpacing: {
    marginRight: theme.spacing(2),
    fontSize: '3rem'
  },
  tabRoot: {
    minWidth: 'unset',
  },
  tabLabelRight: {
    alignItems: 'unset',
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
  boxPadding: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  }

}));

const TabPanel = (props) => {

  const { passClass, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={passClass}>
          {children}
        </div>
      )}
    </div>
  );
}

const tabProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const RouterTab = (props) => <Tab component={RouterLink} {...props} />

export const User = ({ activeMenu }) => {

  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(activeMenu);
  });

  const handleChange = (event, newValue) => {

    let isTwoPartPath = match.path.indexOf('/', 1);
    let baseRoute = "/user";

    if (isTwoPartPath !== -1) {
      //Path is /foo/bar, need to get rid of the /bar
      baseRoute = match.path.slice(0, isTwoPartPath)
    }

    switch (newValue) {
      case 0:
        history.push(`${baseRoute}`);
        break;
      case 1:
        history.push(`${baseRoute}/sessions`);
        break;
      case 2:
        history.push(`${baseRoute}/tokens`);
        break;
      case 3:
        history.push(`${baseRoute}/security`);
        break;
      default:
        console.log("not a valid value");
        break;
    }

    setValue(newValue);

  };

  return (
    value === null ?
      <FingotiLoading />
      :
      <div className={classes.root}>
        <FingotiTabs
          value={value}
          onChange={handleChange}
          aria-label="user settings tabs"
        >
          <RouterTab to={'/user'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Overview" {...tabProps(0)} />
          <RouterTab to={'/user/sessions'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Sessions" {...tabProps(1)} />
          <RouterTab to={'/user/tokens'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Tokens" {...tabProps(2)} />
          <RouterTab to={'/user/security'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Security" {...tabProps(3)} />
        </FingotiTabs>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={0}>
          <UserDetails />
        </TabPanel>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={1}>
          <UserSessions />
        </TabPanel>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={2}>
          <UserTokens />
        </TabPanel>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={3}>
          <UserSecurity />
        </TabPanel>
      </div>
  );
}