import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FingotiButton, FingotiLoading, FingotiFullscreenStatus } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { useForceUpdate } from '../Utils/ForceUpdate';
import Validation from '../../services/validation.service';
import { authUrl } from '../../services/config';

const useStyles = makeStyles((theme) => ({

    root: {
        height: '75vh',
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    message: {
        height: '40%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    iconSpacing: {
        marginRight: theme.spacing(2),
        fontSize: '3rem',
    },
    form: {
        width: '100%'
    },
    email: {
        marginBottom: theme.spacing(6)
    },
    button: {
        width: '90%',
    },
    alignRight: {
        textAlign: 'right',
    }

}))

export const EnterEmail = () => {


    const forceUpdate = useForceUpdate();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let query = params.get('email');
        setEmail(query)
    }, []);

    const handleSetErrors = (errors) => {
        setErrors(errors);
        forceUpdate();
    }

    const validate = new Validation(errors, handleSetErrors);

    const requestReset = () => {
        setLoading(true);
        setSubmitted(true);

        if (!errors.length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email })
            };

            fetch(`${authUrl}/sso/password/request`, requestOptions)
                .then(response => {
                    return response.text().then(text => {
                        const data = text && JSON.parse(text);
                        if (!response.ok) {
                            setSuccess(false);
                            setLoading(false);
                        } else {
                            if (data.success) {
                                setSuccess(true);
                                setLoading(false);
                                return data;
                            } else {
                                enqueueSnackbar(data.message, { variant: 'error' });
                                setSuccess(false);
                                setLoading(false);
                            }
                        }
                    })
                })
                .catch(() => {
                    setSuccess(false);
                    setLoading(false);
                })
        } else {
            setSubmitted(false);
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            {
                loading ?
                    <FingotiLoading />
                    :
                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        {
                            submitted ?
                                success ?
                                    <FingotiFullscreenStatus
                                        success
                                        tagline="Password reset sent"
                                        message="We have sent you an email with a link to reset your password"
                                    />
                                    :
                                    <FingotiFullscreenStatus
                                        tagline="Password reset request failed"
                                        message="Looks like we've run into some issues, please contact support if the issue persists"
                                    />
                                :
                                <Grid item xs={12} lg={3}>
                                    <Typography className={classes.title} variant="h5">
                                        <RotateLeftIcon className={classes.iconSpacing} />
                                        Password Reset
                                    </Typography>
                                    <Divider className={classes.divider} />
                                    <FormControl fullWidth variant="outlined" className={classes.email}>
                                        <TextField
                                            required
                                            id='email'
                                            name='email'
                                            value={email}
                                            variant='outlined'
                                            label='email address'
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={(e) => validate.checkAgainstRegex(e)}
                                            error={Boolean(errors['email'])}
                                            helperText={Boolean(errors['email']) ? errors['email'].msg : ""}
                                        />
                                    </FormControl>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} className={classes.alignRight}>
                                            <FingotiButton
                                                light
                                                className={classes.button}
                                                type="submit"
                                                color="primary"
                                                disabled={errors.length > 0 ? true : false}
                                                onClick={requestReset}
                                            >
                                                Send Email
                                            </FingotiButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FingotiButton
                                                light
                                                className={classes.button}
                                                onClick={() => history.goBack()}
                                            >
                                                Cancel
                                            </FingotiButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
            }
        </React.Fragment>
    )
}