import React, { useEffect, useState, useRef } from 'react';
import {
	FingotiLoading,
	FingotiButton,
	FingotiModal,
	FingotiDelete,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import AvatarGroup from '@material-ui/lab/AvatarGroup/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { apiService } from '../../services/api.service';
import { datetimeService } from '../../services/datetime.service';
import { useProfileState } from '../../context/ProfileContext';
import { cdnUrl } from '../../services/config';
import { useTheme } from '@material-ui/core';

import { OrganisationTokenAdd } from './OrganisationTokenAdd';
import { OrganisationTokenEdit } from './OrganisationTokenEdit';
import { OrganisationTokenInfo } from './OrganisationTokenInfo';

const useStyles = makeStyles((theme) => ({
	actionIcon: {
		padding: 0,
	},
	enabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.success.main,
	},
	disabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.error.main,
	},
	fadeText: {
		background:
			'-webkit-linear-gradient(left, #ffffff 0%, #404040 20%, #404040 100%)',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		background: '#FFF',
		color: theme.palette.secondary.main,
	},
	expired: {
		color: '#FF0000',
	},
}));

export const OrganisationTokens = () => {
	const theme = useTheme();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [thisLoading, setThisLoading] = useState(true);
	const [addOpen, setAddOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [infoOpen, setInfoOpen] = useState(false);
	const [editToken, setEditToken] = useState();
	const [infoToken, setInfoToken] = useState();
	const { users, roles, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		if (users && roles && !loading) {
			setThisLoading(false);
		}
	}, [users, roles, loading]);

	const getTokens = async (query) => {
		return apiService
			.getData(URLGenerator(query, '/organisation/tokens'))
			.then((data) => {
				return {
					data: data.tokens,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch(() => {
				enqueueSnackbar('Error getting tokens', { variant: 'error' });
			});
	};

	const deleteToken = (id) => {
		apiService
			.deleteData(`/organisation/tokens/${id}`)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const copyToken = async (key) => {
		try {
			await navigator.clipboard.writeText(key);
			enqueueSnackbar('Token successfully copied to clipboard', {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar('Failed to copy token to clipboard', {
				variant: 'error',
			});
		}
	};

	const handleNewToken = () => {
		setAddOpen(false);
		tableRef.current.onQueryChange();
	};

	const handleEditToken = () => {
		setEditOpen(false);
		tableRef.current.onQueryChange();
	};

	const handleInfoOpen = (token) => {
		setInfoToken(token);
		setInfoOpen(true);
	};

	const handleEditOpen = (token) => {
		setEditToken(token);
		setEditOpen(true);
	};

	const enabledRenderer = (rd) => {
		return rd.disabled ? (
			<BlockOutlinedIcon className={classes.disabledIcon} />
		) : (
			<CheckCircleOutlineIcon className={classes.enabledIcon} />
		);
	};

	const expiryRenderer = (rd) => {
		let expDate = new Date(rd.expiry);

		return rd.expiry === null ? (
			'Never'
		) : expDate > new Date() ? (
			datetimeService.formatDateTimeNoSeconds(new Date(rd.expiry))
		) : (
			<span className={classes.expired}>
				{datetimeService.formatDateTimeNoSeconds(new Date(rd.expiry))}
			</span>
		);
	};

	const roleRenderer = (rd) => {
		return roles.find((r) => r.id === rd.roleId).roleName;
	};

	const assignedUsersRenderer = (rd) => {
		if (rd.assignedUsers.length > 0) {
			return (
				<AvatarGroup className={classes.avatarGroup}>
					{rd.assignedUsers.map((au) => {
						let user = users.find((u) => u.id === au);

						return (
							<Tooltip title={`${user.forename} ${user.surname}`}>
								<Avatar
									alt={`${user.forename} ${user.surname}`}
									className={classes.avatar}
									size='small'
									src={`${cdnUrl}/images/avatars/${au}`}>
									{`${user.forename.charAt(0)}${user.surname.charAt(0)}`}
								</Avatar>
							</Tooltip>
						);
					})}
				</AvatarGroup>
			);
		} else {
			return 'None';
		}
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton light color='primary' onClick={() => setAddOpen(true)}>
					new token
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'Organisation', link: '/organisation' },
		{ text: 'Tokens', link: '' },
	];

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<React.Fragment>
			<FingotiModal title='Add Token' open={addOpen} setOpen={setAddOpen}>
				<OrganisationTokenAdd
					handleNewToken={handleNewToken}
					setOpen={setAddOpen}
				/>
			</FingotiModal>
			<FingotiModal title='Edit Token' open={editOpen} setOpen={setEditOpen}>
				<OrganisationTokenEdit
					handleEditToken={handleEditToken}
					token={editToken}
					setOpen={setEditOpen}
				/>
			</FingotiModal>
			<FingotiModal
				title='Token Information'
				open={infoOpen}
				setOpen={setInfoOpen}>
				<OrganisationTokenInfo setOpen={setInfoOpen} token={infoToken} />
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				actionButtons={actionButtons()}
				sectionIcon={PersonIcon}
			/>
			<FingotiMarkdown path='/documentation/account/organisation/tokens.md' />
			<FingotiTable
				data={getTokens}
				tableRef={tableRef}
				columns={[
					{ title: 'id', field: 'id', hidden: true },
					{ title: 'name', field: 'tokenName' },
					{
						title: 'enabled',
						align: 'center',
						field: 'disabled',
						filtering: false,
						render: enabledRenderer,
					},
					{
						title: 'assigned users',
						filtering: false,
						cellStyle: { padding: theme.spacing(1) },
						render: assignedUsersRenderer,
					},
					{ title: 'role', filtering: false, render: roleRenderer },
					{
						title: 'key',
						field: 'tokenKey',
						filtering: false,
						render: (rd) => rd.tokenKey.split('.').pop(),
						cellStyle: {
							background:
								'-webkit-linear-gradient(left, #ffffff 0%, #404040 20%, #404040 100%)',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
						},
					},
					{
						title: 'expiry',
						field: 'expiry',
						filtering: false,
						render: expiryRenderer,
					},
				]}
				actions={[
					(rowData) => ({
						icon: FileCopyOutlinedIcon,
						tooltip: 'copy token',
						onClick: () => copyToken(rowData.tokenKey),
					}),
					(rowData) => ({
						icon: InfoOutlinedIcon,
						tooltip: 'token info',
						onClick: () => handleInfoOpen(rowData),
					}),
					(rowData) => ({
						icon: EditOutlinedIcon,
						tooltip: 'edit token',
						onClick: () => handleEditOpen(rowData),
					}),
					(rowData) => ({
						icon: () => (
							<FingotiDelete
								tooltipText='delete token'
                                className={classes.actionIcon}
								onClick={() => deleteToken(rowData.id)}
							/>
						),
					}),
				]}
			/>
		</React.Fragment>
	);
};
