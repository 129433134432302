import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiLoading, FingotiModal, PlanCard } from '@fingoti/components';

import Grid from '@material-ui/core/Grid';

import { apiService } from '../../../services/api.service';
import { StartSubscriptionDetails } from './StartSubscriptionDetails';
import { useProfileState } from '../../../context/ProfileContext';

export const StartSubscription = ({ tenants, billingInfo, onSuccess }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { organisation, loading } = useProfileState();
    const [thisLoading, setThisLoading] = useState(true);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(organisation && !loading) {
            getPlans();
        }
    }, [organisation, loading])

    const getPlans = () => {
        apiService.getData('/billing/plans')
            .then(result => {
                console.log("subscriptions", result);
                let filteredPlans = result.plans.filter(p => p.type === (organisation.isBusiness ? 1 : 0));
                setPlans(filteredPlans);
                setThisLoading(false);
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: "error" })
                setThisLoading(false);
            })
    }

    const handleSubscribe = (sub) => {
        setSelectedPlan(sub.id);
        setOpen(true);
    }

    if (thisLoading) {
        return <FingotiLoading />
    } else {
        return (
            <>
                <FingotiModal title="Setup Subscription" open={open} setOpen={setOpen}>
                    <StartSubscriptionDetails tenants={tenants} billingInfo={billingInfo} plan={selectedPlan} onSuccess={onSuccess} setOpen={setOpen} />
                </FingotiModal>
                {
                    plans.length > 0 ?
                        <Grid container spacing={2}>
                            {
                                plans.map((plan, i) => {

                                    let isTrial = plan.trial.enabled && !billingInfo.trialUsed ? true : false 

                                    return (
                                        <Grid key={`${i}`} item md={3} sm={12}>
                                            <PlanCard 
                                                isTrial={isTrial}
                                                plan={plan} 
                                                handleSubscribe={handleSubscribe}
                                                showLearnMore={false} 
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        :
                        'Error loading subscriptions'
                }
            </>
        )
    }

}