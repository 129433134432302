export const datetimeService = {
    formatTime,
    formatTimeNoSeconds,
    formatDate,
    formatDateTime,
    formatDateTimeNoSeconds,
    formatUptime
}

function formatTime(myDate) {
    const myDateString = ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + ('0' + (myDate.getSeconds())).slice(-2);

    return myDateString;
}

function formatTimeNoSeconds(myDate) {
    const myDateString = ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2);

    return myDateString;
}

function formatDate(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear();

    return myDateString;
}

function formatDateTime(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear() + " " + ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + ('0' + (myDate.getSeconds())).slice(-2) + '.' + myDate.getMilliseconds();

    return myDateString;
}

function formatDateTimeNoSeconds(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear() + " " + ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2);

    return myDateString;
}

function formatUptime(seconds) {
    var day, hour, minute, result;
    seconds = Math.floor(seconds);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;

    result = seconds + "s"

    if (minute > 0 || hour > 0) {
        result = minute + "m " + result
    }

    if (hour > 0 || day > 0) {
        result = hour + "h " + result
    }

    if (day > 0) {
        result = day + "d " + result
    }


    return result;
}