import React, { useState, useCallback, useRef, useEffect } from "react";
import { FingotiButton, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import AvatarEditor from 'react-avatar-editor'

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from "@material-ui/core/Slider";

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import { apiService } from '../../services/api.service';

const useStyles = makeStyles((theme) => ({

    placeholder: {
        border: '2px dashed' + theme.palette.greyThree.main,
        minHeight: '10rem',
        width: '100%',
        color: theme.palette.greyThree.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cropWindow: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    hide: {
        display: 'none !important',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    filename: {
        marginTop: theme.spacing(1),
    },
    crop: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: theme.spacing(2),
    }
}))


export const ImageCrop = ({ organisation, onSuccess, onError, setOpen }) => {

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const previewCanvasRef = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const [crop, setCrop] = useState({ unit: "%", aspect: 16 / 16 });
    const [zoom, setZoom] = useState(1);
    const [upImg, setUpImg] = useState(undefined);
    const [filename, setFilename] = useState('');
    const [placeholderVis, setPlaceholderVis] = useState(true);
    const [completedCrop, setCompletedCrop] = useState(null);

    var width = (window.innerWidth > 0) ? window.innerWidth : Screen.width;
    var height = (window.innerHeight > 0) ? window.innerHeight : Screen.height;

    var size = (width > height) ? height : width
    var sizeScale = isMobile ? 75 : 30;
    var scaledSize = (size / 100) * sizeScale;

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            setPlaceholderVis(false);
            setFilename(e.target.files[0].name);
            reader.addEventListener("load", () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const uploadImage = (canvas) => {
        setSubmitted(true);
        let croppedImage = canvas.getImageScaledToCanvas();
        croppedImage.toBlob((blob) => {
            var formData = new FormData();
            var path = organisation ? "organisation" : "user"
            formData.append("image", blob, "image.png");
            for (var value of formData.values()) {
                console.log(value);
            }
            apiService.postFormData(`/${path}/image`, formData)
                .then(() => {
                    onSuccess();
                })
                .catch(() => {
                    onError();
                })
        }, "image/png", 1)
    }

    return (
        <>
            <FingotiModalContent>
                <Box marginBottom={placeholderVis ? 0 : 2} display='flex' alignItems='center' flexDirection='column'>
                    <input id="file-upload" type="file" accept="image/*" hidden onChange={onSelectFile} />
                    <label for="file-upload" className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-disableElevation">
                        browse for image
                    </label>
                    <span className={classes.filename}>
                        {filename}
                    </span>
                </Box>
                <Box display='flex' height='100%' flexDirection='column' alignItems='center'>
                    {
                        !placeholderVis &&
                            <>
                                <AvatarEditor
                                    ref={previewCanvasRef}
                                    image={upImg}
                                    width={scaledSize}
                                    height={scaledSize}
                                    border={0}
                                    borderRadius={scaledSize}
                                    color={[255, 255, 255, 0.8]} // RGBA
                                    scale={zoom}
                                    rotate={0}
                                    className={classes.crop}
                                />
                                <Box width='80%' marginTop={2}>
                                    <Grid container>
                                        <Grid item xs={2} md={4}></Grid>
                                        <Grid item xs={8} md={4}>
                                            <Slider
                                                value={zoom}
                                                onChange={(e, v) => setZoom(v)}
                                                max={4}
                                                min={1}
                                                step={0.1}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                    }
                </Box>
            </FingotiModalContent>

            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        color="primary"
                        onClick={() => uploadImage(previewCanvasRef.current)}
                        loading={submitted}
                        disabled={upImg === undefined}
                        className={classes.button}
                    >
                        upload Image
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    );
}
