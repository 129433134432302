import React, { useState, useEffect } from 'react';
import { FingotiFullscreenStatus, FingotiLoading } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { UserRegister } from './UserRegister';
import { OrganisationRegister } from './OrganisationRegister';
import { Review } from './Review';

import { apiService } from '../../services/api.service';
import { useProfileState } from '../../context/ProfileContext';
import { Overview } from './Overview';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    boxPadding: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    iconSpacing: {
        marginRight: theme.spacing(2),
        fontSize: '2rem',
    },
    noBottomBorder: {
        border: 'none',
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: '48%',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '24%',
        color: theme.palette.greyTwo.main,
    },
    headerCellAlign: {
        width: '50% !important',
    },
    address: {
        whiteSpace: 'pre',
        border: 'none',
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: '48%',
    },
    messageRoot: {
        height: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    message: {
        height: '40%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

}))

export const Register = () => {

    const classes = useStyles();
    const { loading, loggedIn, user, organisation } = useProfileState();
    const [currentStep, setCurrentStep] = useState(-1);
    const [submittedInfo, setSubmittedInfo] = useState({});
    const [inviteToken, setInviteToken] = useState('');
    const [inviteInfo, setInviteInfo] = useState({});
    const [currentUser, setCurrentUser] = useState(undefined);
    const [partner, setPartner] = useState(undefined);
    const [thisLoading, setThisLoading] = useState(true);
    const [inviteError, setInviteError] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (!loading) {
            if (urlParams.has('token')) {
                getInvite();
            } else {
                getLoggedInUser();
            }
        }
    }, [loading])

    const getInvite = () => {
        let token = urlParams.get('token')
        setInviteToken(token);
        apiService.getData('/user/invite?token=' + token, false)
            .then(result => {
                console.log("Invite got");
                console.log(result);
                let userInfo = { forename: result.forename, surname: result.surname, email: result.email }
                setInviteInfo(userInfo);
                setThisLoading(false);
            })
            .catch(error => {
                console.log("error getting invite");
                setInviteError(true);
                setThisLoading(false);
            })
    }

    const getLoggedInUser = () => {
        if (loggedIn) {
            console.log("user logged in - register");
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("tenant") && organisation.isPartner) {
                console.log("user is member of partner org");
                setCurrentStep(1);
                setCurrentUser(user.id);
                setPartner(organisation.id);
                setThisLoading(false);
            } else {
                //Someone is logged in - make a new org
                setCurrentStep(1);
                setCurrentUser(user.id);
                setThisLoading(false);
            }
        } else {
            setCurrentStep(-1);
            setThisLoading(false);
        }
    };

    const handleNextStep = (data) => {
        setSubmittedInfo({ ...submittedInfo, [currentStep]: data })

        if (inviteToken !== '' && currentStep === 0) {
            setCurrentStep(2)
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    const checkNotUserHandle = (orgHandle) => {
        if (submittedInfo[0] !== undefined) {
            if (submittedInfo[0].userHandle === orgHandle) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const getCurrentStep = () => {
        switch (currentStep) {
            case -1:
                return (
                    <Box className={classes.boxPadding}>
                        <Overview 
                            classes={classes}
                            handleNextStep={handleNextStep}
                        />
                    </Box>
                )
            case 0:
                return (
                    <Box className={classes.boxPadding}>
                        <UserRegister
                            classes={classes}
                            submitStep={handleNextStep}
                            inviteInfo={inviteInfo}
                        />
                    </Box>
                )
            case 1:
                return (
                    <Box className={classes.boxPadding}>
                        <OrganisationRegister
                            classes={classes}
                            partner={partner}
                            submitStep={handleNextStep}
                            checkNotUserHandle={checkNotUserHandle}
                        />
                    </Box>
                )
            case 2:
                return (
                    <Box className={classes.boxPadding}>
                        <Review
                            classes={classes}
                            currentUser={currentUser}
                            partner={partner}
                            submittedInfo={submittedInfo}
                            inviteToken={inviteToken}
                        />
                    </Box>
                )
        }
    }

    return (
        <React.Fragment>
            {
                thisLoading ?
                    inviteError ?
                        <FingotiFullscreenStatus
                            message="Invite no longer valid"
                            tagline="This invite is no longer valid, this could be because it has already been used or has expired"
                        />
                        :
                        <FingotiLoading />
                    :
                    getCurrentStep()
            }
        </React.Fragment>
    );
}