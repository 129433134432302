import React from 'react';
import { FingotiButton, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import SvgIcon from '@material-ui/core/SvgIcon';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { datetimeService } from '../../services/datetime.service';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    noBottomBorder: {
        border: 'none',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '24%',
        color: theme.palette.greyTwo.main,
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    client: {
        display: 'flex',
        alignItems: 'center',
    },
    clientIcon: {
        marginRight: theme.spacing(2),
        fill: theme.palette.greyTwo.main,
    },

}))

const WindowsIcon = (props) => {
    return (
        <SvgIcon {...props} >
            <path d="M3,12V6.75L9,5.43V11.91L3,12M20,3V11.75L10,11.9V5.21L20,3M3,13L9,13.09V19.9L3,18.75V13M20,13.25V22L10,20.09V13.1L20,13.25Z" />
        </SvgIcon>
    );
}

export const UserSessionInfo = ({ setOpen, session }) => {

    const classes = useStyles();

    let icon = undefined;

    if (session.platform.includes("Windows")) {
        icon = <WindowsIcon className={classes.clientIcon} />
    }

    if (session.platform.includes("Android")) {
        icon = <AndroidOutlinedIcon className={classes.clientIcon} />;
    }

    if (session.platform.includes("iPhone") || session.platform.includes("iPad") || session.platform.includes("Mac")) {
        icon = <AppleIcon className={classes.clientIcon} />;
    }

    if (session.platform.includes("Unknown")) {
        icon = <HelpOutlineOutlinedIcon className={classes.clientIcon} />;
    }

    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>platform</TableCell>
                                    <TableCell className={clsx(classes.noBottomBorder, classes.client)}>
                                        {icon} {session.platform}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>client</TableCell>
                                    <TableCell className={classes.noBottomBorder}>{session.client}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>location</TableCell>
                                    <TableCell className={classes.noBottomBorder}>{session.location}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>ip address</TableCell>
                                    <TableCell className={classes.noBottomBorder}>{session.ipAddress}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>started at</TableCell>
                                    <TableCell className={classes.noBottomBorder}>{datetimeService.formatDateTimeNoSeconds(new Date(session.loginTime))}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>user agent</TableCell>
                                    <TableCell style={{ wordWrap: 'break-word' }} className={classes.noBottomBorder}>{session.userAgent}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </FingotiModalContent>

            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    );

}