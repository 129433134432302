import React, { useEffect, useState, useRef } from 'react';
import {
	FingotiLoading,
	FingotiButton,
	FingotiModal,
	FingotiDelete,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonIcon from '@material-ui/icons/Person';

import { apiService } from '../../services/api.service';
import { datetimeService } from '../../services/datetime.service';
import { useProfileState } from '../../context/ProfileContext';

import { UserTokenAdd } from './UserTokenAdd';
import { UserTokenEdit } from './UserTokenEdit';
import { UserTokenInfo } from './UserTokenInfo';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(6),
		width: '100%',
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	client: {
		display: 'flex',
		alignItems: 'center',
	},
	clientIcon: {
		marginRight: theme.spacing(2),
		fill: theme.palette.greyTwo.main,
	},
	currentSession: {
		background: theme.palette.primary.lighter,
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
	},
	iconButton: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		cursor: 'default',
	},
	enabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.success.main,
	},
	disabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.error.main,
	},
	fadeText: {
		background:
			'-webkit-linear-gradient(left, #ffffff 0%, #404040 20%, #404040 100%)',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	organisationName: {
		padding: '9px',
	},
	organisationWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	noItemsRow: {
		textAlign: 'center',
		height: theme.spacing(32),
		borderBottom: 'none',
	},
	expired: {
		color: '#FF0000',
	},
}));

export const UserTokens = () => {
	const theme = useTheme();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [thisLoading, setThisLoading] = useState(true);
	const [addOpen, setAddOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [infoOpen, setInfoOpen] = useState(false);
	const [editToken, setEditToken] = useState();
	const [infoToken, setInfoToken] = useState();
	const [tokens, setTokens] = useState([]);
	const { organisations, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		if (organisations && !loading) {
			setThisLoading(false);
		}
	}, [organisations, loading]);

	const getTokens = async (query) => {
		return apiService
			.getData(URLGenerator(query, '/user/tokens'))
			.then((data) => {
				data.tokens.sort((a, b) => a.tokenName.localeCompare(b.tokenName));
				return {
					data: data.tokens,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const deleteToken = (id) => {
		apiService
			.deleteData(`/user/tokens/${id}`)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const copyToken = async (key) => {
		try {
			await navigator.clipboard.writeText(key);
			enqueueSnackbar('Token successfully copied to clipboard', {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar('Failed to copy token to clipboard', {
				variant: 'error',
			});
		}
	};

	const organisationNameRenderer = (rd) => {
		return (
			<div className={classes.organisationWrapper}>
				<Avatar
					className={classes.avatar}
					size='small'
					src={`https://cdn.fingoti.com/images/avatars/${rd.id}`}>
					<AccountBalanceOutlinedIcon />
				</Avatar>
				{rd.organisationName}
			</div>
		);
	};

	const enabledRenderer = (rd) => {
		return rd.disabled ? (
			<BlockOutlinedIcon className={classes.disabledIcon} />
		) : (
			<CheckCircleOutlineIcon className={classes.enabledIcon} />
		);
	};

	const expiryRenderer = (rd) => {
		let expDate = new Date(rd.expiry);

		return rd.expiry === null ? (
			'Never'
		) : expDate > new Date() ? (
			datetimeService.formatDateTimeNoSeconds(new Date(rd.expiry))
		) : (
			<span className={classes.expired}>
				{datetimeService.formatDateTimeNoSeconds(new Date(rd.expiry))}
			</span>
		);
	};

	const handleNewToken = () => {
		setAddOpen(false);
		tableRef.current.onQueryChange();
	};

	const handleEditToken = () => {
		setEditOpen(false);
		tableRef.current.onQueryChange();
	};

	const handleInfoOpen = (token) => {
		setInfoToken(token);
		setInfoOpen(true);
	};

	const handleEditOpen = (token) => {
		setEditToken(token);
		setEditOpen(true);
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton light color='primary' onClick={() => setAddOpen(true)}>
					new token
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'User', link: '/user' },
		{ text: 'Tokens', link: '' },
	];

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<React.Fragment>
			<FingotiModal title='Add Token' open={addOpen} setOpen={setAddOpen}>
				<UserTokenAdd handleNewToken={handleNewToken} setOpen={setAddOpen} />
			</FingotiModal>
			<FingotiModal title='Edit Token' open={editOpen} setOpen={setEditOpen}>
				<UserTokenEdit
					handleEditToken={handleEditToken}
					token={editToken}
					setOpen={setEditOpen}
				/>
			</FingotiModal>
			<FingotiModal
				title='Token Information'
				open={infoOpen}
				setOpen={setInfoOpen}>
				<UserTokenInfo setOpen={setInfoOpen} token={infoToken} />
			</FingotiModal>
			<FingotiHeader
				breadcrumbs={breadcrumbs}
				actionButtons={actionButtons()}
				sectionIcon={PersonIcon}
			/>
			<FingotiMarkdown path='/documentation/account/user/tokens.md' />
			<FingotiTable
				data={getTokens}
				tableRef={tableRef}
				columns={[
					{ title: 'id', field: 'id', hidden: true },
					{
						title: 'organisation',
						field: 'organisationName',
						cellStyle: { padding: theme.spacing(1) },
						render: organisationNameRenderer,
					},
					{
						title: 'enabled',
						field: 'disabled',
						filtering: false,
						align: 'center',
						cellStyle: { width: "8%" },
						render: enabledRenderer,
					},
					{ title: 'name', field: 'tokenName' },
					{
						title: 'key',
						field: 'tokenKey',
						filtering: false,
						render: (rd) => rd.tokenKey.split('.').pop(),
						cellStyle: {
							background:
								'-webkit-linear-gradient(left, #ffffff 0%, #404040 20%, #404040 100%)',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
						},
					},
					{
						title: 'expiry',
						field: 'expiry',
						filtering: false,
						render: expiryRenderer,
					},
				]}
				actions={[
					(rowData) => ({
						icon: FileCopyOutlinedIcon,
						tooltip: 'copy token',
						onClick: () => copyToken(rowData.tokenKey),
					}),
					(rowData) => ({
						icon: InfoOutlinedIcon,
						tooltip: 'token info',
						onClick: () => handleInfoOpen(rowData),
					}),
					(rowData) => ({
						icon: EditOutlinedIcon,
						tooltip: 'edit token',
						onClick: () => handleEditOpen(rowData),
					}),
					(rowData) => ({
						icon: () => (
							<FingotiDelete
								tooltipText='delete token'
								className={classes.actionIcon}
								onClick={() => deleteToken(rowData.id)}
							/>
						),
					}),
				]}
			/>
		</React.Fragment>
	);
};
