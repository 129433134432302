import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FingotiLoading } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { FingotiFullscreenStatus } from '@fingoti/components';

import { apiService } from '../../services/api.service';
import { useProfileState } from '../../context/ProfileContext';
import { ProfileData } from '../../context/ProfileData';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '90vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	message: {
		height: '40%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export const InviteConfirm = () => {
	const classes = useStyles();
	const history = useHistory();
	const [thisLoading, setThisLoading] = useState(true);
	const [accept, setAccept] = useState(undefined);
	const [success, setSuccess] = useState(undefined);
	const { loading, loggedIn } = useProfileState();
	const profileData = ProfileData();

	useEffect(() => {
		if (!loading) {
			if (!success) {
				getUrlParams();
			} else {
                history.push('/organisation');
			}
		}
	}, [loading]);

	const getUrlParams = async () => {
		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('token') && urlParams.has('accept')) {
			let token = urlParams.get('token');
			let accept = urlParams.get('accept') === 'true';
			if (typeof accept === 'boolean') {
				isRegisteredUser(token)
					.then((isRegistered) => {
						if (isRegistered) {
							setAccept(accept);
							sendInviteResponse(token, accept);
						} else {
							//Redirect user to register if accept true, else decline invite
							if (accept) {
								history.push('/register?token=' + token);
							} else {
								sendInviteResponse(token, accept);
							}
						}
					})
					.catch(() => {
						setThisLoading(false);
						setSuccess(false);
					});
			} else {
				setThisLoading(false);
				setSuccess(false);
				//accept was invalid
			}
		} else {
			setThisLoading(false);
			setSuccess(false);
			//URL had no params
		}
	};

	const isRegisteredUser = async (token) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData('/user?token=' + token, false)
				.then((result) => {
					if (result.dateRegistered) {
						return resolve(true);
					} else {
						return resolve(false);
					}
				})
				.catch(() => {
					return reject(false);
				});
		});
	};

	const sendInviteResponse = (token, accept) => {
		let body = { Accept: accept };
		apiService
			.postData('/user/invite?token=' + token, body, false)
			.then(() => {
				if (loggedIn) {
					profileData.getUserOrganisations();
				}
				setSuccess(true);
				setThisLoading(false);
			})
			.catch(() => {
				console.log('catching error in accept');
				setSuccess(false);
				setThisLoading(false);
			});
	};

	return thisLoading ? (
		<FingotiLoading />
	) : (
		<div className={classes.root}>
			{success ? (
				accept ? (
					<FingotiFullscreenStatus
						success
						tagline='Invite Accepted'
						message='You have now been added as a user of the Organisation'
					/>
				) : (
					<FingotiFullscreenStatus
						tagline='Invite Declined'
						message='You have chosen not to be added as a user of the Organisation'
					/>
				)
			) : (
				<FingotiFullscreenStatus
					tagline='Invite no longer valid'
					message='This invite is no longer valid, this could be because it has already been used or has expired'
				/>
			)}
		</div>
	);
};
