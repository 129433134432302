import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiLoading,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';

import { useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { ProfileData } from '../../context/ProfileData';
import { useProfileState } from '../../context/ProfileContext';
import { authService } from '../../services/auth.service';
import { apiService } from '../../services/api.service';
import { cookieSettings } from '../../services/config';
import { useRoleCheck } from '../Utils/RoleCheck';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(6),
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	currentOrganisation: {
		background: theme.palette.primary.lighter,
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	organisationName: {
		padding: theme.spacing(1),
	},
	organisationWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	enabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.success.main,
	},
	disabledIcon: {
		verticalAlign: 'middle',
		fill: theme.palette.error.main,
	},
	noItemsRow: {
		textAlign: 'center',
		height: theme.spacing(32),
		borderBottom: 'none',
	},
	noPerm: {
		textAlign: 'center',
		padding: theme.spacing(6),
	},
}));

export const OrganisationApplications = () => {
	const theme = useTheme();
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [cookies, setCookie] = useCookies();
	const profileData = ProfileData();
	const { roleCheck } = useRoleCheck();
	const [thisLoading, setThisLoading] = useState(true);
	const { organisation, organisations, roles, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		setThisLoading(true);
		if (organisation && roles && !loading) {
			setThisLoading(false);
		}
	}, [organisation, roles, loading]);

	const getApplications = async (query) => {
		return apiService
			.getData(URLGenerator(query, '/organisation/applications'))
			.then((data) => {
				data.applications.sort((a, b) =>
					a.organisationName.localeCompare(b.name)
				);
				return {
					data: data.applications,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const enabledRenderer = (rd) => {
		return rd.disabled ? (
			<BlockOutlinedIcon className={classes.disabledIcon} />
		) : (
			<CheckCircleOutlineIcon className={classes.enabledIcon} />
		);
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					color='primary'
					// disabled={!organisation.allowApplications}
					disabled={true}
					onClick={() => history.push('/register?tenant=true')}>
					new application
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'Organisation', link: '/organisation' },
		{ text: 'Applications', link: '' },
	];

	return (
		<React.Fragment>
			{thisLoading ? (
				<FingotiLoading />
			) : (
				<React.Fragment>
					<FingotiHeader
						breadcrumbs={breadcrumbs}
						actionButtons={actionButtons()}
						sectionIcon={AccountBalanceOutlinedIcon}
					/>
					<FingotiMarkdown path='/documentation/account/organisation/applications.md' />
					{!roleCheck('partner', 1) ? (
						<FingotiTable
							data={getApplications}
							tableRef={tableRef}
							columns={[
								{ 
									title: 'id', 
									field: 'id' ,
									filtering: false,
								},
								{
									title: 'application name',
									field: 'name',
									filtering: false,
								},
								{
									title: 'domain',
									field: 'domain',
									filtering: false,
								},
								{
									title: 'enabled',
									render: enabledRenderer,
								},
							]}
							actions={[]}
						/>
					) : (
						<Typography className={classes.noPerm}>
							You do not have permission to view this page
						</Typography>
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
