import { useState, useEffect } from 'react';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModal,
	FingotiSwitchButton,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { ProfileData } from '../../context/ProfileData';
import {
	useProfileDispatch,
	useProfileState,
} from '../../context/ProfileContext';

import { UserSecurityMFASetup } from './UserSecurityMFASetup';
import { UserSecurityMFAPasswordConfirm } from './UserSecurityMFAPasswordConfirm';
import { UserSecurityMFARecoveryCodes } from './UserSecurityMFARecoveryCodes';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(6),
	},
	content: {
		marginTop: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		marginLeft: theme.spacing(2),
	},
	viewButton: {
		marginTop: theme.spacing(3),
	},
}));

export const UserSecurityMFA = ({}) => {
	const classes = useStyles();
	const { getUser } = ProfileData();
	const dispatch = useProfileDispatch();
	const { user, loading } = useProfileState();
	const [setupOpen, setSetupOpen] = useState(false);
	const [disableOpen, setDisableOpen] = useState(false);
	const [recoveryCodesOpen, setRecoveryCodesOpen] = useState(false);
	const [thisLoading, setThisLoading] = useState(true);

	useEffect(() => {
		setThisLoading(true);

		if (user && !loading) {
			setThisLoading(false);
		}
	}, [user, loading]);

	const MFAStatusChange = () => {
		if (setupOpen) {
			setSetupOpen(false);
		} else {
			setDisableOpen(false);
		}
		dispatch({ type: 'LOADING' });
		getUser().then(() => {
			dispatch({ type: 'LOADED' });
		});
	};

	const handleMFAStatusClick = (e) => {
		console.log(user.mfaEnabled);
		console.log(e);
		if (user.mfaEnabled == e) {
			if (user.mfaEnabled) {
				setDisableOpen(true);
			} else {
				setSetupOpen(true);
			}
		}
	};

	return (
		<div className={classes.root}>
			<FingotiModal title='Enable MFA' open={setupOpen} setOpen={setSetupOpen}>
				<UserSecurityMFASetup
					setOpen={setSetupOpen}
					onSetup={MFAStatusChange}
				/>
			</FingotiModal>
			<FingotiModal
				title='Disable MFA'
				open={disableOpen}
				setOpen={setDisableOpen}>
				<UserSecurityMFAPasswordConfirm
					setOpen={setDisableOpen}
					onSuccess={MFAStatusChange}
				/>
			</FingotiModal>
			<FingotiModal
				title='Recovery Codes'
				open={recoveryCodesOpen}
				setOpen={setRecoveryCodesOpen}>
				<UserSecurityMFARecoveryCodes setOpen={setRecoveryCodesOpen} />
			</FingotiModal>
			<div>
				<Typography variant='h5'>Multi-Factor Authentication</Typography>
				{thisLoading ? (
					<FingotiLoading fullHeight={false} />
				) : (
					<>
						<div className={classes.content}>
							<FingotiSwitchButton
								leftText='enabled'
								rightText='disabled'
								value={!user.mfaEnabled}
								onClick={handleMFAStatusClick}
							/>
							{user.mfaEnabled ? (
								<Typography className={classes.text}>
									MFA is currently enabled on your user account
								</Typography>
							) : (
								<Typography className={classes.text}>
									MFA is not currently enabled on your account
								</Typography>
							)}
						</div>
						{user.mfaEnabled && (
							<FingotiButton
								light
								color='primary'
								className={classes.viewButton}
								onClick={() => setRecoveryCodesOpen(true)}>
								View recovery codes
							</FingotiButton>
						)}
					</>
				)}
			</div>
		</div>
	);
};
