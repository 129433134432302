import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
	FingotiLoading,
	FingotiButton,
	FingotiModalContent,
	FingotiModalActions,
	FingotiTooltip,
} from '@fingoti/components';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useProfileState } from '../../../context/ProfileContext';
import { apiService } from '../../../services/api.service';
import { ClickAwayListener } from '@material-ui/core';
import { SubscriptionAssignment } from './SubscriptionAssignment';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	spacing: {
		marginTop: theme.spacing(4),
	},
	avatar: {
		fontSize: '0.2rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	pricing: {
		border: '1px solid ' + theme.palette.greyThree.main,
		borderRadius: theme.spacing(1) / 2,
	},
	radio: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
	},
	text: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
	},
	topSpace: {
		marginTop: theme.spacing(1),
	},
	bottomSpace: {
		marginBottom: theme.spacing(1),
	},
	tenantLabel: {
		display: 'flex',
		alignItems: 'flex-end',
		height: 40,
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		borderBottom: '1px solid ' + theme.palette.divider,
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
	disabled: {
		color: theme.palette.action.disabled,
	},
}));

export const EditSubscription = ({
	tenants,
	subscription,
	billingInfo,
	setOpen,
	onSuccess,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const { organisation, loading } = useProfileState();
	const [thisLoading, setThisLoading] = useState(true);
	const [allTenants, setAllTenants] = useState([]);
	const [validTenants, setValidTenants] = useState([]);
	const [assignedOrgs, setAssignedOrgs] = useState([]);
	const [autoRenew, setAutoRenew] = useState(false);
	const [allPlans, setAllPlans] = useState([]);
	const [plans, setPlans] = useState([]);
	const [selectedPlan, setSelectedPlan] = useState();
	const [submitted, setSubmitted] = useState(false);
	const [atPeriodEnd, setAtPeriodEnd] = useState(false);
	const [disableNow, setDisableNow] = useState(false);
	const [enableRadios, setEnableRadios] = useState(false);
	const assignmentRef = useRef(null);

	useEffect(() => {
		if (organisation && !loading) {
			let assignedTo = [],
				filteredTenants = [],
				filteredPlans = [];

			if (organisation.isPartner) {
				let vt = tenants.filter(
					(t) =>
						!t.subscriptionId &&
						t.isBusiness === Boolean(subscription.planSelected.type)
				);

				if (billingInfo.subscriptionId === subscription.subscriptionId) {
					assignedTo.push(organisation);
				} else if (!billingInfo.subscriptionId) {
					vt.push(organisation);
				}

				let tenantsAssigned = tenants.filter(
					(t) => t.subscriptionId === subscription.subscriptionId
				);

				if (tenantsAssigned.length > 0) {
					assignedTo.push(...tenantsAssigned);
				}

				tenants.forEach((tenant) => {
					if (
						!tenant.subscriptionId ||
						tenant.subscriptionId === subscription.subscriptionId
					) {
						filteredTenants.push(tenant);
					}
				});

				vt.sort((a, b) => {
					let aName = a.organisationName.toUpperCase();
					let bName = b.organisationName.toUpperCase();
					return aName < bName ? -1 : aName > bName ? 1 : 0;
				});

				setValidTenants(vt);
			} else {
				assignedTo.push(organisation);
			}

			setAssignedOrgs(assignedTo);
			setAllTenants(filteredTenants);
			setAutoRenew(!subscription.endDate);

			getPlans().then((plans) => {
				if (assignedTo.length > 0) {
					filteredPlans = plans.filter(
						(p) => p.type === (assignedTo[0].isBusiness ? 1 : 0)
					);
				} else {
					filteredPlans = plans.filter(
						(p) => p.type === subscription.planSelected.type
					);
				}

				setPlans(filteredPlans);
				setSelectedPlan(subscription.planSelected);
				setThisLoading(false);
			});
		}
	}, []);

	const getPlans = () => {
		return new Promise((resolve) => {
			apiService
				.getData('/billing/plans')
				.then((result) => {
					setAllPlans(result.plans);
					resolve(result.plans);
				})
				.catch((error) => {
					enqueueSnackbar(error, { variant: 'error' });
					setThisLoading(false);
				});
		});
	};

	const handlePlanChange = (e) => {
		if (subscription.planSelected.id === e.target.value) {
			setEnableRadios(false);
		} else {
			setEnableRadios(true);
		}

		let matchedPlan = plans.find((p) => p.id === e.target.value);
		let usedRipples = subscription.planSelected.ripples - subscription.balance;

		console.log('sub', subscription);

		if (usedRipples >= matchedPlan.ripples || subscription.status === 1) {
			setDisableNow(true);
			setAtPeriodEnd(true);
		} else {
			setDisableNow(false);
		}

		setSelectedPlan(matchedPlan);
	};

	const handleSubmit = () => {
		setSubmitted(true);
		let body = {
			organisationId: organisation.isPartner
				? assignmentRef.current().map((ao) => ao.id)
				: assignedOrgs.map((ao) => ao.id),
			autoRenew: autoRenew,
			planId:
				selectedPlan.id === subscription.planSelected.id
					? null
					: selectedPlan.id,
			atPeriodEnd: atPeriodEnd,
		};

		apiService
			.patchData(`/billing/subscriptions/${subscription.subscriptionId}`, body)
			.then((result) => {
				setSubmitted(false);
				onSuccess();
				setOpen(false);
				enqueueSnackbar(result.message, { variant: 'success' });
			})
			.catch((error) => {
				setSubmitted(false);
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	if (thisLoading) {
		return (
			<FingotiModalContent>
				<div className={classes.root}>
					<div
						style={{
							marginTop: theme.spacing(3),
							marginBottom: theme.spacing(3),
						}}>
						<FingotiLoading fullHeight={false} />
					</div>
				</div>
			</FingotiModalContent>
		);
	} else {
		return (
			<>
				<FingotiModalContent>
					<div className={classes.root}>
						<Grid container spacing={4} alignItems='center'>
							<Grid item md={8}>
								<FormControl fullWidth variant='outlined'>
									<InputLabel id='plan-label'>plan</InputLabel>
									<Select
										fullWidth
										variant='outlined'
										value={selectedPlan.id}
										labelId='plan-label'
										label='plan'
										onChange={(e) => handlePlanChange(e)}>
										{plans.map((plan, i) => {
											return (
												<MenuItem key={`plan-${i}`} value={plan.id}>
													{plan.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={4} style={{ textAlign: 'right' }}>
								<FormControl>
									<FormControlLabel
										control={
											<Switch
												checked={autoRenew}
												onChange={() => setAutoRenew(!autoRenew)}
												name='autoRenew'
												color='primary'
											/>
										}
										label='auto renew'
									/>
								</FormControl>
							</Grid>
						</Grid>
						{enableRadios && (
							<Grid
								container
								className={clsx(classes.spacing, classes.pricing)}>
								<Grid
									item
									xs={2}
									md={1}
									className={clsx(classes.radio, classes.topSpace, {
										[classes.disabled]: disableNow,
									})}>
									<Radio
										style={{ padding: 0 }}
										disabled={disableNow}
										checked={!atPeriodEnd}
										onChange={() => setAtPeriodEnd(!atPeriodEnd)}
										color='primary'
									/>
								</Grid>
								<Grid
									item
									xs={8}
									md={10}
									className={clsx(classes.text, classes.topSpace, {
										[classes.disabled]: disableNow,
									})}>
									Change plan now
									<FingotiTooltip
										title={
											disableNow
												? subscription.status === 1
													? 'Trial subscriptions can only be updated at period end'
													: 'You cannot downgrade this subscription immediately because the current useage exceeds the selected plan alllowance'
												: 'Change the subscription plan immediately, bills prorata immediately'
										}>
										<InfoOutlinedIcon
											fontSize='small'
											style={{
												paddingLeft: theme.spacing(1),
												color: theme.palette.greyOne.main,
											}}
										/>
									</FingotiTooltip>
								</Grid>
								<Grid
									item
									xs={2}
									md={1}
									className={clsx(classes.radio, classes.topSpace, {
										[classes.disabled]: disableNow,
									})}></Grid>
								<Grid
									item
									xs={2}
									md={1}
									className={clsx(classes.radio, classes.bottomSpace)}>
									<Radio
										style={{ padding: 0 }}
										checked={atPeriodEnd}
										onChange={() => setAtPeriodEnd(!atPeriodEnd)}
										color='primary'
									/>
								</Grid>
								<Grid
									item
									xs={8}
									md={10}
									className={clsx(classes.text, classes.bottomSpace)}>
									Change plan at the end of the billing period
									<FingotiTooltip title='Changes will not occur untill the end of the billing period, any price changes will be reflected on your next bill'>
										<InfoOutlinedIcon
											fontSize='small'
											style={{
												paddingLeft: theme.spacing(1),
												color: theme.palette.greyOne.main,
											}}
										/>
									</FingotiTooltip>
								</Grid>
								<Grid
									item
									xs={2}
									md={1}
									className={clsx(classes.radio, classes.topSpace)}></Grid>
							</Grid>
						)}
						{organisation.isPartner && (
							<SubscriptionAssignment
								assignedRef={assignmentRef}
								className={classes.spacing}
								validOrganisations={validTenants}
								assignedOrganisations={assignedOrgs}
							/>
						)}
						{/* {organisation.isPartner && (
							<FormControl fullWidth variant='outlined'>
								<InputLabel id='assignedTo-label'>assigned to</InputLabel>
								<Select
									fullWidth
									variant='outlined'
									value={assignedOrgs[0].id}
									labelId='assignedTo-label'
									label='assigned to'
									classes={{
										outlined: classes.select,
									}}
									onChange={(e) => handleAssigmentChange(e)}>
									<MenuItem
										key='unassigned'
										value='00000000-0000-0000-0000-000000000000'>
										<Avatar className={classes.avatar} size='small' />
										Unassigned
									</MenuItem>
									{billingInfo.subscriptionId == null ||
									billingInfo.subscriptionId === subscription.subscriptionId ? (
										<MenuItem key='organisation' value={organisation.id}>
											<Avatar
												className={classes.avatar}
												size='small'
												src={`https://cdn.fingoti.com/images/avatars/${organisation.id}`}
											/>
											{organisation.organisationName}
										</MenuItem>
									) : (
										''
									)}
									{allTenants.length > 0 && (
										<Typography className={classes.tenantLabel}>
											{organisation.organisationName}'s Tenants
										</Typography>
									)}
									{allTenants.length > 0 &&
										allTenants.map((tenant, i) => {
											if (
												!tenant.subscriptionId ||
												tenant.subscriptionId === subscription.subscriptionId
											) {
												return (
													<MenuItem key={`tenant-${i}`} value={tenant.id}>
														<Avatar
															className={classes.avatar}
															size='small'
															src={`https://cdn.fingoti.com/images/avatars/${tenant.id}`}
														/>
														{tenant.organisationName}
													</MenuItem>
												);
											}
										})}
								</Select>
							</FormControl>
						)} */}
					</div>
				</FingotiModalContent>
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							color='primary'
							className={classes.button}
							loading={submitted}
							onClick={handleSubmit}>
							Update
						</FingotiButton>
						<FingotiButton
							light
							className={classes.button}
							onClick={() => setOpen(false)}>
							CANCEL
						</FingotiButton>
					</div>
				</FingotiModalActions>
			</>
		);
	}
};
