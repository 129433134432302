import React, { useState } from 'react';
import { FingotiButton, FingotiModalActions, FingotiModalContent } from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { useProfileState } from '../../context/ProfileContext';
import { apiService } from '../../services/api.service';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    select: {
        display: 'flex',
        alignItems: 'center',
    }

}))

export const OrganisationUserEdit = ({ handleEditUser, editUser, setOpen }) => {

    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();
    const [selectedRole, setSelectedRole] = useState(editUser.roleId);
    const { roles } = useProfileState();


    const submitUserEdit = () => {
        let body = { roleId: selectedRole }
        apiService.patchData(`/organisation/users/${editUser.id}`, body)
            .then(result => {
                enqueueSnackbar(result.message, { variant: "success" });
                handleEditUser();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: "error" });
            })
    }

    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <form className={classes.form}>
                        <FormControl required variant='outlined'>
                            <InputLabel id='roleSelect'>role</InputLabel>
                            <Select
                                required
                                variant='outlined'
                                id='organisation'
                                value={selectedRole}
                                onChange={(e) => setSelectedRole(e.target.value)}
                                labelId='roleSelect'
                                label='role *'
                                classes={{
                                    outlined: classes.select
                                }}
                            >
                                {
                                    roles.map((role, i) => {
                                        return (
                                            <MenuItem key={i} value={role.id}>
                                                {role.roleName}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </form>
                </div>
            </FingotiModalContent>
            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        type="submit"
                        color="primary"
                        className={classes.button}
                        onClick={submitUserEdit}
                    >
                        save user
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    );
}