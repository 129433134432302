import React, { useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import countryCodeLookup from 'iso-countries-lookup';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FingotiButton, FingotiLoading, FingotiModalActions, FingotiModalContent } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';
import { CardSelect } from '../cards/CardSelect';
import { classicNameResolver } from 'typescript';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    root: {
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center'
    },
    tableHead: {
        color: theme.palette.primary.main,
    },
    spacing: {
        margin: theme.spacing(2) + 'px auto',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    select: {
        textAlign: 'left',
        width: '50%'
    }

}))


export const TryPayment = ({ setOpen, onDone, inv }) => {

    const classes = useStyles();
    const stripe = useStripe();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCard, setSelectedCard] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const tryPayment = async () => {
        setSubmitted(true);
        let { error, paymentIntent } = await stripe.confirmCardPayment(inv.payment_intent.client_secret, { payment_method: selectedCard });

        if (error) {
            console.error(error);
            setSubmitted(false);
            enqueueSnackbar("Payment failed", { variant: 'error' });
            onDone();
        } else {
            console.log("[payment intent]", paymentIntent);
            setSubmitted(false);
            enqueueSnackbar("Payment successful", { variant: 'success' });
            onDone();
        }
    }


    return (
        <>
            <FingotiModalContent>
                <div className={classes.root}>
                    <Typography>
                        This invoice is unpaid
                    </Typography>
                    <br />
                    <Typography>
                        Please select a payment method
                    </Typography>
                    <br />
                    <CardSelect className={clsx(classes.spacing, classes.select)} onChange={setSelectedCard} />
                </div>
            </FingotiModalContent>
            <FingotiModalActions>
                <div className={classes.buttonGroup}>
                    <FingotiButton
                        light
                        color="primary"
                        className={classes.button}
                        loading={submitted}
                        onClick={tryPayment}
                    >
                        pay invoice
                    </FingotiButton>
                    <FingotiButton
                        light
                        className={classes.button}
                        onClick={() => setOpen(false)}
                    >
                        CANCEL
                    </FingotiButton>
                </div>
            </FingotiModalActions>
        </>
    );
}