import React, { useState, useEffect } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
	card: {
		border: '1px solid ' + theme.palette.greyFour.main,
	},
	cardHeader: {
		padding: theme.spacing(1, 2),
	},
	list: {
		height: 230,
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto',
	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
	listItemPrimary: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

const not = (a, b) => {
	return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
	return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a, b) => [...a, ...not(b, a)];

export const OrganisationTokenAssignment = ({
	validUsers,
	assignedUsers = [],
	className,
	assignedRef,
}) => {
	const classes = useStyles();
	const [checked, setChecked] = useState([]);
	const [valid, setValid] = useState(validUsers);
	const [assigned, setAssigned] = useState(assignedUsers);

	const leftChecked = intersection(checked, valid);
	const rightChecked = intersection(checked, assigned);

	const getAssigned = () => assigned;

	useEffect(() => {
		assignedRef.current = getAssigned;
	});

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = (items) => intersection(checked, items).length;

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setAssigned(assigned.concat(leftChecked));
		setValid(not(valid, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setValid(valid.concat(rightChecked));
		setAssigned(not(assigned, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const customList = (title, items) => (
		<Card elevation={0} className={classes.card}>
			<CardHeader
				className={classes.cardHeader}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={
							numberOfChecked(items) === items.length && items.length !== 0
						}
						indeterminate={
							numberOfChecked(items) !== items.length &&
							numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{ 'aria-label': 'all items selected' }}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} selected`}
			/>
			<Divider />
			<List className={classes.list} dense component='div' role='list'>
				{items.map((value) => {
					const labelId = `transfer-list-all-item-${value.id}-label`;

					return (
						<ListItem
							key={value.id}
							role='listitem'
							button
							onClick={handleToggle(value)}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText
								classes={{ primary: classes.listItemPrimary }}
								id={labelId}
								primary={`${value.forename} ${value.surname}`}
								secondary={`${value.userHandle}`}
							/>
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Card>
	);

	return (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			className={className}>
			<Grid item md={5}>
				{customList('Unassigned', valid)}
			</Grid>
			<Grid item md={2}>
				<Grid container direction='column' alignItems='center'>
					<Button
						variant='outlined'
						size='small'
						className={classes.button}
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label='move selected right'>
						&gt;
					</Button>
					<Button
						variant='outlined'
						size='small'
						className={classes.button}
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label='move selected left'>
						&lt;
					</Button>
				</Grid>
			</Grid>
			<Grid item md={5}>
				{customList('Assigned', assigned)}
			</Grid>
		</Grid>
	);
};
