import React, { useEffect, useState } from 'react';
import {
	useRouteMatch,
	useHistory,
	Link as RouterLink,
	Redirect,
} from 'react-router-dom';
import { FingotiLoading, FingotiTabs } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';

import { OrganisationDetails } from './OrganisationDetails';
import { OrganisationManage } from './OrganisationManage';
import { OrganisationUsers } from './OrganisationUsers';
import { OrganisationRoles } from './OrganisationRoles';
import { OrganisationTenants } from './OrganisationTenants';
import { OrganisationAddresses } from './OrganisationAddresses';

import { useProfileState } from '../../context/ProfileContext';
import { OrganisationTokens } from './OrganisationTokens';
import { OrganisationApplications } from './OrganisationApplications';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		height: '100%',
	},
	tab: {
		width: '100%',
	},
	divider: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(6),
	},
	iconSpacing: {
		marginRight: theme.spacing(2),
		fontSize: '3rem',
	},
	tabRoot: {
		minWidth: 'unset',
	},
	tabLabelRight: {
		alignItems: 'unset',
		textAlign: 'right',
		paddingRight: theme.spacing(1),
	},
	boxPadding: {
		padding: theme.spacing(6),
		paddingTop: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
		},
	},
}));

const TabPanel = (props) => {
	const { passClass, children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && <div className={passClass}>{children}</div>}
		</div>
	);
};

const tabProps = (index) => {
	return {
		'id': `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
};

const RouterTab = (props) => <Tab component={RouterLink} {...props} />;

export const Organisation = ({ activeMenu }) => {
	const classes = useStyles();
	const match = useRouteMatch();
	const history = useHistory();
	const [value, setValue] = useState(0);
	const [thisLoading, setLoading] = useState(true);
	const { organisation, loading } = useProfileState();

	useEffect(() => {
		setValue(activeMenu);
		if (organisation && !loading) {
			setLoading(false);
		}
	}, [organisation, loading, activeMenu]);

	const handleChange = (event, newValue) => {
		let isTwoPartPath = match.path.indexOf('/', 1);
		let baseRoute = '/organisation';

		if (isTwoPartPath !== -1) {
			//Path is /foo/bar, need to get rid of the /bar
			baseRoute = match.path.slice(0, isTwoPartPath);
		}

		switch (newValue) {
			case 0:
				history.push(`${baseRoute}`);
				break;
			case 1:
				history.push(`${baseRoute}/manage`);
				break;
			case 2:
				history.push(`${baseRoute}/users`);
				break;
			case 3:
				history.push(`${baseRoute}/roles`);
				break;
			case 4:
				history.push(`${baseRoute}/addresses`);
				break;
			case 5:
				history.push(`${baseRoute}/tokens`);
				break;
			case 6:
				history.push(`${baseRoute}/tenants`);
				break;
			case 7:
				history.push(`${baseRoute}/applications`);
				break;
			default:
				console.log('not a valid value');
				break;
		}

		setValue(newValue);
	};

	return value === null || thisLoading ? (
		<FingotiLoading />
	) : (
		<div className={classes.root}>
			<FingotiTabs
				value={value}
				onChange={handleChange}
				aria-label='organisation settings tabs'>
				<RouterTab
					to={'/organisation'}
					classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
					label='Overview'
					{...tabProps(0)}
				/>
				<RouterTab
					to={'/organisation/manage'}
					classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
					label='Manage'
					{...tabProps(1)}
				/>
				<RouterTab
					to={'/organisation/users'}
					classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
					label='Users'
					{...tabProps(2)}
				/>
				<RouterTab
					to={'/organisation/roles'}
					classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
					label='Roles'
					{...tabProps(3)}
				/>
				<RouterTab
					to={'/organisation/addresses'}
					classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
					label='Addresses'
					{...tabProps(4)}
				/>
				<RouterTab
					to={'/organisation/tokens'}
					classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
					label='Tokens'
					{...tabProps(5)}
				/>
				{organisation.isPartner && (
					<RouterTab
						to={'/organisation/tenants'}
						classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
						label='Tenants'
						{...tabProps(6)}
					/>
				)}
				{organisation.allowApplications && (
					<RouterTab
						to={'/organisation/applications'}
						classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
						label='Applications'
						{...tabProps(7)}
					/>
				)}
			</FingotiTabs>
			<TabPanel
				passClass={classes.boxPadding}
				className={classes.tab}
				value={value}
				index={0}>
				<OrganisationDetails />
			</TabPanel>

			<TabPanel
				passClass={classes.boxPadding}
				className={classes.tab}
				value={value}
				index={1}>
				<OrganisationManage />
			</TabPanel>

			<TabPanel
				passClass={classes.boxPadding}
				className={classes.tab}
				value={value}
				index={2}>
				<OrganisationUsers />
			</TabPanel>

			<TabPanel
				passClass={classes.boxPadding}
				className={classes.tab}
				value={value}
				index={3}>
				<OrganisationRoles />
			</TabPanel>

			<TabPanel
				passClass={classes.boxPadding}
				className={classes.tab}
				value={value}
				index={4}>
				<OrganisationAddresses />
			</TabPanel>

			<TabPanel
				passClass={classes.boxPadding}
				className={classes.tab}
				value={value}
				index={5}>
				<OrganisationTokens />
			</TabPanel>

			<>
				{organisation.isPartner ? (
					<TabPanel
						passClass={classes.boxPadding}
						className={classes.tab}
						value={value}
						index={6}>
						<OrganisationTenants />
					</TabPanel>
				) : (
					<Redirect to='/organisation' />
				)}
			</>

			<>
				{organisation.allowApplications ? (
					<TabPanel
						passClass={classes.boxPadding}
						className={classes.tab}
						value={value}
						index={7}>
						<OrganisationApplications />
					</TabPanel>
				) : (
					<Redirect to='/organisation' />
				)}
			</>
		</div>
	);
};
