import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalContent,
	FingotiModalActions,
} from '@fingoti/components';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { apiService } from '../../../services/api.service';
import { CardSelect } from '../cards/CardSelect';
import { useProfileState } from '../../../context/ProfileContext';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	spacing: {
		marginTop: theme.spacing(4),
	},
	tableCell: {
		padding: theme.spacing(1),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
	pricing: {
		border: '1px solid ' + theme.palette.greyThree.main,
		borderRadius: theme.spacing(1) / 2,
	},
	radio: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
	},
	text: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
	},
	topSpace: {
		marginTop: theme.spacing(1),
	},
	bottomSpace: {
		marginBottom: theme.spacing(1),
	},
	avatar: {
		fontSize: '0.2rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	tenantLabel: {
		display: 'flex',
		alignItems: 'flex-end',
		height: 40,
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		borderBottom: '1px solid ' + theme.palette.divider,
	},
	pricingLabel: {
		fontSize: '9pt',
		paddingLeft: 6,
		paddingRight: 6,
		color: theme.palette.greyThree.main,
	},
	vat: {
		color: theme.palette.greyThree.main,
		marginLeft: theme.spacing(1),
	},
}));

export const StartSubscriptionDetails = ({
	tenants: tenantsIn,
	plan: planIn,
	billingInfo,
	setOpen,
	onSuccess,
}) => {
	const theme = useTheme();
	const classes = useStyles();
	const stripe = useStripe();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { organisation, loading } = useProfileState();
	const [thisLoading, setThisLoading] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [plan, setPlan] = useState(undefined);
	const [allPlans, setAllPlans] = useState([]);
	const [plans, setPlans] = useState([]);
	const [selectedCycle, setSelectedCycle] = useState(0);
	const [selectedCard, setSelectedCard] = useState('');
	const [tenants, setTenants] = useState([]);
	const [selectedTenant, setSelectedTenant] = useState(undefined);

	useEffect(() => {
		setThisLoading(true);
		if (!loading && organisation) {
			//This is just for use later on
			let selectedTenant,
				filteredTenants = [];
			let isCurrentOrg = false;

				console.log("bil i", billingInfo);

			if (organisation.isPartner) {
				tenantsIn.forEach((tenant, i) => {
					if (!tenant.subscriptionId) {
						selectedTenant = tenantsIn[i];
						filteredTenants.push(tenant);
					}
				});

				setTenants(filteredTenants);
			}

			if (!billingInfo.subscriptionId) {
				isCurrentOrg = true;
				selectedTenant = organisation;
			}

			setSelectedTenant(selectedTenant);

			console.log('tenants', tenantsIn);

			getPlans().then((plans) => {
				if (planIn) {
					let filteredPlans = plans.filter(
						(p) => p.type === (organisation.isBusiness ? 1 : 0)
					);
					let matchedPlan = filteredPlans.find((p) => p.id === planIn);
					let plan = {
						...matchedPlan,
						isTrial:
							matchedPlan.trial.enabled && !billingInfo.trialUsed
								? true
								: false,
					};
					setPlan(plan);
					setPlans(filteredPlans);
					setThisLoading(false);
				} else {
					let plan;
					let filteredPlans;
					if (selectedTenant && !isCurrentOrg) {
						filteredPlans = plans.filter(
							(p) => p.type === (selectedTenant.isBusiness ? 1 : 0)
						);
						plan = {
							...filteredPlans[0],
							isTrial:
								filteredPlans[0].trial.enabled && !selectedTenant.trialUsed
									? true
									: false,
						};
					} else {
						filteredPlans = plans.filter(
							(p) => p.type === (organisation.isBusiness ? 1 : 0)
						);
						plan = {
							...filteredPlans[0],
							isTrial:
								filteredPlans[0].trial.enabled && !billingInfo.trialUsed
									? true
									: false,
						};
					}

					setPlans(filteredPlans);
					setPlan(plan);
					setThisLoading(false);
				}
			});
		}
	}, [loading, organisation]);

	const getPlans = () => {
		return new Promise((resolve) => {
			apiService
				.getData('/billing/plans')
				.then((result) => {
					console.log('subscriptions', result);
					setAllPlans(result.plans);
					resolve(result.plans);
				})
				.catch((error) => {
					enqueueSnackbar(error, { variant: 'error' });
					setThisLoading(false);
				});
		});
	};

	const handlePlanSelect = (e) => {
		if (!selectedTenant || selectedTenant.id === organisation.id) {
			let matchedPlan = plans.find((p) => p.id === e.target.value);
			setPlan({
				...matchedPlan,
				isTrial:
					matchedPlan.trial.enabled && !billingInfo.trialUsed ? true : false,
			});
		} else {
			let matchedPlan = plans.find((p) => p.id === e.target.value);
			setPlan({
				...matchedPlan,
				isTrial:
					matchedPlan.trial.enabled && !selectedTenant.trialUsed ? true : false,
			});
		}
	};

	const handleTenantChange = (e) => {
		if (e.target.value === undefined) return;

		if (e.target.value === organisation.id) {
			let filteredPlans = allPlans.filter(
				(p) => p.type === (organisation.isBusiness ? 1 : 0)
			);
			setSelectedTenant(organisation);
			setPlan({
				...plan,
				isTrial: plan.trial.enabled && !billingInfo.trialUsed ? true : false,
			});
			setPlans(filteredPlans);
		} else {
			let matchedTenant = tenantsIn.find((t) => t.id === e.target.value);
			let filteredPlans = allPlans.filter(
				(p) => p.type === (matchedTenant.isBusiness ? 1 : 0)
			);
			setSelectedTenant(matchedTenant);
			setPlan({
				...filteredPlans[0],
				isTrial:
					filteredPlans[0].trial.enabled && !matchedTenant.trialUsed
						? true
						: false,
			});
			setPlans(filteredPlans);
		}
	};

	const handleSubscribe = () => {
		setSubmitted(true);
		createSubscription()
			.then(attemptPayment)
			.then(onComplete)
			.catch((error) => {
				console.error(error);
			});
	};

	const createSubscription = () => {
		let body = {
			organisationId: selectedTenant ? selectedTenant.id : organisation.id,
			planId: plan.id,
			paymentMethodId: selectedCard,
			priceId:
				selectedCycle === 0
					? plan.pricing.monthly.priceId
					: plan.pricing.yearly.priceId,
		};

		return new Promise((resolve, reject) => {
			apiService
				.postData('/billing/subscriptions', body)
				.then((result) => {
					console.log('sub result', result);
					return resolve(result);
				})
				.catch((error) => {
					return reject(error);
				});
		});
	};

	const attemptPayment = async (subscription) => {
		if (subscription.clientSecret) {
			let { error, paymentIntent } = await stripe.confirmCardPayment(
				subscription.clientSecret
			);

			if (error) {
				console.error(error);
				enqueueSnackbar('Payment failed', { variant: 'error' });
				history.push('/organisation/invoices');
			}

			return paymentIntent;
		}

		let paymentIntent = { status: 'trial' };
		return paymentIntent;
	};

	const onComplete = (paymentIntent) => {
		if (paymentIntent.status === 'succeeded') {
			enqueueSnackbar('Payment successful', { variant: 'success' });
		} else if (paymentIntent.status === 'trial') {
			enqueueSnackbar('Trial started', { variant: 'success' });
		}

		onSuccess();
		setSubmitted(false);
		setOpen(false);
	};

	if (thisLoading) {
		return (
			<FingotiModalContent>
				<div className={classes.root}>
					<div
						style={{
							marginTop: theme.spacing(3),
							marginBottom: theme.spacing(3),
						}}>
						<FingotiLoading fullHeight={false} />
					</div>
				</div>
			</FingotiModalContent>
		);
	} else {
		return (
			<>
				<FingotiModalContent>
					<div className={classes.root}>
						{organisation.isPartner && tenantsIn.length > 0 && (
							<FormControl fullWidth variant='outlined'>
								<InputLabel id='assignTo-label'>assign to</InputLabel>
								<Select
									fullWidth
									variant='outlined'
									value={selectedTenant.id}
									labelId='assignTo-label'
									label='assign to'
									classes={{
										outlined: classes.select,
									}}
									onChange={(e) => handleTenantChange(e)}>
									{!billingInfo.subscriptionId && (
										<MenuItem key='organisation' value={organisation.id}>
											<Avatar
												className={classes.avatar}
												size='small'
												src={`https://cdn.fingoti.com/images/avatars/${organisation.id}`}
											/>
											{organisation.organisationName}
										</MenuItem>
									)}
									{tenants.length > 0 && (
										<Typography className={classes.tenantLabel}>
											{organisation.organisationName}'s Tenants
										</Typography>
									)}
									{tenants.length > 0 &&
										tenants.map((tenant, i) => {
											if (!tenant.subscriptionId) {
												return (
													<MenuItem key={`tenant-${i}`} value={tenant.id}>
														<Avatar
															className={classes.avatar}
															size='small'
															src={`https://cdn.fingoti.com/images/avatars/${tenant.id}`}
														/>
														{tenant.organisationName}
													</MenuItem>
												);
											}
										})}
								</Select>
							</FormControl>
						)}
						<FormControl
							fullWidth
							variant='outlined'
							className={classes.spacing}>
							<InputLabel id='plan-label'>plan</InputLabel>
							<Select
								fullWidth
								variant='outlined'
								value={!plan ? (plans.length > 0 ? plans[0].id : '1') : plan.id}
								labelId='plan-label'
								label='plan'
								onChange={(e) => handlePlanSelect(e)}>
								{plans.length > 0 ? (
									plans.map((plan, i) => {
										return (
											<MenuItem key={`${i}`} value={`${plan.id}`}>
												{plan.name}
											</MenuItem>
										);
									})
								) : (
									<MenuItem key='1' value='1'>
										Error loading plans
									</MenuItem>
								)}
							</Select>
						</FormControl>
						{plan && (
							<>
								<fieldset className={clsx(classes.spacing, classes.pricing)}>
									<legend className={classes.pricingLabel}>cycle</legend>
									<Grid container>
										<Grid
											item
											xs={2}
											md={1}
											className={clsx(classes.radio, classes.topSpace)}>
											<Radio
												style={{ padding: 0 }}
												checked={selectedCycle === 0}
												onChange={() => setSelectedCycle(0)}
												color={!plan.isTrial ? 'primary' : 'secondary'}
											/>
										</Grid>
										<Grid
											item
											xs={10}
											md={11}
											className={clsx(classes.text, classes.topSpace)}>
											Monthly -{' '}
											{plan.isTrial &&
												`free for ${plan.trial.period} days then`}{' '}
											{new Intl.NumberFormat('en-GB', {
												style: 'currency',
												currency: 'GBP',
											}).format(plan.pricing.monthly.cost * 1.2)}
											/month <small className={classes.vat}>{'inc VAT'}</small>
										</Grid>
										<Grid
											item
											xs={2}
											md={1}
											className={clsx(classes.radio, classes.bottomSpace)}>
											<Radio
												style={{ padding: 0 }}
												checked={selectedCycle === 1}
												onChange={() => setSelectedCycle(1)}
												color={!plan.isTrial ? 'primary' : 'secondary'}
											/>
										</Grid>
										<Grid
											item
											xs={10}
											md={11}
											className={clsx(classes.text, classes.bottomSpace)}>
											Annually -{' '}
											{plan.isTrial &&
												`free for ${plan.trial.period} days then`}{' '}
											{new Intl.NumberFormat('en-GB', {
												style: 'currency',
												currency: 'GBP',
											}).format(plan.pricing.yearly.cost * 1.2)}
											/year (
											{new Intl.NumberFormat('en-GB', {
												style: 'currency',
												currency: 'GBP',
											}).format((plan.pricing.yearly.cost * 1.2) / 12)}
											/month) <small className={classes.vat}>{'inc VAT'}</small>
										</Grid>
									</Grid>
								</fieldset>
								<CardSelect
									onChange={setSelectedCard}
									className={classes.spacing}
								/>
							</>
						)}
					</div>
				</FingotiModalContent>
				{plan && (
					<FingotiModalActions>
						<div className={classes.buttonGroup}>
							{/* <FingotiButton
                                light
                                className={classes.button}
                                disabled
                            >
                                Avaliable soon!
                            </FingotiButton> */}
							<FingotiButton
								light
								color={plan.isTrial ? 'secondary' : 'primary'}
								className={classes.button}
								loading={submitted}
								onClick={handleSubscribe}
								disabled={loading}>
								{plan.isTrial ? 'Start Trial' : 'Start Subscription'}
							</FingotiButton>
							<FingotiButton
								light
								className={classes.button}
								onClick={() => setOpen(false)}>
								CANCEL
							</FingotiButton>
						</div>
					</FingotiModalActions>
				)}
			</>
		);
	}
};
