import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';
import { FingotiLoading, FingotiTabs } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { BillingCards } from './cards/BillingCards';
import { BillingSubscription } from './subscription/BillingSubscription';
import { BillingInvoices } from './invoices/BillingInvoices';
import { stripeKey } from '../../services/config';


const useStyles = makeStyles((theme) => ({

  root: {
      width: '100%',
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: '100%'
  },
  tab: {
      width: '100%',
  },
  divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
  },
  iconSpacing: {
      marginRight: theme.spacing(2),
      fontSize: '3rem',
  },
  tabRoot: {
      minWidth: 'unset',
  },
  tabLabelRight: {
      alignItems: 'unset',
      textAlign: 'right',
      paddingRight: theme.spacing(1),
  },
  boxPadding: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2),
      }
  },

}));

const stripePromise = loadStripe(stripeKey);

const elFonts = [
  {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Work+Sans&display=swap',
      family: 'Work Sans',
  }
];

const TabPanel = (props) => {
  const { passClass, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={passClass}>
          {children}
        </div>
      )}
    </div>
  );
}


const tabProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const RouterTab = (props) => <Tab component={RouterLink} {...props} />

export const Billing = ({ activeMenu }) => {

  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(activeMenu);
  });

  const handleChange = (event, newValue) => {

    let isTwoPartPath = match.path.indexOf('/', 1);
    let baseRoute = "/billing";

    if (isTwoPartPath !== -1) {
      //Path is /foo/bar, need to get rid of the /bar
      baseRoute = match.path.slice(0, isTwoPartPath)
    }

    switch (newValue) {
      case 0:
        history.push(`${baseRoute}`);
        break;
      case 1:
        history.push(`${baseRoute}/cards`);
        break;
      case 2:
        history.push(`${baseRoute}/invoices`);
        break;
      default:
        console.log("not a valid value");
        break;
    }

    setValue(newValue);

  };

  return (
    value === null ?
      <FingotiLoading />
      :
      <div className={classes.root}>
        <FingotiTabs
          value={value}
          onChange={handleChange}
          aria-label="billing settings tabs"
        >
          <RouterTab to={'/billing'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Subscriptions" {...tabProps(0)} />
          <RouterTab to={'/billing/cards'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Cards" {...tabProps(1)} />
          <RouterTab to={'/billing/invoices'} classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }} label="Invoices" {...tabProps(2)} />   
        </FingotiTabs>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={0}>
          <Elements stripe={stripePromise}>
            <BillingSubscription />
          </Elements>
        </TabPanel>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={1}>
          <Elements stripe={stripePromise} options={{ fonts: elFonts }}>
            <BillingCards />
          </Elements>
        </TabPanel>

        <TabPanel passClass={classes.boxPadding} className={classes.tab} value={value} index={2}>
          <Elements stripe={stripePromise} options={{ fonts: elFonts }}>
            <BillingInvoices />
          </Elements>
        </TabPanel>
      </div>
  );
}