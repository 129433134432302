import React from 'react';
import {
	FingotiButton,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	buttonGroup: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	organisationName: {
		display: 'flex',
		alignItems: 'center',
		padding: '9px',
	},
	button: {
		width: '42%',
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
}));

export const OrganisationRoleDependentUsers = ({ setOpen, dependentUsers }) => {
	const classes = useStyles();

	return (
		<>
			<FingotiModalContent>
				<div className={classes.root}>
					<Typography>
						Before deleting this role please check there are no users assigned
						to that role, we have detected the following users are still
						assinged
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHead}>user</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dependentUsers.map((user, i) => {
									return (
										<TableRow key={i}>
											<TableCell className={classes.organisationName}>
												<Avatar
													className={classes.avatar}
													size='small'
													src={`https://cdn.fingoti.com/images/avatars/${user.id}`}>
													<AccountBalanceOutlinedIcon />
												</Avatar>
												{user.forename} {user.surname}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</FingotiModalContent>
			<FingotiModalActions>
				<FingotiButton
					light
					className={classes.button}
					onClick={() => setOpen(false)}>
					Close
				</FingotiButton>
			</FingotiModalActions>
		</>
	);
};
