import React from 'react';
import { FingotiHeader, FingotiMarkdown } from '@fingoti/components';

import Grid from '@material-ui/core/Grid';

import PersonIcon from '@material-ui/icons/Person';

import { UserSecurityChangePassword } from './UserSecurityPasswordChange';
import { UserSecurityMFA } from './UserSecurityMFA';

export const UserSecurity = () => {
	const breadcrumbs = [
		{ text: 'User', link: '/user' },
		{ text: 'Security', link: '' },
	];

	return (
		<React.Fragment>
			<FingotiHeader breadcrumbs={breadcrumbs} sectionIcon={PersonIcon} />
			<FingotiMarkdown path='/documentation/account/user/security.md' />
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<UserSecurityMFA />
				</Grid>
				<Grid item xs={12}>
					<UserSecurityChangePassword />
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
