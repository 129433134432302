import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
	FingotiModal,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { authUrl } from '../../services/config';
import { handleResponse } from '../../services/response.service';
import { UserSecurityMFAPasswordConfirm } from './UserSecurityMFAPasswordConfirm';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
		textAlign: 'center',
	},
	recoveryCodes: {
		width: '50%',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	recoveryCode: {
		fontFamily: 'Courier New',
		color: theme.palette.secondary.main,
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
	button: {
		width: '32%',
	},
}));

export const UserSecurityMFARecoveryCodes = ({ setOpen }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [thisLoading, setThisLoading] = useState(true);
	const [error, setError] = useState(false);
	const [recoveryCodes, setRecoveryCodes] = useState([]);

	useEffect(() => {
		getRecoveryCodes();
	}, []);

	const getRecoveryCodes = () => {
		setThisLoading(true);
		let requestOptions = {
			method: 'GET',
			credentials: 'include',
		};

		fetch(`${authUrl}/mfa/recover`, requestOptions)
			.then(handleResponse)
			.then((result) => {
				console.log('res', result);
				setRecoveryCodes(result.codes);
				setThisLoading(false);
			})
			.catch((error) => {
				console.error('ERROR', error);
				setError(true);
				setThisLoading(false);
			});
	};

	const copyRecoveryCodes = async () => {
		try {
			await navigator.clipboard.writeText(recoveryCodes.join('\n'));
			enqueueSnackbar('Recovery codes copied to clipboard', {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar('Failed to copy recovery codes to clipboard', {
				variant: 'error',
			});
		}
	};

	const downloadRecoveryCodes = () => {
		var element = document.createElement('a');
		element.setAttribute(
			'href',
			'data:text/plain;charset=utf-8,' +
				encodeURIComponent(recoveryCodes.join('\n'))
		);
		element.setAttribute('download', 'fingoti-recovery-codes.txt');

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};

	const confirmSuccess = () => {
		getRecoveryCodes();
		setConfirmOpen(false);
	};

	return (
		<>
			<FingotiModal
				title='Regenerate Recovery Codes'
				open={confirmOpen}
				setOpen={setConfirmOpen}>
				<UserSecurityMFAPasswordConfirm
					setOpen={setConfirmOpen}
					onSuccess={confirmSuccess}
					regenerate={true}
				/>
			</FingotiModal>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading fullHeight={false} />
				) : error ? (
					<Typography>
						Looks live we've run into a problem, please try again, if the error
						persists please contact support
					</Typography>
				) : (
					<div className={classes.root}>
						<div className={classes.content}>
							<Typography>
								Each of the 16 codes shown below are recovery codes for use if
								you no longer have access to your MFA app, please keep a copy of
								these codes secure.
							</Typography>
							<Grid container className={classes.recoveryCodes}>
								{recoveryCodes.map((rc, i) => (
									<Grid item xs={12} md={6}>
										<Typography
											key={`code-${i}`}
											className={classes.recoveryCode}>
											<strong>{rc}</strong>
										</Typography>
									</Grid>
								))}
							</Grid>
							{/* <div
								className={classes.buttonGroup}
								style={{ marginLeft: 'auto', marginRight: 'auto' }}> */}
							<Grid container spacing={2}>
								<Grid item xs={12} md={4}>
									<FingotiButton
										light
										// color='primary'
										onClick={copyRecoveryCodes}>
										Copy to clipboard
									</FingotiButton>
								</Grid>
								<Grid item xs={12} md={4}>
									<FingotiButton
										light
										// color='primary'
										onClick={downloadRecoveryCodes}>
										Download as TXT
									</FingotiButton>
								</Grid>
								<Grid item xs={12} md={4}>
									<FingotiButton
										light
										color='primary'
										onClick={() => setConfirmOpen(true)}>
										Regenerate Codes
									</FingotiButton>
								</Grid>
							</Grid>
							{/* </div> */}
						</div>
					</div>
				)}
			</FingotiModalContent>

			<FingotiModalActions>
				<div className={classes.buttonGroup}>
					<FingotiButton
						light
						className={classes.button}
						onClick={() => setOpen(false)}>
						Close
					</FingotiButton>
				</div>
			</FingotiModalActions>
		</>
	);
};
