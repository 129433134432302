import React, { useState, useEffect } from 'react';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalActions,
	FingotiModalContent,
} from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import { DateTimePicker } from '@material-ui/pickers';

import { useRoleCheck } from '../Utils/RoleCheck';
import { useProfileState } from '../../context/ProfileContext';
import { apiService } from '../../services/api.service';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	formControl: {
		marginTop: theme.spacing(3),
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
	select: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		fontSize: '0.2rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	expiry: {
		width: '100%',
		marginTop: theme.spacing(3),
	},
	tenantLabel: {
		fontWeight: 'bolder',
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		borderTop: '1px solid ' + theme.palette.divider,
		borderBottom: '1px solid ' + theme.palette.divider,
	},
}));

export const UserTokenAdd = ({ handleNewToken, setOpen }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { roleCheck } = useRoleCheck();
	const [thisLoading, setThisLoading] = useState(true);
	const [name, setName] = useState('');
	const [thisOrganisation, setThisOrganisation] = useState('');
	const [tenants, setTenants] = useState([]);
	const [enableExpiry, setEnableExpiry] = useState(false);
	const [expiry, setExpiry] = useState(new Date());
	const { organisation, organisations, loading } = useProfileState();

	useEffect(() => {
		if (organisation && organisations && !loading) {
			setThisOrganisation(organisation.id);
			console.log("RC", !roleCheck("partner", 2))
			if (organisation.isPartner && !roleCheck("partner", 2)) {
				getTenants();
			} else {
				setThisLoading(false);
			}
		}
	}, [organisation, organisations, loading]);

	const createToken = (event) => {
		event.preventDefault();
		let body = {
			expiry: enableExpiry ? expiry : null,
			organisationId: thisOrganisation,
			tokenName: name,
		};
		apiService
			.postData('/user/tokens', body)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				handleNewToken();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const getTenants = (page = 1, tenants = []) => {
		return new Promise((resolve, reject) => {
			apiService
				.getData(
					`/organisation/tenants?itemsPerPage=50&pageNumber=${page}`
				)
				.then((tenantsData) => {

					tenants = [...tenants, ...tenantsData.tenants];

					if (tenantsData.count > tenants.length) {
						getTenants(page + 1, tenants)
							.then(resolve)
							.catch(reject);
					} else {
						setTenants(tenants);
						setThisLoading(false);
						resolve(true);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	return (
		<>
			<FingotiModalContent>
				{thisLoading ? (
					<FingotiLoading />
				) : (
					<div className={classes.root}>
						<form className={classes.form}>
							<FormControl variant='outlined'>
								<TextField
									required
									id='tokenName'
									name='tokenName'
									variant='outlined'
									label='token name'
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</FormControl>
							<FormControl
								required
								variant='outlined'
								className={classes.formControl}>
								<InputLabel id='organisationSelect'>organisation</InputLabel>
								<Select
									required
									variant='outlined'
									id='organisation'
									value={thisOrganisation}
									onChange={(e) => setThisOrganisation(e.target.value)}
									labelId='organisationSelect'
									label='organisation *'
									classes={{
										outlined: classes.select,
									}}>
									{organisations.map((organisation, i) => {
										return (
											<MenuItem
												key={`organisation-${i}`}
												value={organisation.id}>
												<Avatar
													className={classes.avatar}
													size='small'
													src={`https://cdn.fingoti.com/images/avatars/${organisation.id}`}
												/>
												{organisation.organisationName}
											</MenuItem>
										);
									})}
									{tenants.length > 0 ? (
										<Typography className={classes.tenantLabel}>
											{organisation.organisationName}'s Tenants
										</Typography>
									) : (
										''
									)}
									{tenants.length > 0
										? tenants.map((tenant, i) => {
												return (
													<MenuItem key={`tenant-${i}`} value={tenant.id}>
														<Avatar
															className={classes.avatar}
															size='small'
															src={`https://cdn.fingoti.com/images/avatars/${tenant.id}`}
														/>
														{tenant.organisationName}
													</MenuItem>
												);
										  })
										: ''}
								</Select>
							</FormControl>
							<FormControl
								required
								variant='outlined'
								className={classes.formControl}>
								<FormControlLabel
									control={
										<Switch
											checked={enableExpiry}
											onChange={() => setEnableExpiry(!enableExpiry)}
											name='enableExpiry'
											color='primary'
										/>
									}
									label='enable expiry?'
								/>
							</FormControl>
							<Collapse in={enableExpiry}>
								<FormControl
									required
									variant='outlined'
									className={classes.expiry}>
									<DateTimePicker
										DialogProps={{ style: { zIndex: 1400 } }}
										value={expiry}
										hideTabs
										disablePast
										inputVariant='outlined'
										onChange={setExpiry}
										label='expiry date/time'
									/>
								</FormControl>
							</Collapse>
						</form>
					</div>
				)}
			</FingotiModalContent>

			{!thisLoading && (
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							type='submit'
							color='primary'
							className={classes.button}
							onClick={createToken}>
							create token
						</FingotiButton>
						<FingotiButton
							light
							className={classes.button}
							onClick={() => setOpen(false)}>
							Cancel
						</FingotiButton>
					</div>
				</FingotiModalActions>
			)}
		</>
	);
};
