import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import {
	FingotiButton,
	FingotiLoading,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { ProfileData } from '../../context/ProfileData';
import { useProfileState } from '../../context/ProfileContext';
import { cookieSettings } from '../../services/config';
import { authService } from '../../services/auth.service';
import { apiService } from '../../services/api.service';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(6),
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	currentOrganisation: {
		background: theme.palette.primary.lighter,
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
		marginRight: theme.spacing(2),
	},
	organisationName: {
		padding: theme.spacing(1),
	},
	organisationWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export const OrganisationManage = () => {
	const theme = useTheme();
	const classes = useStyles();
	const history = useHistory();
	const profileData = ProfileData();
	const { enqueueSnackbar } = useSnackbar();
	const [cookies, setCookie, removeCookie] = useCookies();
	const [accentRow, setAccentRow] = useState(true);
	const [processedOrganisations, setProcessedOrganisations] = useState([]);
	const [thisLoading, setThisLoading] = useState(false);
	const { organisation, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		setThisLoading(true);
		if (organisation && !loading) {
			setThisLoading(false);
		}
	}, [organisation, loading]);

	const getOrganisations = (query) => {
		return apiService
			.getData(URLGenerator(query, '/user/organisations'))
			.then((data) => {
				data.organisations.sort((a, b) =>
					a.organisationName.localeCompare(b.organisationName)
				);

				return {
					data: data.organisations,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};

				// let removeIndex = data.organisations.findIndex(
				// 	(org) => org.id === organisation.id
				// );
				// if (removeIndex !== -1) {
				// 	let currentOrganisation = data.organisations[removeIndex];
				// 	delete data.organisations[removeIndex];
				// 	data.organisations.unshift(currentOrganisation);
				//     let orgs = data.organisations.filter((s) => s !== undefined);
				// 	return {
				// 		data: orgs,
				// 		page: data.pageNumber - 1,
				// 		totalCount: data.count,
				// 	};
				// } else {
				// 	return {
				// 		data: data.organisations,
				// 		page: data.pageNumber - 1,
				// 		totalCount: data.count,
				// 	};
				// }
			})
			.catch((e) => {
				console.error(e);
				enqueueSnackbar('Error getting addresses', { variant: 'error' });
			});
	};

	const switchOrganisation = (orgId) => {
		authService
			.getAppToken(orgId)
			.then(() => {
				removeCookie('Partner', cookieSettings());
				profileData.getData();
				enqueueSnackbar('Successfully switched organisation', {
					variant: 'success',
				});
			})
			.catch(() => {
				enqueueSnackbar('Failed to swtich organisation', { variant: 'error' });
			});
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					color='primary'
					onClick={() => history.push('/register')}>
					new organisation
				</FingotiButton>
			</React.Fragment>
		);
	};

	const breadcrumbs = [
		{ text: 'Organisation', link: '/organisation' },
		{ text: 'Manage', link: '' },
	];

	const organisationNameRenderer = (rd) => {
		console.log(rd);
		return (
			<div className={classes.organisationWrapper}>
				<Avatar
					className={classes.avatar}
					size='small'
					src={`https://cdn.fingoti.com/images/avatars/${rd.id}`}>
					<AccountBalanceOutlinedIcon />
				</Avatar>
				{rd.organisationName}
			</div>
		);
	};

	return (
		<React.Fragment>
			{thisLoading ? (
				<FingotiLoading />
			) : (
				<React.Fragment>
					<FingotiHeader
						breadcrumbs={breadcrumbs}
						actionButtons={actionButtons()}
						sectionIcon={AccountBalanceOutlinedIcon}
					/>
					<FingotiMarkdown path='/documentation/account/organisation/manage.md' />
					<FingotiTable
						data={getOrganisations}
						tableRef={tableRef}
						columns={[
							{ title: 'id', field: 'id', hidden: true },
							{
								title: 'organisation name',
								field: 'organisationName',
								render: organisationNameRenderer,
								cellStyle: { padding: 8 },
							},
							{
								title: 'organisation number',
								field: 'organisationNumber',
								filtering: false,
							},
							{ title: 'role', field: 'roleName', filtering: false },
						]}
						actions={[
							(rowData) =>
								rowData.id !== organisation.id && {
									icon: AutorenewIcon,
									tooltip: 'switch organisation',
									onClick: () => switchOrganisation(rowData.id),
								},
						]}
						options={{
							rowStyle: (rowData) => {
								return {
									backgroundColor:
										rowData.id === organisation.id &&
										theme.palette.primary.lighter,
								};
							},
						}}
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
