import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FingotiButton, FingotiFullscreenStatus, FingotiHeader, FingotiLoading } from '@fingoti/components';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Popper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import Validation from '../../services/validation.service';
import { apiService } from '../../services/api.service';
import { useForceUpdate } from '../Utils/ForceUpdate';
import { cdnUrl } from '../../services/config';

const CustomPopper = (props) => {
    return <Popper {...props} style={{ ...props.style, zIndex: 1400 }} />
}

export const OrganisationRegister = ({ classes, partner, submitStep, checkNotUserHandle }) => {

    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const [organisationName, setOrganisationName] = useState('');
    const [organisationHandle, setOrganisationHandle] = useState('');
    const [isBusiness, setIsBusiness] = useState(false);
    const [vatNumber, setVatNumber] = useState('');
    const [maddrLn1, setMAddrLn1] = useState('');
    const [maddrLn2, setMAddrLn2] = useState('');
    const [mtown, setMTown] = useState('');
    const [mcounty, setMCounty] = useState('');
    const [mcountry, setMCountry] = useState('');
    const [mpostcode, setMPostcode] = useState('')
    const [customerForename, setCustomerForename] = useState('');
    const [customerSurname, setCustomerSurname] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [inviteCustomer, setInviteCustomer] = useState(false);
    const [thisLoading, setThisLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getCountryList();
    }, []);

    const getCountryList = () => {
        fetch(`${cdnUrl}/static/metadata/countries_v1.0.json`)
            .then(async response => {
                if (!response.ok) {
                    throw Error("Error getting country codes")
                } else {
                    const data = await response.json();
                    return data;
                }
            })
            .then(data => {
                setSelectedCountry(data.countries[0]);
                setMCountry(data.countries[0].code);
                setCountries(data.countries);
                setThisLoading(false);
            })
            .catch(e => {
                console.error(e);
                setError(true);
                setThisLoading(false);
            })
    }

    const handleSetErrors = (errors) => {
        setErrors(errors);
        forceUpdate();
    }

    const validate = new Validation(errors, handleSetErrors);

    const checkSpecialChar = (e) => {
        if (validate.checkAgainstRegex(e)) {
            checkDoesntExist(e);
        }
    }

    const checkDoesntExist = async (e) => {
        if (validate.checkNotEmpty(e)) {
            if (checkNotUserHandle(e.target.value)) {

                let fieldName = e.target.name;
                let fieldValue = e.target.value;
                let body = { userHandle: fieldValue }

                try {
                    let data = await apiService.postData("/user/details", body);
                    if (!data.userHandle) {
                        errors[fieldName] = { msg: "organisation handle already exists" }
                        handleSetErrors(errors);
                        return false;
                    } else if (errors[fieldName]) {
                        delete errors[fieldName];
                        handleSetErrors(errors);
                        return true;
                    } else {
                        return true;
                    }
                }
                catch (e) {
                    console.error(e);
                }
            } else {
                errors['organisationHandle'] = { msg: "organisation handle must not be the same as user handle" }
                handleSetErrors(errors);
            }
        }
    }

    const generateHandle = async (orgName) => {
        if (organisationHandle === '') {
            let orgParts = orgName.split(' ');
            let generatedHandle = '';
            let finalHandle = '';

            orgParts.forEach(part => {
                let partSubstr = part.substring(0, 4);
                generatedHandle = generatedHandle + partSubstr;
            })

            const addRandomNumbers = () => {
                let rn = Math.floor(Math.random() * (999 - 1)).toString();
                let paddedRn = rn.padStart(3, "0");

                return generatedHandle + paddedRn;
            }

            finalHandle = addRandomNumbers();

            let fakeEvent = {
                target: {
                    name: "organisationHandle",
                    value: generatedHandle
                }
            }

            let doesntExist = await checkDoesntExist(fakeEvent);

            if (!doesntExist) {
                finalHandle = addRandomNumbers();
            }

            delete errors['organisationHandle'];
            handleSetErrors(errors);
            setOrganisationHandle(finalHandle.toLowerCase());
        }
    }

    const validatePostcode = (e, r) => {
        let regexString = r || selectedCountry.regex;
        console.log(regexString);
        let regex = new RegExp(regexString);

        console.log(regex);

        if (!regex.test(e.target.value)) {
            errors['mpostcode'] = { msg: "postcode not valid" }
            handleSetErrors(errors);
        } else {
            delete errors['mpostcode'];
            handleSetErrors(errors);
        }
    }

    const handleNextStep = (e) => {

        let reqFields = {
            organisationName: organisationName,
            organisationHandle: organisationHandle,
            maddrLn1: maddrLn1,
            mtown: mtown,
            mcounty: mcounty,
            mcountry: mcountry,
            mpostcode: mpostcode
        };

        let allFields = {
            organisationName: organisationName,
            organisationHandle: organisationHandle,
            isBusiness: isBusiness,
            vatNumber: vatNumber,
            maddrLn1: maddrLn1,
            maddrLn2: maddrLn2,
            mtown: mtown,
            mcounty: mcounty,
            mcountry: mcountry,
            mpostcode: mpostcode,
            inviteCustomer: inviteCustomer,
        };

        if (partner !== undefined) {
            allFields.customerForename = customerForename;
            allFields.customerSurname = customerSurname;
            allFields.customerEmail = customerEmail;
        }

        if (validate.checkRequiredFields(reqFields)) {
            submitStep(allFields);
        }
    }

    const breadcrumbs = [
        { text: 'Create Organisation' }
    ]

    console.log("country", mcountry);

    return (
        <>
            {thisLoading ?
                <FingotiLoading />
                :
                error ?
                    <FingotiFullscreenStatus
                        tagline="There was a problem"
                        message="We ran into an issue, please refresh and try again, if the issue persists contact support"
                    />
                    :
                    <React.Fragment>
                        <FingotiHeader
                            breadcrumbs={breadcrumbs}
                            sectionIcon={PersonAddOutlinedIcon}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant='h6'>
                                    Basic Information
                                </Typography>
                                <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                    <TextField
                                        fullWidth
                                        required
                                        id='organisationName'
                                        name='organisationName'
                                        variant='outlined'
                                        label='organisation name'
                                        value={organisationName}
                                        onChange={(e) => setOrganisationName(e.target.value)}
                                        onBlur={(e) => validate.checkNotEmpty(e) ? generateHandle(e.target.value) : () => { }}
                                        error={Boolean(errors['organisationName'])}
                                        helperText={Boolean(errors['organisationName']) ? errors['organisationName'].msg : ""}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                    <TextField
                                        fullWidth
                                        required
                                        id='organisationHandle'
                                        name='organisationHandle'
                                        variant='outlined'
                                        label='organisation handle'
                                        value={organisationHandle}
                                        onChange={(e) => setOrganisationHandle(e.target.value.toLowerCase())}
                                        onBlur={(e) => checkSpecialChar(e)}
                                        error={Boolean(errors['organisationHandle'])}
                                        helperText={Boolean(errors['organisationHandle']) ? errors['organisationHandle'].msg : ""}
                                        inputProps={{ maxLength: 32 }}
                                    />
                                </FormControl>
                                <Collapse in={isBusiness}>
                                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                                        <TextField
                                            fullWidth
                                            id='vatNumber'
                                            name='vatNumber'
                                            variant='outlined'
                                            label='vat number'
                                            value={vatNumber}
                                            onChange={(e) => setVatNumber(e.target.value)}
                                            error={Boolean(errors['vatNumber'])}
                                            helperText={Boolean(errors['vatNumber']) ? errors['vatNumber'].msg : ""}
                                        />
                                    </FormControl>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.isBusiness}>
                                <FormControl style={{ 'marginTop': '56px' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch className="switch"
                                                color="primary"
                                                name="isBusiness"
                                                label="business organisation?"
                                                checked={isBusiness}
                                                onClick={() => setIsBusiness(!isBusiness)}
                                            />
                                        }
                                        label="business organisation?"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} />
                            {
                                partner !== undefined ?
                                    <>
                                        <Grid item xs={12} md={5} className={classes.isBusiness}>
                                            <FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Switch className="switch"
                                                            color="primary"
                                                            name="inviteCustomer"
                                                            label="Would you like to invite the customer to be a user of the tenant organisation?"
                                                            checked={inviteCustomer}
                                                            onClick={() => setInviteCustomer(!inviteCustomer)}
                                                        />
                                                    }
                                                    label="Would you like to invite the customer to be a user of the tenant organisation?"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={12} md={5} />
                                    </>
                                    :
                                    <></>
                            }
                            {
                                inviteCustomer ?
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant='h6'>
                                                Customer Information
                                            </Typography>
                                            <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id='customerForename'
                                                    name='customerForename'
                                                    variant='outlined'
                                                    label='customer forename'
                                                    value={customerForename}
                                                    onChange={(e) => setCustomerForename(e.target.value)}
                                                    onBlur={(e) => validate.checkNotEmpty(e)}
                                                    error={Boolean(errors['customerForename'])}
                                                    helperText={Boolean(errors['customerForename']) ? errors['customerForename'].msg : ""}
                                                />
                                            </FormControl>
                                            <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id='customerSurname'
                                                    name='customerSurname'
                                                    variant='outlined'
                                                    label='customer surname'
                                                    value={customerSurname}
                                                    onChange={(e) => setCustomerSurname(e.target.value)}
                                                    onBlur={(e) => validate.checkNotEmpty(e)}
                                                    error={Boolean(errors['customerSurname'])}
                                                    helperText={Boolean(errors['customerSurname']) ? errors['customerSurname'].msg : ""}
                                                />
                                            </FormControl>
                                            <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id='customerEmail'
                                                    name='customerEmail'
                                                    variant='outlined'
                                                    label='customer email'
                                                    value={customerEmail}
                                                    onChange={(e) => setCustomerEmail(e.target.value)}
                                                    onBlur={(e) => validate.checkNotEmpty(e)}
                                                    error={Boolean(errors['customerEmail'])}
                                                    helperText={Boolean(errors['customerEmail']) ? errors['customerEmail'].msg : ""}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid xs={12} md={8} />
                                    </>
                                    :
                                    <></>
                            }
                            <Grid item xs={12} md={4}>
                                <Typography variant='h6'>
                                    Contact Address
                                </Typography>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <TextField
                                        fullWidth
                                        required
                                        id='maddrLn1'
                                        name='maddrLn1'
                                        variant='outlined'
                                        label='address line 1'
                                        value={maddrLn1}
                                        onChange={(e) => setMAddrLn1(e.target.value)}
                                        onBlur={(e) => validate.checkNotEmpty(e)}
                                        error={Boolean(errors['maddrLn1'])}
                                        helperText={Boolean(errors['maddrLn1']) ? errors['maddrLn1'].msg : ""}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <TextField
                                        fullWidth
                                        id='maddrLn2'
                                        name='maddrLn2'
                                        variant='outlined'
                                        label='address line 2'
                                        value={maddrLn2}
                                        onChange={(e) => setMAddrLn2(e.target.value)}
                                        error={Boolean(errors['maddrLn2'])}
                                        helperText={Boolean(errors['maddrLn2']) ? errors['maddrLn2'].msg : ""}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <TextField
                                        fullWidth
                                        required
                                        id='mtown'
                                        name='mtown'
                                        variant='outlined'
                                        label='city'
                                        value={mtown}
                                        onChange={(e) => setMTown(e.target.value)}
                                        onBlur={(e) => validate.checkNotEmpty(e)}
                                        error={Boolean(errors['mtown'])}
                                        helperText={Boolean(errors['mtown']) ? errors['mtown'].msg : ""}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <TextField
                                        fullWidth
                                        required
                                        id='mcounty'
                                        name='mcounty'
                                        variant='outlined'
                                        label='county'
                                        value={mcounty}
                                        onChange={(e) => setMCounty(e.target.value)}
                                        onBlur={(e) => validate.checkNotEmpty(e)}
                                        error={Boolean(errors['mcounty'])}
                                        helperText={Boolean(errors['mcounty']) ? errors['mcounty'].msg : ""}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <Autocomplete
                                        disableClearable
                                        id='mcountry'
                                        name='mcountry'
                                        options={countries}
                                        value={selectedCountry}
                                        onChange={(e, v) => { 
                                            setSelectedCountry(v); 
                                            setMCountry(v.code); 
                                            if(mpostcode !== '') {
                                                validatePostcode({ target: { value: mpostcode } }, v.regex);
                                            }
                                        }}
                                        getOptionLabel={(opt) => `${opt.country}`}
                                        getOptionSelected={(o, v) => o.code === v.code ? true : false}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                name='mcountry'
                                                label='country *'
                                            />
                                        )}
                                        PopperComponent={CustomPopper}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth variant="outlined">
                                    <TextField
                                        fullWidth
                                        required
                                        id='mpostcode'
                                        name='mpostcode'
                                        variant='outlined'
                                        label='postcode'
                                        value={mpostcode}
                                        onChange={(e) => setMPostcode(e.target.value.toUpperCase())}
                                        onBlur={(e) => validatePostcode(e)}
                                        error={Boolean(errors['mpostcode'])}
                                        helperText={Boolean(errors['mpostcode']) ? errors['mpostcode'].msg : ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8} />
                            <Grid item xs={12} md={4} className={classes.buttonGroup}>
                                <FingotiButton
                                    light
                                    type="submit"
                                    className={classes.button}
                                    color="primary"
                                    onClick={handleNextStep}
                                    disabled={Boolean(Object.keys(errors).length > 0 ||
                                        organisationName.length === 0 ||
                                        organisationHandle.length === 0 ||
                                        maddrLn1.length === 0 ||
                                        mtown.length === 0 ||
                                        mcounty.length === 0 ||
                                        mcountry.length === 0 ||
                                        mpostcode.length === 0)}
                                >
                                    NEXT STEP
                                </FingotiButton>
                                <FingotiButton
                                    light
                                    className={classes.button}
                                    onClick={() => history.goBack()}
                                >
                                    CANCEL
                                </FingotiButton>
                            </Grid>
                        </Grid>
                    </React.Fragment>
            }
        </>
    );
}