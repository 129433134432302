import React, { useState, useEffect } from 'react';
import { FingotiButton, FingotiLoading, FingotiModalContent, FingotiModalActions } from '@fingoti/components';
import { useSnackbar } from 'notistack';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import { DateTimePicker } from '@material-ui/pickers';

import { apiService } from '../../services/api.service';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    },
    button: {
        width: '42%',
    },
    select: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        fontSize: '0.2rem',
        border: '2px solid #E0E0E0',
        marginRight: theme.spacing(2),
    },
    expiry: {
        width: '100%',
        marginTop: theme.spacing(3),
    }

}))

export const UserTokenEdit = ({ handleEditToken, setOpen, token }) => {

    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();
    const [thisLoading, setThisLoading] = useState(true);
    const [name, setName] = useState('');
    const [enableExpiry, setEnableExpiry] = useState(false);
    const [expiry, setExpiry] = useState(new Date())
    const [enabled, setEnabled] = useState(true);

    useEffect(() => {
        setEnabled(!token.disabled);
        setName(token.tokenName);
        if (token.expiry !== null) {
            setEnableExpiry(true);
            setExpiry(token.expiry);
        }
        setThisLoading(false);
    }, [token])

    const editToken = () => {
        let body = { expiry: enableExpiry ? expiry : null, disabled: !enabled, tokenName: name }
        console.log("patch token data", body);
        apiService.patchData(`/user/tokens/${token.id}`, body)
            .then(result => {
                enqueueSnackbar(result.message, { variant: "success" });
                handleEditToken();
            })
            .catch(error => {
                enqueueSnackbar(error, { variant: "error" });
            })
    }

    return (
        <>
            <FingotiModalContent>
                {thisLoading ?
                    <FingotiLoading />
                    :
                    <div className={classes.root}>
                        <form className={classes.form}>
                            <FormControl variant="outlined">
                                <TextField
                                    required
                                    id='tokenName'
                                    name='tokenName'
                                    variant='outlined'
                                    label='token name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl required variant='outlined' className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={enabled}
                                            onChange={() => setEnabled(!enabled)}
                                            name='tokenEnabled'
                                            color='primary'
                                        />
                                    }
                                    label='token enabled?'
                                />
                            </FormControl>
                            <FormControl required variant='outlined' className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={enableExpiry}
                                            onChange={() => setEnableExpiry(!enableExpiry)}
                                            name='enableExpiry'
                                            color='primary'
                                        />
                                    }
                                    label='enable expiry?'
                                />
                            </FormControl>
                            <Collapse in={enableExpiry}>
                                <FormControl required variant='outlined' className={classes.expiry}>
                                    <DateTimePicker
                                        DialogProps={{ style: { zIndex: 1400 } }}
                                        value={expiry}
                                        hideTabs
                                        disablePast
                                        inputVariant='outlined'
                                        onChange={setExpiry}
                                        label="expiry date/time"
                                    />
                                </FormControl>
                            </Collapse>
                        </form>
                    </div>
                }
            </FingotiModalContent>

            {!thisLoading &&
                <FingotiModalActions>
                    <div className={classes.buttonGroup}>
                        <FingotiButton
                            light
                            color="primary"
                            className={classes.button}
                            onClick={editToken}
                        >
                            save token
                        </FingotiButton>
                        <FingotiButton
                            light
                            className={classes.button}
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </FingotiButton>
                    </div>
                </FingotiModalActions>
            }
        </>
    );

}