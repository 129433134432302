import React, { useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import {
	FingotiModal,
	FingotiButton,
	FingotiLoading,
	FingotiDelete,
	FingotiHeader,
	FingotiMarkdown,
	FingotiTable,
	URLGenerator,
} from '@fingoti/components';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined';

import { OrganisationAddressesNewEdit } from './OrganisationAddressesNewEdit';

import { apiService } from '../../services/api.service';
import { useProfileState } from '../../context/ProfileContext';
import { useRoleCheck } from '../Utils/RoleCheck';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(6),
	},
	tableHead: {
		color: theme.palette.primary.main,
	},
	actionIcons: {
		display: 'flex',
		alignItems: 'center',
	},
	actionIcon: {
		padding: 0,
	},
	lineOne: {
		padding: theme.spacing(1),
	},
	userWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	deleteIcon: {
		fill: '#FF0000',
	},
	editIcon: {
		fill: '#00FF00',
	},
	iconDisabled: {
		fill: theme.palette.action.disabled,
	},
	button: {
		marginLeft: theme.spacing(2),
	},
	labelCell: {
		minWidth: 52,
		width: '85%',
		display: 'inline-block',
		padding: 2,
		margin: 2,
		borderRadius: 3,
		[theme.breakpoints.up('md')]: {
			width: 72,
		},
	},
	main: {
		backgroundColor: theme.palette.primary.light,
	},
	billing: {
		backgroundColor: theme.palette.secondary.light,
	},
	delivery: {
		backgroundColor: theme.palette.greyThree.main,
	},
}));

export const OrganisationAddresses = () => {
	const theme = useTheme();
	const classes = useStyles();
	const { roleCheck } = useRoleCheck();
	const { enqueueSnackbar } = useSnackbar();
	const [newAddressOpen, setNewAddressOpen] = useState(false);
	const [editAddress, setEditAddress] = useState(undefined);
	const [editAddressOpen, setEditAddressOpen] = useState(false);
	const [thisLoading, setThisLoading] = useState(false);
	const { organisation, loading } = useProfileState();
	const tableRef = useRef();

	useEffect(() => {
		setThisLoading(true);
		if (organisation && !loading) {
			setThisLoading(false);
		}
	}, [organisation, loading]);

	const getAddresses = (query) => {
		return apiService
			.getData(URLGenerator(query, '/organisation/addresses'))
			.then((data) => {
				return {
					data: data.addresses,
					page: data.pageNumber - 1,
					totalCount: data.count,
				};
			})
			.catch(() => {
				enqueueSnackbar('Error getting addresses', { variant: 'error' });
			});
	};

	const removeAddress = (addressId) => {
		apiService
			.deleteData(`/organisation/addresses/${addressId}`)
			.then((result) => {
				enqueueSnackbar(result.message, { variant: 'success' });
				tableRef.current.onQueryChange();
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const actionButtons = () => {
		return (
			<React.Fragment>
				<FingotiButton
					light
					onClick={() => setNewAddressOpen(true)}
					className={classes.button}
					disabled={roleCheck('user', 2)}
					color='primary'>
					Add Address
				</FingotiButton>
			</React.Fragment>
		);
	};

	const handleAddressEdit = (address) => {
		setEditAddress(address);
		setEditAddressOpen(true);
	};

	const refreshTable = () => {
		tableRef.current.onQueryChange();
	};

	const assignmentRenderer = (rd) => {
		if (rd.id === organisation.mainAddress) {
			return (
				<span className={clsx(classes.main, classes.labelCell)}>Main</span>
			);
		} else if (rd.id === organisation.billingAddress) {
			return (
				<span className={clsx(classes.billing, classes.labelCell)}>
					Billing
				</span>
			);
		} else if (rd.id === organisation.deliveryAddress) {
			return (
				<span className={clsx(classes.delivery, classes.labelCell)}>
					Delivery
				</span>
			);
		} else {
			return 'None';
		}
	};

	const breadcrumbs = [
		{ text: 'Organisation', link: '/organisation' },
		{ text: 'Addresses', link: '' },
	];

	return (
		<React.Fragment>
			{thisLoading ? (
				<FingotiLoading />
			) : (
				<React.Fragment>
					<FingotiModal
						title='Add New Address'
						open={newAddressOpen}
						setOpen={setNewAddressOpen}>
						<OrganisationAddressesNewEdit
							setOpen={setNewAddressOpen}
							refreshAddresses={refreshTable}
							update={false}
						/>
					</FingotiModal>
					<FingotiModal
						title='Edit Address'
						open={editAddressOpen}
						setOpen={setEditAddressOpen}>
						<OrganisationAddressesNewEdit
							setOpen={setEditAddressOpen}
							refreshAddresses={refreshTable}
							update={true}
							updateData={editAddress}
						/>
					</FingotiModal>
					<FingotiHeader
						breadcrumbs={breadcrumbs}
						sectionIcon={AccountBalanceOutlined}
						actionButtons={actionButtons()}
					/>
					<FingotiMarkdown path='/documentation/account/organisation/addresses.md' />
					<FingotiTable
						data={getAddresses}
						tableRef={tableRef}
						columns={[
							{ title: 'id', field: 'id', hidden: true },
							{ title: 'address', field: 'line1' },
							{ title: 'postcode', field: 'postcode' },
							{
								title: 'assignment',
								cellStyle: { padding: theme.spacing(1) },
								align: 'center',
								render: assignmentRenderer,
							},
						]}
						actions={[
							(rowData) => ({
								icon: EditOutlinedIcon,
								tooltip: 'edit address',
								onClick: () => handleAddressEdit(rowData),
							}),
							(rowData) => ({
								icon: () =>
									rowData.id === organisation.mainAddress ||
									rowData.id === organisation.deliveryAddress ||
									rowData.id === organisation.billingAddress ? (
										<></>
									) : (
										<FingotiDelete
											remove
											className={classes.actionIcon}
											tooltipText='remove address'
											onClick={() => removeAddress(rowData.id)}
										/>
									),
							}),
						]}
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
