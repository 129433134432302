import React, { useEffect, useState } from 'react';
import { FingotiLoading, FingotiFullscreenStatus } from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import { ssoService } from '../../services/sso.service';
import { useProfileDispatch, useProfileState } from '../../context/ProfileContext';
import { authService } from '../../services/auth.service';

const useStyles = makeStyles((theme) => ({

    root: {
        height: '75vh'
    }

}))

export const Logout = () => {

    const classes = useStyles();
    const dispatch = useProfileDispatch();
    const { user, loading } = useProfileState();
    const [success, setSuccess] = useState(false);
    const [thisLoading, setThisLoading] = useState(true);

    useEffect(() => {
        if (user && !loading) {
            ssoService.ssoLogout()
                .then(() => {
                    authService.appLogout();
                    setSuccess(true);
                    setThisLoading(false);
                    dispatch({ type: 'LOGOUT' });
                })
                .catch(error => {
                    setSuccess(false)
                    setThisLoading(false);
                })
        }
    }, [user, loading])

    return (
        <React.Fragment>
            { thisLoading ?
                <FingotiLoading />
                :
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                    {
                        success ?
                            <FingotiFullscreenStatus
                                success
                                tagline="Logged out"
                                message="Logged out successfully"
                            />
                            :
                            <FingotiFullscreenStatus
                                tagline="Logout failed"
                            />
                    }
                </Grid>
            }
        </React.Fragment>
    );

}