import { authUrl, cookieSettings } from './config';
import { handleLoginResponse } from './response.service';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const ssoResendVerification = () => {
    return new Promise((resolve, reject) => {

        let requestOpts = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }

        return fetch(`${authUrl}/sso/email/resend`, requestOpts)
            .then(handleLoginResponse)
            .then(res => {
                return resolve(res);
            })
            .catch(error => {
                console.error("Resend error", error);
                return reject(error);
            })

    })
}

const ssoLogin = (email, password) => {
    return new Promise((resolve, reject) => {

        let requestOpts = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        }

        return fetch(`${authUrl}/sso/login`, requestOpts)
            .then(handleLoginResponse)
            .then(user => {
                if(user.organisationId !== null) {
                    cookies.set("ActiveOrganisation", user.organisationId, cookieSettings())
                    return resolve(user);
                } else {
                    //User needs to create org
                }
            })
            .catch(error => {
                console.error("SSO login failed", [ error ]);
                return reject(error);
            })

    })
}

const ssoLogout = (session) => {

    let url; 

    session ? url = `${authUrl}/sso/logout/${session}` : url = `${authUrl}/sso/logout`;

    return new Promise((resolve, reject) => {

        let requestOpts = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        };

        return fetch(url, requestOpts)
            .then(handleLoginResponse)
            .then(data => {
                if(!session) {
                    console.log("removing AO");
                    cookies.remove("ActiveOrganisation", cookieSettings());
                }
                return resolve(data);
            })
            .catch(error => {
                console.error(`SSO logout failed - Session ${session}`, [ error ]);
                return reject(error);
            })

    })
}

export const ssoService = {
    ssoResendVerification,
    ssoLogin,
    ssoLogout
}