import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FingotiButton, FingotiLoading, FingotiModal, FingotiHeader } from '@fingoti/components';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Hidden from '@material-ui/core/Hidden';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { useProfileDispatch, useProfileState } from '../../context/ProfileContext';
import { apiService } from '../../services/api.service';
import { ImageCrop } from '../Utils/ImageCrop';
import { useRoleCheck } from '../Utils/RoleCheck';
import { OrganisationEdit } from './OrganisationEdit';
import { useForceUpdate } from '../Utils/ForceUpdate';

const useStyles = makeStyles(theme => ({

    root: {
        height: '75vh'
    },
    avatar: {
        width: '4rem',
        height: '4rem',
        border: '4px solid #E0E0E0',
        transition: 'all 150ms linear',
        [theme.breakpoints.up('sm')]: {
            width: '6rem',
            height: '6rem',
        },
        [theme.breakpoints.up('md')]: {
            width: '8rem',
            height: '8rem',
        },
        [theme.breakpoints.up('lg')]: {
            width: '10rem',
            height: '10rem',
        }
    },
    avatarWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        transition: 'all 150ms linear',
    },
    avatarButton: {
        '&:hover': {
            '& $avatarEdit': {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                transition: 'all 150ms linear'
            },
            '& $avatar': {
                cursor: 'pointer',
                transition: 'all 150ms linear',
                opacity: '0.4',
            }
        }
    },
    avatarEdit: {
        position: 'absolute',
        top: '42%',
        left: '38%',
        fontSize: '1rem',
        fontFamily: 'Work Sans',
        zIndex: 100,
        display: 'none',
        color: '#FFF',
        transition: 'all 150ms linear',
        padding: '6px 8px',
        borderRadius: '5%',
        background: 'rgba(64, 64, 64, 0.8)',
    },
    defaultAvatar: {
        fontSize: '5rem',
    },
    profileHead: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'flex-end'
    },
    username: {
        color: theme.palette.primary.main,
    },
    userInfoText: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconSpacing: {
        marginRight: theme.spacing(2),
    },
    noBottomBorder: {
        border: 'none',
    },
    headerCell: {
        border: 'none',
        textAlign: 'right',
        borderRight: '1px solid ' + theme.palette.greyFour.main,
        width: '48%',
        color: theme.palette.greyTwo.main,
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        }
    },
    headerCellAlign: {
        width: '48% !important',
    },
    address: {
        border: 'none',
    },
    verticalTop: {
        verticalAlign: 'top',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}))

export const OrganisationDetails = () => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [mainContact, setMainContact] = useState(undefined);
    const [mainAddress, setMainAddress] = useState(undefined);
    const [billingContact, setBillingContact] = useState(undefined);
    const [billingAddress, setBillingAddress] = useState(undefined);
    const [deliveryContact, setDeliveryContact] = useState(undefined);
    const [deliveryAddress, setDeliveryAddress] = useState(undefined);
    const [thisLoading, setThisLoading] = useState(true);
    const [imageOpen, setImageOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const { organisation, addresses, users, update, error, loading } = useProfileState();
    const dispatch = useProfileDispatch();
    const { roleCheck } = useRoleCheck();

    useEffect(() => {

        setThisLoading(true);
        if (organisation && addresses && users && !loading) {
            Promise.all([getMainContact(), getMainAddress(), getBillingContact(),
            getBillingAddress(), getDeliveryContact(), getDeliveryAddress()])
                .then(() => {
                    setThisLoading(false);
                })
                .catch(() => {
                    console.log("Failed getting address/contact info");
                    setThisLoading(false);
                })
        }

    }, [organisation, addresses, users, loading, update])


    const getMainContact = () => {
        return new Promise((resolve, reject) => {
            if (organisation.mainContact !== "00000000-0000-0000-0000-000000000000") {
                let mainContact = users.find(user => user.id === organisation.mainContact);
                if (mainContact !== undefined) {
                    setMainContact(mainContact);
                    return resolve();
                } else {
                    enqueueSnackbar("Error getting main contact", { variant: 'error' });
                    return reject();
                }
            } else {
                return resolve();
            }
        })
    }

    const getMainAddress = () => {
        return new Promise((resolve, reject) => {
            let mainAddress = addresses.find(addr => addr.id === organisation.mainAddress)
            if (mainAddress !== undefined) {
                setMainAddress(mainAddress);
                return resolve();
            } else {
                enqueueSnackbar("Error getting main address ", { variant: 'error' });
                return reject();
            }
        })
    }

    const getBillingContact = () => {
        return new Promise((resolve, reject) => {
            if (organisation.billingContact !== "00000000-0000-0000-0000-000000000000") {
                let billingContact = users.find(user => user.id === organisation.billingContact);
                if (billingContact !== undefined) {
                    setBillingContact(billingContact);
                    return resolve();
                } else {
                    enqueueSnackbar("Error getting billing contact", { variant: 'error' });
                    return reject();
                }
            } else {
                return resolve();
            }
        })
    }

    const getBillingAddress = () => {
        return new Promise((resolve, reject) => {
            let billingAddress = addresses.find(addr => addr.id === organisation.billingAddress)
            if (billingAddress !== undefined) {
                setBillingAddress(billingAddress);
                return resolve();
            } else {
                enqueueSnackbar("Error getting billing address ", { variant: 'error' });
                return reject();
            }
        })
    }

    const getDeliveryContact = () => {
        return new Promise((resolve, reject) => {
            if (organisation.deliveryContact !== "00000000-0000-0000-0000-000000000000") {
                let deliveryContact = users.find(user => user.id === organisation.deliveryContact);
                if (deliveryContact !== undefined) {
                    setDeliveryContact(deliveryContact);
                    return resolve();
                } else {
                    enqueueSnackbar("Error getting delivery contact", { variant: 'error' });
                    return reject();
                }
            } else {
                return resolve();
            }
        })
    }

    const getDeliveryAddress = () => {
        return new Promise((resolve, reject) => {
            let deliveryAddress = addresses.find(addr => addr.id === organisation.deliveryAddress)
            if (deliveryAddress !== undefined) {
                setDeliveryAddress(deliveryAddress);
                return resolve();
            } else {
                enqueueSnackbar("Error getting delivery address ", { variant: 'error' });
                return reject();
            }
        })
    }

    const imageUploadSuccess = () => {
        enqueueSnackbar("Successfully updated organisation image", { variant: 'success' });
        setImageOpen(false);
        dispatch({ type: 'UPDATE' });
    }

    const imageUploadFail = () => {
        enqueueSnackbar("Failed to update organisation image", { variant: 'error' });
        setImageOpen(false);
    }

    const handleEditSuccess = () => {
        apiService.getData('/organisation')
            .then(organisationData => {
                delete organisationData.success;
                dispatch({ type: 'ADD_ORGANISATION', payload: organisationData });
            })
    }

    const actionButtons = () => {
        return (
            <React.Fragment>
                <FingotiButton
                    light
                    disabled={roleCheck("organisation", 2)}
                    color="primary"
                    onClick={setEditOpen}
                >
                    edit organisation
                </FingotiButton>
            </React.Fragment>
        );
    }

    const breadcrumbs = [
        { text: 'Organisation', link: '/organisation' },
        { text: 'Overview', link: '' }
    ]

    return (
        thisLoading ?
            <FingotiLoading />
            :
            error ?
                ""
                :
                <React.Fragment>
                    <FingotiModal title="Upload Organisation Image" open={imageOpen} setOpen={setImageOpen}>
                        <ImageCrop organisation onSuccess={imageUploadSuccess} setOpen={setImageOpen} onError={imageUploadFail} />
                    </FingotiModal>
                    <FingotiModal title="Edit Organisation" open={editOpen} setOpen={setEditOpen}>
                        <OrganisationEdit organisation={organisation} onSuccess={handleEditSuccess} setOpen={setEditOpen} />
                    </FingotiModal>
                    <FingotiHeader breadcrumbs={breadcrumbs} actionButtons={actionButtons()} sectionIcon={AccountBalanceOutlinedIcon} />
                    <Grid container spacing={4}>
                        <Grid item xs={3} sm={2} className={classes.avatarWrapper}>
                            {
                                !roleCheck("organisation", 2) ?
                                    <div className={classes.avatarButton}>
                                        <div className={classes.avatarEdit} onClick={() => setImageOpen(true)}>
                                            <EditOutlinedIcon style={{ fontSize: '1rem', paddingRight: '6px' }} />
                                            edit
                                        </div>
                                        <Avatar src={`https://cdn.fingoti.com/images/profiles/${organisation.id}?rid=${Date.now()}`} className={classes.avatar} onClick={setImageOpen}>
                                            <AccountBalanceOutlinedIcon className={classes.defaultAvatar} />
                                        </Avatar>
                                    </div>
                                    :
                                    <Avatar src={`https://cdn.fingoti.com/images/profiles/${organisation.id}?rid=${Date.now()}`} className={classes.avatar}>
                                        <AccountBalanceOutlinedIcon className={classes.defaultAvatar} />
                                    </Avatar>
                            }
                        </Grid>
                        <Grid item xs={9} sm={10} className={classes.profileHead}>
                            <Typography variant="h4">
                                {organisation.organisationName}
                            </Typography>
                            <Typography className={classes.username}>
                                @{organisation.organisationHandle}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={clsx(classes.headerCell, classes.headerCellAlign)}>organisation number</TableCell>
                                            <TableCell className={classes.noBottomBorder}>{organisation.organisationNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={clsx(classes.headerCell, classes.headerCellAlign)}>organisation type</TableCell>
                                            <TableCell className={classes.noBottomBorder}>
                                                {
                                                    organisation.isBusiness ?
                                                        organisation.isPartner ?
                                                            "Partner"
                                                            :
                                                            "Business"
                                                        :
                                                        "Personal"
                                                }
                                            </TableCell>
                                        </TableRow>
                                        {
                                            organisation.vatNumber !== "" && organisation.vatNumber !== null ?

                                                <TableRow>
                                                    <TableCell className={clsx(classes.headerCell, classes.headerCellAlign)}>vat number</TableCell>
                                                    <TableCell className={classes.noBottomBorder}>{organisation.vatNumber}</TableCell>
                                                </TableRow>
                                                :
                                                ""
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {
                                            organisation.mainContact !== "00000000-0000-0000-0000-000000000000" &&
                                            <TableRow>
                                                <TableCell className={classes.headerCell}>main contact</TableCell>
                                                <TableCell className={classes.noBottomBorder}><span className={classes.username}>{mainContact.forename} {mainContact.surname}</span></TableCell>
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>main address</TableCell>
                                            <TableCell className={classes.address}>
                                                {mainAddress.line1}<br />
                                                {mainAddress.line2 ? <>{mainAddress.line2} <br /></> : ""}
                                                {mainAddress.city}<br />
                                                {mainAddress.county}<br />
                                                {mainAddress.country}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.headerCell}>main postcode</TableCell>
                                            <TableCell className={classes.noBottomBorder}>{mainAddress.postcode}</TableCell>
                                        </TableRow>
                                        {
                                            organisation.billingContact !== "00000000-0000-0000-0000-000000000000" &&
                                            <TableRow>
                                                <TableCell className={classes.headerCell}>billing contact</TableCell>
                                                <TableCell className={classes.noBottomBorder}><span className={classes.username}>{billingContact.forename} {billingContact.surname}</span></TableCell>
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>billing address</TableCell>
                                            <TableCell className={classes.address}>
                                                {billingAddress.line1}<br />
                                                {billingAddress.line2 ? <>{billingAddress.line2} <br /></> : ""}
                                                {billingAddress.city}<br />
                                                {billingAddress.county}<br />
                                                {billingAddress.country}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.headerCell}>billing postcode</TableCell>
                                            <TableCell className={classes.noBottomBorder}>{billingAddress.postcode}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Hidden only={['xl', 'lg', 'md', 'xs']}>
                            <Grid item sm={6} />
                        </Hidden>
                        <Grid item xs={12} sm={6} md={4}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {
                                            organisation.deliveryContact !== "00000000-0000-0000-0000-000000000000" &&
                                            <TableRow>
                                                <TableCell className={classes.headerCell}>delivery contact</TableCell>
                                                <TableCell className={classes.noBottomBorder}><span className={classes.username}>{deliveryContact.forename} {deliveryContact.surname}</span></TableCell>
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell className={clsx(classes.headerCell, classes.verticalTop)}>delivery address</TableCell>
                                            <TableCell className={classes.address}>
                                                {deliveryAddress.line1}<br />
                                                {deliveryAddress.line2 ? <>{deliveryAddress.line2} <br /></> : ""}
                                                {deliveryAddress.city}<br />
                                                {deliveryAddress.county}<br />
                                                {deliveryAddress.country}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.headerCell}>delivery postcode</TableCell>
                                            <TableCell className={classes.noBottomBorder}>{mainAddress.postcode}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </React.Fragment>
    );
}