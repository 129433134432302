import react, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
	FingotiButton,
	FingotiLoading,
	FingotiModalContent,
	FingotiModalActions,
} from '@fingoti/components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';

import { cdnUrl } from '../../../services/config';
import { apiService } from '../../../services/api.service';
import { useProfileState } from '../../../context/ProfileContext';
import { datetimeService } from '../../../services/datetime.service';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '70%',
		},
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	button: {
		width: '42%',
	},
	noBottomBorder: {
		border: 'none',
	},
	headerCell: {
		border: 'none',
		textAlign: 'right',
		borderRight: '1px solid ' + theme.palette.greyFour.main,
		width: '24%',
		color: theme.palette.greyTwo.main,
	},
	labelCell: {
		textAlign: 'center',
		width: '12%',
		display: 'inline-block',
		padding: '2px',
		borderRadius: '3px',
		marginLeft: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
	},
	status: {
		verticalAlign: 'middle',
		paddingRight: theme.spacing(1),
	},
	assignedTo: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		fontSize: '1.1rem',
		border: '2px solid #E0E0E0',
        background: '#FFF',
		color: theme.palette.secondary.main,
	},
	usageRoot: {
		height: 10,
		borderRadius: 5,
	},
	usageBar: {
		borderRadius: 5,
		backgroundColor: theme.palette.greyFour.main,
	},
	success: {
		borderRadius: 5,
		backgroundColor: theme.palette.success.main,
	},
	warning: {
		borderRadius: 5,
		backgroundColor: theme.palette.warning.main,
	},
	error: {
		borderRadius: 5,
		backgroundColor: theme.palette.error.main,
	},
}));

export const DetailSubscription = ({
	billingInfo,
	tenants,
	subscription,
	setOpen,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const { organisation } = useProfileState();
	const [thisLoading, setThisLoading] = useState(true);
	const [plans, setPlans] = useState([]);
	const [assignedTo, setAssignedTo] = useState([]);
	const usedRipples = subscription.planSelected.ripples - subscription.balance;
	const usedRipplePercent = Math.floor(
		(usedRipples / subscription.planSelected.ripples) * 100
	);

	useEffect(() => {
		if (organisation.isPartner) {
			let assignedOrgs = [];
			if (subscription.subscriptionId === billingInfo.subscriptionId) {
				assignedOrgs.push(organisation);
			}

			assignedOrgs.push(
				...tenants.filter(
					(t) => t.subscriptionId === subscription.subscriptionId
				)
			);


			setAssignedTo(assignedOrgs);
		} else {
			setAssignedTo([organisation]);
		}

		if (subscription.updating) {
			getPlans();
		} else {
			setThisLoading(false);
		}
	}, []);

	const getPlans = () => {
		apiService
			.getData('/billing/plans')
			.then((result) => {
				console.log('subscriptions', result);
				setPlans(result.plans);
				setThisLoading(false);
			})
			.catch((error) => {
				enqueueSnackbar(error, { variant: 'error' });
				setThisLoading(false);
			});
	};

	if (thisLoading) {
		return (
			<div className={classes.root}>
				<div
					style={{
						marginTop: theme.spacing(3),
						marginBottom: theme.spacing(3),
					}}>
					<FingotiLoading fullHeight={false} />
				</div>
			</div>
		);
	} else {
		return (
			<>
				<FingotiModalContent>
					<div className={classes.root}>
						<TableContainer>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell className={classes.headerCell}>number</TableCell>
										<TableCell className={classes.noBottomBorder}>
											{subscription.subscriptionNumber}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className={classes.headerCell}>status</TableCell>
										<TableCell className={classes.noBottomBorder}>
											{subscription.status === 0 ? (
												<>
													<Tooltip title='pending'>
														<CancelOutlinedIcon
															className={classes.status}
															style={{ fill: theme.palette.error.main }}
														/>
													</Tooltip>
													pending
												</>
											) : subscription.status === 3 ? (
												<>
													<Tooltip title='cancelled'>
														<CancelOutlinedIcon
															className={classes.status}
															style={{ fill: theme.palette.error.main }}
														/>
													</Tooltip>
													cancelled
												</>
											) : subscription.endDate ? (
												<>
													<Tooltip title='ending'>
														<ErrorOutlineOutlinedIcon
															className={classes.status}
															style={{ fill: theme.palette.warning.main }}
														/>
													</Tooltip>
													ending
												</>
											) : subscription.status === 1 ? (
												<>
													<Tooltip title='trial'>
														<CheckCircleOutlineOutlinedIcon
															className={classes.status}
															style={{ fill: theme.palette.secondary.main }}
														/>
													</Tooltip>
													trial
												</>
											) : (
												<>
													<Tooltip title='active'>
														<CheckCircleOutlineOutlinedIcon
															className={classes.status}
															style={{ fill: theme.palette.success.main }}
														/>
													</Tooltip>
													active
												</>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className={classes.headerCell}>plan</TableCell>
										<TableCell className={classes.noBottomBorder}>
											{subscription.planSelected.name}
										</TableCell>
									</TableRow>
									{organisation.isPartner && (
										<TableRow>
											<TableCell className={classes.headerCell}>
												assigned to
											</TableCell>
											<TableCell
												className={clsx(
													classes.noBottomBorder,
													classes.assignedTo
												)}>
												{assignedTo.length > 0 ? (
													<AvatarGroup className={classes.avatarGroup}>
														{assignedTo.map((o) => (
															<Tooltip title={`${o.organisationName}`}>
																<Avatar
																	alt={`${o.organisationName}`}
																	className={classes.avatar}
																	size='small'
																	src={`${cdnUrl}/images/avatars/${
																		o ? o.id : 'unassigend'
																	}`}>
																	{o.organisationName
																		.split(' ')
																		.map((p, i) => i < 2 && p.charAt(0))}
																</Avatar>
															</Tooltip>
														))}
													</AvatarGroup>
												) : (
													'Unassigned'
												)}
												{/* <Avatar
													className={classes.avatar}
													size='small'
													src={`https://cdn.fingoti.com/images/avatars/${
														assignedTo ? assignedTo.id : 'unassigned'
													}`}>
													<AccountBalanceOutlinedIcon />
												</Avatar>
												{assignedTo
													? assignedTo.organisationName
													: 'Unassigned'} */}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell className={classes.headerCell}>
											auto renew
										</TableCell>
										<TableCell className={classes.noBottomBorder}>
											{!subscription.endDate ? (
												<CheckCircleOutlineOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.success.main }}
												/>
											) : (
												<CancelOutlinedIcon
													className={classes.status}
													style={{ fill: theme.palette.error.main }}
												/>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className={classes.headerCell}>
											started on
										</TableCell>
										<TableCell className={classes.noBottomBorder}>
											{datetimeService.formatDate(
												new Date(subscription.startDate)
											)}
										</TableCell>
									</TableRow>
									{!subscription.endDate ? (
										<TableRow>
											<TableCell className={classes.headerCell}>
												renews on
											</TableCell>
											<TableCell className={classes.noBottomBorder}>
												{datetimeService.formatDate(
													new Date(subscription.renewalDate)
												)}
											</TableCell>
										</TableRow>
									) : (
										<TableRow>
											<TableCell className={classes.headerCell}>
												ends on
											</TableCell>
											<TableCell className={classes.noBottomBorder}>
												{datetimeService.formatDate(
													new Date(subscription.endDate)
												)}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell className={classes.headerCell}>
											ripple usage
										</TableCell>
										<TableCell className={classes.noBottomBorder}>
											<Box display='flex' alignItems='center'>
												<Box minWidth={100}>
													{usedRipples +
														'/' +
														subscription.planSelected.ripples}
												</Box>
												<Box width='80%' mr={1}>
													<LinearProgress
														variant='determinate'
														value={usedRipplePercent}
														classes={{
															root: classes.usageRoot,
															colorPrimary: classes.usageBar,
															bar: clsx({
																[classes.success]: usedRipplePercent <= 74,
																[classes.warning]: usedRipplePercent >= 75,
																[classes.error]: usedRipplePercent >= 90,
															}),
														}}
													/>
												</Box>
												<Box minWidth={35}>{usedRipplePercent}%</Box>
											</Box>
										</TableCell>
									</TableRow>
									{subscription.updating && (
										<TableRow>
											<TableCell className={classes.headerCell}>
												pending updates
											</TableCell>
											<TableCell className={classes.noBottomBorder}>
												Change plan:{' '}
												{plans.find((p) => p.id === subscription.updating).name}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</FingotiModalContent>
				<FingotiModalActions>
					<div className={classes.buttonGroup}>
						<FingotiButton
							light
							className={classes.button}
							onClick={() => setOpen(false)}>
							Close
						</FingotiButton>
					</div>
				</FingotiModalActions>
			</>
		);
	}
};
